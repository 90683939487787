import React from 'react';

import {
	unsubscribeAgentPresence,
	setAgents,
} from '../../redux/actions/async/echat';
import { I } from '../../common/globals';
import Measure from 'react-measure';
import { CHAT_REPLY_INPUT_TAGS_WIDTH } from '../../common/v5/constants';

const toFilterChunks = (text) => {
	if (!text) {
		return [];
	}
	return text.trim().toLowerCase().split(/\s+/)
}

const shortenText = (name, limit) => {
	var shortenTxt = name;
	if(name.length > limit) {
		shortenTxt = name.substring(0, (limit-3))+"...";
	}
	return shortenTxt;
}

const matchAny = (patterns, text) => {
	var chunks = text.trim().split(/\s+/)
	, i
	, j
	;
	if (!patterns || patterns.length == 0) {
		return true;
	}
	for(i=0; i<patterns.length;i++) {
		for(j=0; j<chunks.length; j++) {
			if (chunks[j].indexOf(patterns[i]) !== -1) {
				return true;
			}
		}
	}
	return false;
}

// agetNameAndChatName is used only for when it is to be viewed by an
// agent. Never use it in the context where it will be sent to the
// client. For that case use agentChatName instead.
const agentNameAndChatName = agent => {
	if (agent.chatName && agent.chatName != agent.userName) {
		return agent.userName + ' (' +agent.chatName + ')';
	}
	return agent.userName;
}

// We use agentChatName when adding agent names in text that a client
// may see.
const agentChatName = agent => {
	if (agent.chatName) {
		return agent.chatName;
	}
	return agent.userName;
}

class Error extends React.Component {
	render() {
		if (!this.props.msg) {
			return null;
		}
		return <p className={this.props.className+" error"}>{this.props.msg}</p>
	}
}

const AgentTags = ({ icon, title, displayName, click }) => {
	return (
		<div className="agentPresence" title={title}>
			<div className="candidate">
				<span className="agent">{displayName}</span>
			</div>
			<div className="remove-agent" onClick={click}>
				<i className={icon}></i>
			</div>
		</div>
	)
}

class Agent extends React.Component {
	constructor(props) {
		super(props);
	}
	getAttribute (agent) {
		var acceptChat = agent.acceptChat;
		if (this.props.agentPresence) {
			agent = this.props.agentPresence
		}

		var attr = {};
		if (agent.online && agent.acceptChat) {
			attr.title = I("Accepts chat");
			attr.klass = "acceptChat fa fa-check-circle-o";
		} else if (agent.online && !agent.acceptChat) {
			attr.title = I("Does not accept chat");
			attr.klass = "onlineNoChat fa fa-circle-o";
		} else {
			attr.title = I("Offline");
			attr.klass = "fa fa-circle-o";
		}

		return attr;
	}
	render() {
		var props = this.props
		, agent = props.getAgent(props.agentId)
		, displayName = agentNameAndChatName(agent)
		, newlyAdded
		, addRemoveText
		, addRemoveTitle
		, removeOrAdd
		, showAddRemoveLink = agent.id != initialChatData.agentID && agent.id != props.ownerId
		, tagStyle = props.tagStyle;
		;

		if (!matchAny(props.filter, displayName.toLowerCase())) {
			return null;
		}

		switch (props.context) {
			case "participants":
				showAddRemoveLink = false;
				break;
			case "candidates":
				addRemoveText = I("Add");
				addRemoveTitle = "";
				removeOrAdd = function() {
					props.addAgent(agent);
				}
				break;
			case "added":
				// We're rendering agents that are already
				// added or about to be added to an existing
				// chat
				newlyAdded = !props.alreadyAdded[agent.id];
				if (newlyAdded) {
					addRemoveText = I("Joining");
					addRemoveTitle = I("new to this chat");
				} else {
					if (this.props.invited) {
						addRemoveText = I("Uninvite");
					} else {
						addRemoveText = I("Remove");
					}
					addRemoveTitle = I("already added");
				}
				removeOrAdd = function() {
					props.removeAgent(agent);
				}
				break;
			default:
				throw new Error("handle case "+props.context);
		}

		var addRemoveLink;
		if (showAddRemoveLink) {
			addRemoveLink = <button
				data-qa-id={"collab-addRemove-agent-"+displayName}
				href="#"
				title={addRemoveTitle}
				onClick={removeOrAdd}
				className="chataddagent btn-blue pull-right"
			>
				{addRemoveText}
			</button>
		}

		var attr = this.getAttribute(agent);
		return (tagStyle ? <AgentTags title={attr.title} displayName={displayName} icon={"icon-circled-times"} click={removeOrAdd}/>
		: <div
			className="agentPresence"
			title={attr.title}
		>
			<div className="candidate">
				<i className={attr.klass}/>
				<label className="agent">{displayName}</label>
			</div>
			{addRemoveLink}
		</div>
		)
	}
}

class SelectedAgentInput extends React.Component {
	state = {
		dimensions: {
		width: -1,
		height: -1,
		},
	}

	render() {
		const { width, height } = this.state.dimensions
		const {
			addedAgentList
			, toggleSelectedAgents
			, showSelectedAgents
			, addedAgentTags
		} = this.props

		const tagWidth = CHAT_REPLY_INPUT_TAGS_WIDTH;
		let inputFieldCapacity = (width - tagWidth); //minus 60 for "more" tag
		let fittedTagsCount = 0;
		let fittedTags = [];

		$.each(addedAgentTags, (i, v) => {
			// input field gets less and less after each tags is added
			inputFieldCapacity -= tagWidth;
			// in the meantime, tags are pushed as long there is input capacity
			if(inputFieldCapacity > 0) {
				fittedTagsCount ++;
				fittedTags.push(v);
			}
		});
		// count remaing tags which are not fitted/included
		let remainingTags = addedAgentTags.length - fittedTagsCount;

		return (
		<Measure
			bounds
			onResize={contentRect => {
			this.setState({ dimensions: contentRect.bounds })
			}}
		>
			{({ measureRef }) => (
			<div className="tab-column">
				<div className="filterheader added-agent">
					<div className="label-added-agent"><span>{I("In Chat:")}</span></div>
					<div ref={measureRef} className="input-selected-agent-container">
						<ul className="barelist-tags">
							{fittedTags}
						</ul>
						{remainingTags > 0 && <div className="more-tags" onClick={toggleSelectedAgents}>
							<span>{remainingTags}{I(" more")}</span>
						</div>}
					</div>
					<div className="panel-trigger" onClick={toggleSelectedAgents}><i className="icon-add"></i></div>
				</div>
				{showSelectedAgents && <div className="scrollable">
				<div className="filter-title"><span>{I("IN THIS CHAT")}</span></div>
					{addedAgentList}
				</div>}
			</div>
			)}
		</Measure>
		)
	}
}

class AgentList extends React.Component {
	constructor(props) {
		super(props);
		var i
		, agentIds = props.agentIds
		, alreadyAdded = {}
		, agent
		;

		for (var i=0; i<agentIds.length; i++) {
			agent = props.getAgent(agentIds[i]);
			if (!agent) {
				continue;
			}
			alreadyAdded[agentIds[i]] = agent;
		}

		/*
		 * FIXME Known bug: If agent A invites Agent B, and keep the
		 * AgentList UI open (sees agent B in "Agents" list for the
		 * chat), and Agent  B rejects the chat Agent B name will
		 * disappear from "Agents" list seen by Agent A. This is as
		 * expected. But if Agent A then searches for Agent B in the
		 * candidate list Agent B won't appear (BUG). This bug happen
		 * because Agent A AgentList UI still think that Agent B is in
		 * the state.alreadyAdded list. A possible fix is to move
		 * handling of AgentList.state into redux.
		 */
		this.state = {
			selectedAreaId: {},
			alreadyAdded: alreadyAdded,
			mutate: {
				toRemoveMap: {},
				toAddMap: {},
			},
			filterArea: [],
			filterAgent: [],
			filterAddedAgent: [],
			showAreas: false,
			showAgents: false,
			showSelectedAgents: false
		};

		this.filterArea = this.filterArea.bind(this);
		this.filterAddedAgent = this.filterAddedAgent.bind(this);
		this.filterAgent = this.filterAgent.bind(this);
		this.doAddAgent = this.doAddAgent.bind(this);
		this.removeAgent = this.removeAgent.bind(this);
		this.addAgent = this.addAgent.bind(this);
		this.cancel = this.cancel.bind(this);
	}
	addAgent(agent) {
		delete this.state.mutate.toRemoveMap[agent.id];
		this.state.mutate.toAddMap[agent.id] = agent;
		this.setState({
			mutate: {
				toRemoveMap: this.state.mutate.toRemoveMap,
				toAddMap: this.state.mutate.toAddMap,
			}
		});
	}
	addAgents(area) {
		var state = this.state;
		$.each(area.agents, function(i, agent) {
			delete state.mutate.toRemoveMap[agent.id];
			state.mutate.toAddMap[agent.id] = agent;
		});
		this.setState({
			mutate: {
				toRemoveMap: state.mutate.toRemoveMap,
				toAddMap: this.state.mutate.toAddMap,
			}
		});
	}
	removeAgent(agent) {
		this.state.mutate.toRemoveMap[agent.id] = agent;
		delete this.state.mutate.toAddMap[agent.id];
		this.setState({
			mutate: {
				toRemoveMap: this.state.mutate.toRemoveMap,
				toAddMap: this.state.mutate.toAddMap,
			}
		});
	}
	agentSorter(a, b) {
		var aname = agentNameAndChatName(a)
		, bname = agentNameAndChatName(b)
		;

		if (aname < bname) {
			return -1;
		}
		if (aname > bname) {
			return 1;
		}
		return 0;
	}
	collectCandidates(areas, filter) {
		var seen = {}
		, ret = []
		, state = this.state
		;

		if (!filter) {
			return ret;
		}

		$.each(areas, function(i, area) {
			$.each(area.agents, function(i, agent) {
				if (state.alreadyAdded[agent.id]) {
					if (!state.mutate.toRemoveMap[agent.id]) {
						return;
					}
				}
				if (state.mutate.toAddMap[agent.id]) {
					return;
				}
				if (filter(area, agent)) {
					if (seen[agent.id]) {
						return;
					}
					seen[agent.id] = true;

					ret.push(agent);
				}
			});
		});
		return ret;
	}
	matchChosen(area) {
		return this.state.selectedAreaId[area.id];
	}
	matchArea(area) {
		return matchAny(this.state.filterArea, area.name.toLowerCase());
	}
	matchAgent(agent) {
		return matchAny(this.state.filterAgent, agentNameAndChatName(agent).toLowerCase());
	}
	matchAddedAgentFilter(agent) {
		return matchAny(this.state.filterAddedAgent, agentNameAndChatName(agent).toLowerCase());
	}
	isMutated() {
		var pristine = {}
		, toRemove = this.state.mutate.toRemoveMap
		, toAdd = this.state.mutate.toAddMap
		, k
		;

		$.each(this.props.agentIds, function(i, id) {
			pristine[id] = true;
		});

		for (var k in toRemove) {
			if (!toRemove.hasOwnProperty(k)) { continue; }
			if (pristine[toRemove[k].id]) {
				return true;
			}
		}

		for (var k in toAdd) {
			if (!toAdd.hasOwnProperty(k)) { continue; }
			if (!pristine[toAdd[k].id]) {
				return true;
			}
		}

		return false;
	}
	countOtherAgents(area) {
		var n = 0;
		if (!area.agents) {
			return 0;
		}
		$.each(area.agents, (i, agent) => {
			if (agent.id == initialChatData.agentID) {
				return;
			}
			n++;
		});
		return n;
	}
	filterAgent(e) {
		var v = e.target.value;
		if (v.length > 0) {
			v = v.trim()
			if (v.length == 0) return;
		}
		this.setState({
			filterAgent: toFilterChunks(v),
			showAgents: true
		});
	}
	filterAddedAgent(e) {
		var v = e.target.value;
		if (v.length > 0) {
			v = v.trim()
			if (v.length == 0) return;
		}
		this.setState({
			filterAddedAgent: toFilterChunks(v),
			showSelectedAgents: true
		});
	}
	filterArea(e) {
		var v = e.target.value;
		if (v.length > 0) {
			v = v.trim()
			if (v.length == 0) return;
		}
		this.setState({
			filterArea: toFilterChunks(v),
			showAreas: true
		});
	}
	clearMutation(ack) {
		this.setState({
			mutate: {
				toRemoveMap: {},
				toAddMap: {},
			},
		});
	}
	cancel() {
		this.clearMutation();
		this.props.onDone();
	}
	doAddAgent() {
		this.doSetAgent(this.props.chat.sessionId, this.state.alreadyAdded, this.state.mutate, (ack) => {
			if (ack.error) {
				this.props.showError(I("An error occurred. {ERROR}").replace('{ERROR}', ack.error));
				return;
			}
			this.cancel();
		});
	}
	doSetAgent(sessionId, alreadyAdded, mutate, callback) {
		var finalist = {}
		, agentIds = []
		;

		$.each(alreadyAdded, function(id, agent) {
			finalist[id] = agent;
		});
		$.each(mutate.toRemoveMap, function(id, agent) {
			delete finalist[id];
		});
		$.each(mutate.toAddMap, function(id, agent) {
			finalist[id] = agent;
		});

		$.each(finalist, function(id, agent) {
			agentIds.push(finalist[id].id);
		});
		agentIds.sort();

		setAgents({ sessionId, agentIds }, callback);
	}
	toggleAreas = () => {
		this.setState({
			showAreas: !this.state.showAreas
		})
	}
	toggleAgents = () => {
		this.setState({
			showAgents: !this.state.showAgents
		})
	}
	toggleSelectedAgents = () => {
		this.setState({
			showSelectedAgents: !this.state.showSelectedAgents
		})
	}
	render() {
		var props = this.props
		, state = this.state
		, areas = props.areas
		, agentIds = props.agentIds
		, that = this
		, isSelected = function(id) { return state.selectedAreaId[id]; }
		, i
		, agent
		, hasAreaSelected = false
		, hasAreaFilter = state.filterArea.length > 0
		, hasAgentFilter = state.filterAgent.length > 0
		, areaItems = []
		, testScroll = false // TODO urlParams['chat.testscroll'] // If true add phony areas/agents for testing scrolling of areas/agents list
		;

		$.each(areas, (i, area) => {
			var areaName = shortenText(area.name,30)
			, changeArea = function(e) {
				state.selectedAreaId[area.id] = $(e.target).is(':checked')
				that.setState({selectedAreaId: state.selectedAreaId})
			}
			, addAll = function() {
				that.addAgents(area);
			}
			;

			if (that.countOtherAgents(area) == 0) {
				return;
			}

			if (state.selectedAreaId[area.id]) {
				hasAreaSelected = true;
			}

			if (!matchAny(state.filterArea, area.name.toLowerCase())) {
				return;
			}

			let labelStyle = {
				textTransform: 'none',
			};
			if (isSelected(area.id)) {
				labelStyle.fontWeight = 'bold';
			} else {
				labelStyle.fontWeight = 'initial';
			}

			var cid=props.id+"_area_ckbox_"+area.id;
			areaItems.push(<li key={props.id+"_area_select_"+area.id}>
				<div className="candidate">
					<input
						data-qa-id={"collab-cb-area"+areaName}
						type="checkbox"
						id={cid}
						onChange={changeArea}
						checked={state.selectedAreaId[area.id]}
					/>
					<label htmlFor={cid} style={labelStyle} title={area.name}>{areaName}</label>
				</div>
				<button data-qa-id={"collab-add-area-"+areaName} onClick={addAll} className="chataddagent btn-blue pull-right">{I("Add")}</button>
			</li>);
		});

		if (testScroll) for (var i=1; i<=30; i++) {
			areaItems.push(<li key={props.id+"_area_select_phony_"+i}>
				<div className="candidate">
				{"Phony Area-" + i}
				</div>
				<button data-qa-id={"collab-add-area-"+i} className="chataddagent btn-blue pull-right">{I("Add")}</button>
			</li>);
		}

		var areaList = <ul className="barelist">
			{areaItems}
		</ul>

		var matcher;
		/*
		* We have three parameters that control how the agent list candidates are shown:
		*
		* 1. Chosen areas (checkbox checked)
		* 2. Filtered areas
		* 3. Filtered agents
		*
		* These are all binary states (chosen or not, filtered
		* or not) hence we have 8 possible states which are
		* handled below:
		*/
		if (hasAreaSelected) {
			if       (!hasAreaFilter && !hasAgentFilter) {
				// Show all agents from chosen areas
				matcher = function(area, agent) {
					return that.matchChosen(area);
				};
			} else if( hasAreaFilter && !hasAgentFilter) {
				// Show agents from filtered chosen areas
				matcher = function(area, agent) {
					return that.matchChosen(area) && that.matchArea(area);
				};
			} else if(!hasAreaFilter &&  hasAgentFilter) {
				// Show filtered agents from chosen areas
				matcher = function(area, agent) {
					return that.matchChosen(area) && that.matchAgent(agent);
				};
			} else if( hasAreaFilter &&  hasAgentFilter) {
				// Show filtered agents from filtered chosen area
				matcher = function(area, agent) {
					return that.matchChosen(area) && that.matchArea(area) && that.matchAgent(agent);
				};
			} else {
				throw new Error("FIXME we should not reach here");
			}
		} else {
			// No areas chosen
			if       (!hasAreaFilter && !hasAgentFilter) {
				// Do nothing
			} else if( hasAreaFilter && !hasAgentFilter) {
				// Do nothing - area already filtered in areaList above
			} else if(!hasAreaFilter &&  hasAgentFilter) {
				// Show filtered agents from all areas
				matcher = function(area, agent) {
					return that.matchAgent(agent);
				};
			} else if( hasAreaFilter &&  hasAgentFilter) {
				// Show agents from filtered areas
				matcher = function(area, agent) {
					return that.matchArea(area) && that.matchAgent(agent);
				};
			} else {
				throw new Error("FIXME we should not reach here");
			}
		}

		var candidates = this.collectCandidates(areas, matcher);

		// Render candidates
		var agentItems = [];
		var agentList;
		if (candidates.length > 0) {
			candidates.sort(this.agentSorter);
			$.each(candidates, (i, agent) => {
				if (agent.id == initialChatData.agentID) {
					return null;
				}
				agentItems.push(<li key={props.id+"_candidate_agent_"+agent.id}>
					<Agent
						getAgent={that.props.getAgent}
						removeAgent={that.removeAgent}
						addAgent={that.addAgent}
						context='candidates'
						agentId={agent.id}
						ownerId={props.ownerId}
						agentPresence={props.agentPresence[agent.id]}
						filter={state.filterAgent}
					/>
				</li>);
			})

			if (testScroll) for(i=1;i<=30; i++) {
				agentItems.push(<li key={props.id+"_candidate_agent_phony_"+i}>
					{"Phony Agent-" + i}
				</li>);
			}
			agentList = <ul className="barelist">
				{agentItems}
			</ul>
		}

		// Render agents that are already added or to be invited
		var agents = {};
		$.each(agentIds, function(i, agentId) {
			if (state.mutate.toRemoveMap[agentId]) {
				// This is an agent that is about to be removed.
				return;
			}
			agent = that.props.getAgent(agentId);
			if (!agent) {
				// this should not happen
				return;
			}
			agents[agent.id] = agent;
		});
		$.each(state.mutate.toAddMap, function(id, agent) {
			agents[agent.id] = agent;
		});

		var addedAgents = [];
		$.each(agents, function(id, agent) {
			addedAgents.push(agent);
		});
		addedAgents.sort(this.agentSorter);

		var addedAgentItems = [];
		var addedAgentTags = [];
		$.each(addedAgents, (i, agent) => {
			if (state.filterAddedAgent.length > 0) {
				if (!matchAny(state.filterAddedAgent, agentNameAndChatName(agent).toLowerCase())) {
					return null;
				}
			}

			addedAgentTags.push(<li key={props.id+"_added_agent_"+agent.id}>
				<Agent
					tagStyle={true} //set true if want tag design
					removeAgent={that.removeAgent}
					getAgent={that.props.getAgent}
					addAgent={that.addAgent}
					context='added'
					agentId={agent.id}
					ownerId={props.ownerId}
					agentPresence={props.agentPresence[agent.id]}
					filter={[]}
					alreadyAdded={state.alreadyAdded}
				/>
			</li>)

			addedAgentItems.push(<li key={props.id+"_added_agent_"+agent.id}>
				<Agent
					removeAgent={that.removeAgent}
					getAgent={that.props.getAgent}
					addAgent={that.addAgent}
					context='added'
					agentId={agent.id}
					ownerId={props.ownerId}
					agentPresence={props.agentPresence[agent.id]}
					filter={[]}
					alreadyAdded={state.alreadyAdded}
				/>
			</li>);
		})

		if (testScroll) for (var i=1; i<=30; i++) {
			addedAgentItems.push(<li key={props.id+"_added_agent_foo_"+i}>
				{"Added agent "+i}
			</li>);
		}
		var addedAgentList = <ul className="barelist">
			{addedAgentItems}
		</ul>

		// var addedAgentUItagsList = <ul className="barelist-tags">
		// 	{addedAgentUItags}
		// </ul>

		let uiFilterArea = <div className="col-sm-6 col-md-4">
			<div className="filterheader">
				<p className="caption">{I("Filter Area")}</p>
				<input data-qa-id="collab-filter-area" type="text" placeholder={I("area name...")} onKeyUp={this.filterArea}/>
			</div>
			<div className="scrollable">
				{areaList}
			</div>
		</div>;

		let uiFilterAgent = <div className="col-sm-6 col-md-4">
			<div className="filterheader">
				<p className="caption">{I("Filter Agent")}</p>
				<input data-qa-id="collab-filter-agent" type="text" placeholder={I("type agent name...")} onKeyUp={this.filterAgent}/>
			</div>
			<div className="scrollable">
				{agentList}
			</div>
		</div>;

		let uiSelectedAgent = <div className="col-sm-6 col-md-4">
			<div className="filterheader">
				<p className="caption">{I("Agents")}</p>
				<input data-qa-id="collab-find-agent" type="text" placeholder={I("find agent...")} onKeyUp={this.filterAddedAgent}/>
			</div>
			<div className="scrollable">
				{addedAgentList}
			</div>
			<div className="group-chat-action">
				<button
					data-qa-id="collab-add-cancel"
					type="button"
					onClick={this.cancel}
					className="btn-blue pull-right chataddagent"
				>
					{I('Cancel')}
				</button>

				<button
					data-qa-id="collab-add-ok"
					type="button"
					onClick={this.doAddAgent}
					className="btn-blue pull-right chataddagent"
					disabled={this.props.connectStatus === "connecting" || !this.isMutated()}>
				{I('OK')}
				</button>
			</div>
		</div>;

		let uiNotice = <div className="tab-column">
			{ this.props.connectStatus === "connecting" ?
			<Error className="pull-right" msg={I("Connecting, please wait ...")}/>
			: this.props.dead?
			<Error className="pull-right" msg={I("This chat session has ended.")}/>
			: this.props.error?
			<Error className="pull-right" msg={this.props.error}/>
			:""
			}
		</div>;

		//new UI part
		//TODO: rename or replace old one after conformation of `new v5 ReplyBox`

		let newUiFilterArea = <div className="tab-column area">
			<div className="filterheader">
				<input data-qa-id="collab-filter-area" type="text" placeholder={I("Search areas")} onKeyUp={this.filterArea}/>
				<div className="panel-trigger" onClick={this.toggleAreas}><i className="icon-add"></i></div>
			</div>
			{this.state.showAreas && areaItems.length > 0 && <div className="scrollable">
				<div className="filter-title"><span>{I("AREAS")}</span></div>
				{areaList}
			</div>}
		</div>;

		let newUiFilterAgent = <div className="tab-column">
			<div className="filterheader">
				<input data-qa-id="collab-filter-agent" type="text" placeholder={I("Search agents")} onKeyUp={this.filterAgent}/>
				<div hidden={!agentList} className="panel-trigger" onClick={this.toggleAgents}><i className="icon-add"></i></div>
			</div>
			{this.state.showAgents && agentList && <div className="scrollable">
				<div className="filter-title"><span>{I("AGENTS")}</span></div>
				{agentList}
			</div>}
		</div>;

		let newUiSelectedAgent = <SelectedAgentInput
			toggleSelectedAgents={this.toggleSelectedAgents}
			showSelectedAgents={this.state.showSelectedAgents}
			addedAgentList={addedAgentList}
			addedAgentTags={addedAgentTags}
		/>

		let uiChatAction = <div className="group-chat-action">
			<button
				data-qa-id="collab-add-cancel"
				type="button"
				onClick={this.cancel}
				className="btn-blue pull-right chataddagent"
			>
				{I('Cancel')}
			</button>

			<button
				data-qa-id="collab-add-ok"
				type="button"
				onClick={this.doAddAgent}
				className="btn-blue pull-right chataddagent"
				disabled={this.props.connectStatus === "connecting" || !this.isMutated()}>
			{I('OK')}
			</button>
		</div>

		const newAgentsTab = <div className="addAgentsTab v5">
			<div className="row-notice">
				{uiNotice}
			</div>
			<div className="row-filter">
				{newUiFilterArea}
				{newUiFilterAgent}
				{newUiSelectedAgent}
				{uiChatAction}
			</div>
		</div>

		return newAgentsTab;
	}
}

export class AddAgent extends React.Component {
	componentWillUnmount() {
		unsubscribeAgentPresence();
	}

	render() {
		if (this.props.error) {
			let msg = I("An error occurred. {ERROR}").replace('{ERROR}', this.props.error);
			return <span style={{color:'red'}}>{msg}</span>
		}

		if (this.props.chat.gettingCandidates) {
			return <div><i className="fa fa-spinner fa-spin"/></div>;
		}

		if (this.props.list && this.props.list.length == 0) {
			return <div>{I("No other agents available at the moment.")}</div>;
		}

		return <AgentList
			chat={this.props.chat}
			showError={this.props.showError}
			onDone={this.props.onDone}
			getAgent={this.props.getAgent}
			areas={this.props.list}
			agentIds={this.props.agentIds}
			ownerId={this.props.ownerId}
			agentPresence={this.props.agentStore}
			error={this.props.error}
			dead={this.props.dead}
			connectStatus={this.props.uiData.wsConnected?"connected":"connecting"}
		/>
	}
}
