// TODO: NOTE.1
// TODO: chart options get from report's keys. This requires new endpoint to
//       carry relation between report-id and report-key-id. Use normalized data
//       as there are already endpoint carry full report data and full report
//       keys / groups data.
// TODO: fix report contact-top-list ajax request as it triggers avatar endpoint
//       base on old chart layout. See asyncs/workflow.js:fetchAvatarByClient.
// TODO: report keys and groups endpoint is called everytime edit or creating
//       new custom report. This is unacceptable. See
//       statisticsCtnr.js:mapStatisticsCallbacks. This will impact one of the
//       todo task mentioned above.
// TODO: switching to statistics page is using window.location and cause the SPA
//       reload everything when clicking from icon base collapsed menu. It makes
//       SPA pointless, unacceptable.
// TODO: DropdownGroup component has some conditional check on dependent states
//       within the group. Almost similar (should be same) conditional check use
//       inside ChartEditor.js:emoveFalsyFields. This conditional check should
//       be refactored and share within the same code.
// TODO: fix CT_LINE chart as it too static without considering custom report.
// TODO: fix async/statistics.js:MAP_SYSTEM_REPORTS_REPORT_CHART to share common
//       code constants.js:WIDGET_CHART_TYPE.
// TODO: cross layout chart data reference is not tested proper for new grid
//       layout which base on shortid. That's using grid to make report page
//       reference overview chart data need to double check in future.
// TODO: fix the mobile responsive which previously working but because of this
//       task likely broken it.
// TODO: Chart component withMountingEffect did not work for report page, report
//       page chart component is using old mounting effect. Fix this when report
//       page also need to support custom grid layout.
// TODO: There is a bug where plus chart button during editing may overlap
//       existing chart because of the same shortid. But it don't always happen,
//       quick solution is the reload page, if the editing need to be saved, then
//       save before reload. 'shortid' only appear in front-end for the lifetime
//       of the page and never store into backend.
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import each from 'lodash/each'
import indexOf from 'lodash/indexOf'
import styled from 'styled-components'
import update from 'immutability-helper'
import {
  AT_CHOOSE_AGENTS,
  AT_CHOOSE_GROUPS,
  CHART_TYPE_NAMES,
  CL_OVERVIEW,
  CS_EXIST,
  CS_REPORT,
  DT_ABSOLUTE,
  DT_RELATIVE,
  DDG_EXISTING_OV_CHARTS,
  DDG_WIDGET_REPORT_TYPE,
  DDG_WIDGET_REPORT_LIST,
  DDG_WIDGET_CHART_TYPE,
  UNSELECT,
  WIDGET_CHART_TYPE
} from '../../common/v5/constants'
import {
  DEF_CHARTS,
  DEF_REPORT_PARAM,
  TXT_CANCEL,
  TXT_INVALID_CHART_TYPE,
  TXT_INVALID_REPORT_ID,
  TXT_SAVE,
  TXT_UNKNOWN_CHART_TYPE,
  TXT_USE_PARAMETERS
} from '../../common/v5/statisticConstants'
import { fontSizeGeneral } from '../../styles/_variables'
import {
  findOverviewChartOnly,
  getStartDay,
  optionsFromReportData,
  reportDataFromReports
} from '../../redux/selectors/statistics'
import { withChartParameters } from '../../containers/chart'
import {
  composeWithDisplayName,
  withUnmountWhenHidden
} from '../../reactcomponents/hocs'
import Button from '../../reactcomponents/Button'
import { ArrayDropdowns } from './DropdownGroup'
import ReportParameters from './ReportParameters'

const useDefaultReportParameters = agentTimezoneOffset => useMemo(() => {
  const startDay = getStartDay(agentTimezoneOffset)
  const defReportParam = update(DEF_REPORT_PARAM, { $unset: ['reportType'] })
  return update(
    defReportParam,
    { startTime: { $set: startDay }, endTime: { $set: startDay } }
  )
}, [agentTimezoneOffset])

const trackRemoveKeys = (array, object, ...keys) => {
  each(keys, v => {
    if (!object[v]) {
      array.push(v)
      object[v] = true
    }
  })
}

const createTrackRemoveKeys = (array, object) => (...keys) => {
  return trackRemoveKeys(array, object, ...keys)
}

const keysWithValidFalsy = {
  agentType: true
}

// TODO: NOTE.1 this field removal should base on report options also.
const removeFalsyFields = obj => {
  const keys = []
  const keyMap = {}
  const remover = createTrackRemoveKeys(keys, keyMap)
  each(obj, (v, k) => {
    if (!keysWithValidFalsy[k] && !v) {
      remover(k)
    }
  })
  if (obj.dateType === DT_RELATIVE) {
    remover('startTime', 'endTime')
  } else if (obj.dateType === DT_ABSOLUTE) {
    remover('relativeDateType')
  }
  if (obj.agentType !== AT_CHOOSE_AGENTS) {
    remover('users')
  }
  if (obj.agentType !== AT_CHOOSE_GROUPS) {
    remover('groups')
  }
  if (!keys.length) {
    return obj
  }
  return update(obj, { $unset: keys })
}

const getChartsFromReportData = reportData => {
  if (!reportData) {
    return DEF_CHARTS
  }
  const { Name, SystemReport } = reportData
  if (!SystemReport) {
    return DEF_CHARTS
  }
  const charts = WIDGET_CHART_TYPE[Name]
  if (!charts || !charts.length) {
    return DEF_CHARTS
  }
  return update(DEF_CHARTS, { $push: charts })
}

const chartsFromReportData = reportData => {
  const cs = []
  each(getChartsFromReportData(reportData), v => {
    let name = CHART_TYPE_NAMES[v]
    if (typeof name === 'undefined') {
      if (process.env.NODE_ENV !== 'production') {
        console.log('dbg: fix this no name chart:', v)
      }
      name = TXT_UNKNOWN_CHART_TYPE.replace('{CHART_TYPE}', v)
    }
    cs.push({ id: v, name })
  })
  return cs
}

const defaultChart = DEF_CHARTS[0]

const useChartEditorState = (
  agentTimezoneOffset,
  breakpointLayout,
  chartMap,
  onSave,
  pinReportsById,
  reportsById,
  systemReportsByName
) => {
  const [enableParameters, setEnableParameters] = useState(false)
  const [
    param,
    setParam
  ] = useState(useDefaultReportParameters(agentTimezoneOffset))
  const handleEnableParametersChange = useCallback(() => {
    setEnableParameters(enable => !enable)
  }, [])
  const reportsGetters = useMemo(() => ([
    () => systemReportsByName,
    () => reportsById
  ]), [reportsById, systemReportsByName])
  const [chartSource, setChartSource] = useState(CS_REPORT)
  const [chart, setChart] = useState(defaultChart)
  const [report, setReport] = useState(UNSELECT)
  const [existChart, setExistChart] = useState('')
  const [existOverviewCharts, existOverviewChartsByIdx] = useMemo(() => {
    const array = []
    const object = {}
    each(breakpointLayout, ({ i }, index) => {
      object[i] = { index }
      array.push({ id: i, name: index + '' })
    })
    return [array, object]
  }, [breakpointLayout])
  const existChartId = useMemo(() => {
    if (chartSource === CS_EXIST) {
      if (existChart) {
        const chartIndex = existOverviewChartsByIdx[existChart]
        if (chartIndex) {
          return { layout: CL_OVERVIEW, idx: existChart }
        }
      }
    }
  }, [chartSource, existChart, existOverviewChartsByIdx])
  const chartLayoutId = useMemo(() => {
    const data = { type: chartSource, chart }
    if (chartSource === CS_EXIST) {
      if (existChartId) {
        data.id = existChartId
      }
    } else {
      data.id = report
      if (enableParameters) {
        data.param = removeFalsyFields(param)
      }
    }
    if (process.env.NODE_ENV !== 'production') {
      console.log('debug chart layout id:', data)
    }
    return data
  }, [
    chartSource,
    chart,
    enableParameters,
    existChartId,
    param,
    report
  ])
  const chartFinder = useCallback(
    chartId => findOverviewChartOnly(
      { [CL_OVERVIEW]: chartMap },
      pinReportsById,
      chartId
    ),
    [chartMap, pinReportsById]
  )
  const [targetReport, targetSource] = useMemo(() => {
    if (chartSource !== CS_EXIST) {
      return [report, chartSource]
    } else if (!chartLayoutId.id) {
      return [UNSELECT, chartSource]
    }
    const { id, type } = chartFinder(chartLayoutId)
    return [id, type]
  }, [chartFinder, chartSource, chartLayoutId, report])
  const reportData = useMemo(
    () => reportDataFromReports(targetReport, targetSource, reportsGetters),
    [targetReport, targetSource, reportsGetters]
  )
  const charts = useMemo(() => chartsFromReportData(reportData), [reportData])
  const valueSetterMap = useMemo(() => ({
    [DDG_WIDGET_REPORT_TYPE]: setChartSource,
    [DDG_WIDGET_REPORT_LIST]: setReport,
    [DDG_WIDGET_CHART_TYPE]: setChart,
    [DDG_EXISTING_OV_CHARTS]: setExistChart
  }), [])
  const valueSetter = useCallback((type, selection) => {
    valueSetterMap[type](selection)
  }, [valueSetterMap])
  const [showReportOption, setShowReportOption] = useState(true)
  const [showExist, setShowExist] = useState(false)
  const showSetterMap = useMemo(() => ({
    [CS_REPORT]: setShowReportOption,
    [CS_EXIST]: setShowExist
  }), [])
  const showSetter = useCallback(id => each(showSetterMap, (v, k) => {
    v(parseInt(k, 10) === id)
  }), [showSetterMap])
  const handleSelect = useCallback((index, type, selection) => {
    if (type === DDG_WIDGET_REPORT_TYPE) {
      showSetter(selection)
    }
    valueSetter(type, selection)
  }, [showSetter, valueSetter])
  const handleParamChange = useCallback((field, value) => {
    setParam(param => update(param, { [field]: { $set: value } }))
  }, [])
  const saveDisabled = useMemo(() => {
    const { id, chart } = chartLayoutId
    if (!id || id <= 0) {
      return TXT_INVALID_REPORT_ID.replace('{REPORT_ID}', id + '')
    } else if (!chart || chart <= 0) {
      return TXT_INVALID_CHART_TYPE.replace('{CHART_TYPE}', chart + '')
    }
  }, [chartLayoutId])
  const handleSave = useCallback(() => {
    if (saveDisabled) {
      return
    }
    // many of our charts is too static stick to system report and causing id
    // base report won't work. This is a work-around to solve the issue.
    let saveData
    if (chartSource === CS_REPORT && reportData.SystemReport) {
      saveData = update(chartLayoutId, { id: { $set: reportData.Name } })
    } else {
      saveData = chartLayoutId
    }
    onSave(saveData)
  }, [chartLayoutId, chartSource, onSave, reportData, saveDisabled])
  const data = useMemo(() => ([
    {
      data: DDG_WIDGET_REPORT_TYPE,
      selected: chartSource
    },
    {
      data: DDG_WIDGET_REPORT_LIST,
      selected: report,
      doNotRender: !showReportOption
    },
    {
      data: DDG_EXISTING_OV_CHARTS,
      doNotRender: !showExist,
      selected: existChart,
      source: existOverviewCharts
    },
    {
      data: DDG_WIDGET_CHART_TYPE,
      selected: chart,
      source: charts
    }
  ]), [
    chartSource,
    chart,
    charts,
    existChart,
    existOverviewCharts,
    report,
    showReportOption,
    showExist
  ])
  // reset chart type if previously selected option no longer valid due to
  // selected report changed.
  useEffect(() => {
    if (chart !== defaultChart) {
      if (indexOf(getChartsFromReportData(reportData), chart) < 0) {
        setChart(defaultChart)
      }
    }
  }, [chart, reportData])
  // reset exist chart if previously selected option no longer valid due to
  // chart report being removed.
  useEffect(() => {
    if (existChart) {
      if (!existOverviewChartsByIdx[existChart]) {
        setExistChart('')
      }
    }
  }, [existChart, existOverviewChartsByIdx])
  return [
    chartSource === CS_EXIST,
    report,
    optionsFromReportData(reportData),
    data,
    param,
    saveDisabled,
    handleSave,
    handleSelect,
    handleEnableParametersChange,
    enableParameters && chartSource !== CS_EXIST ? handleParamChange : null
  ]
}

//const StyledArrayDropdowns = styled(ArrayDropdowns)`
const StyledArrayDropdowns = styled(props => <ArrayDropdowns {...props} />)`
  &.c3-dropdown-group {
    display: flex;
    flex-direction: column;
    li {
      margin: unset;
      padding: 4px;
      div {
        font-size: ${fontSizeGeneral};
      }
    }
  }
`
const Parameters = composeWithDisplayName(
  'Parameters',
  memo,
  withUnmountWhenHidden,
  withChartParameters
)(ReportParameters)

//const StyledParameters = styled(Parameters)`
const StyledParameters = styled(props => <Parameters {...props} />)`
  &.c3-dropdown-group {
    display: flex;
    flex-direction: column;
    li {
      margin: unset;
      padding: 4px;
      .c3-dropdown, .date-select-dropdown {
        span {
          font-size: ${fontSizeGeneral};
        }
        i {
          font-size: 10px;
          margin-left: 4px;
          vertical-align: middle;
        }
      }
    }
  }
`
const EnableParameters = ({ className, enable, onChange }) => (
  <span className={className} onClick={onChange}>
    <input type='checkbox' checked={enable} readOnly />&nbsp;
    {TXT_USE_PARAMETERS}
  </span>
)

//const StyledEnableParametersBase = styled(EnableParameters)`
const StyledEnableParametersBase = styled(props => <EnableParameters {...props} />)`
  cursor: pointer;
  display: inline-block;
  font-size: ${fontSizeGeneral};
  margin-left: 4px;
  margin-top: 10px;
  & > input {
    cursor: pointer;
  }
`
const StyledEnableParameters = withUnmountWhenHidden(StyledEnableParametersBase)

const StyledInputDIV = styled.div`
  height: calc(100% - 52px);
  padding: 8px;
  width: 100%;
`
const Save = props => <Button text={TXT_SAVE} {...props} />

const Cancel = props => <Button text={TXT_CANCEL} color='yellow' {...props} />

const StyledActionDIV = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 8px;
  width: 100%;
`
const SaveCancel = ({ onCancel, onSave, saveDisabled }) => (
  <StyledActionDIV>
    <Save disabled={saveDisabled} onClick={onSave} />&nbsp;
    <Cancel onClick={onCancel} />
  </StyledActionDIV>
)

const Div = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const ChartEditorBase = ({
  agentTimezoneOffset,
  breakpointLayout,
  chartMap,
  onCancel,
  onSave,
  pinReportsById,
  reportsById,
  systemReportsByName
}) => {
  const [
    noEnableParameters,
    reportId,
    options,
    data,
    param,
    saveDisabled,
    handleSave,
    handleSelect,
    handleEnableParametersChange,
    handleParamChange
  ] = useChartEditorState(
    agentTimezoneOffset,
    breakpointLayout,
    chartMap,
    onSave,
    pinReportsById,
    reportsById,
    systemReportsByName
  )
  const enableParameters = !!handleParamChange
  return (
    <Div>
      <StyledInputDIV>
        <StyledArrayDropdowns data={data} onSelect={handleSelect} />
        <StyledEnableParameters
          hidden={noEnableParameters}
          enable={enableParameters}
          onChange={handleEnableParametersChange}
        />
        <StyledParameters
          hidden={!enableParameters}
          onParametersChange={handleParamChange}
          options={options}
          parameters={param}
          reportId={reportId}
        />
      </StyledInputDIV>
      <SaveCancel
        onCancel={onCancel}
        onSave={handleSave}
        saveDisabled={saveDisabled}
      />
    </Div>
  )
}

const ChartEditor = composeWithDisplayName(
  'ChartEditor',
  memo,
  withUnmountWhenHidden
)(ChartEditorBase)

export default ChartEditor
