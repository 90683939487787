import React, {PureComponent} from 'react';
//dev
import MenuCtnr from '../../common/v5/menuCtnr';
import ToggleSideBar from './ToggleSideBar';
import {
	CENTION_LOGO,
	CENTION_LOGO_MINI
} from '../../common/v5/constants';
import { CancellableSimpleLink } from '../../containers/link';
import {
	getExtQueueType
} from '../../common/v5/helpers';

class AppHeader extends PureComponent {
	constructor(props){
		super(props);
	}
	toggleMainMenu = () => {
		this.props.toggleMainMenu();
	}
	handleSideBarToggle = () => {
		this.props.handleSideBarToggle();
	}
	handleSideBarCollapse = () => {
		this.props.handleSideBarCollapse();
	}
	handleHomePage = (e) => {
		e.preventDefault();
		this.props.onMainView();
	}
	render() {
		const {
			backNavHide,
			showErrandMobileView,
			showSideBar,
			collapseSideBar,
			showMobileView,
			disableCollapse
		} = this.props;
		let showMenu = true;
		if(showErrandMobileView){
			showMenu = showErrandMobileView && !collapseSideBar;
		}else {
			showMenu = !collapseSideBar;
		}
		let makeLogoClickable = true;
		let qType = getExtQueueType();
		if (qType.length > 0) {
			makeLogoClickable = false;
		}
		let logoWrapperStyle = {};
		if (collapseSideBar) {
			logoWrapperStyle = {margin: "auto", textAlign: "center"}
		}
		const hideLogo = features['hide-cention-logo'];
		let customStyledAppHeader = { borderRadius: "initial"}
		if(hideLogo && collapseSideBar) {
			customStyledAppHeader = { borderRadius: "initial", padding: "0px 15px"}
		}
		return (
			<div className="app-header" style={customStyledAppHeader} >
				{
					(hideLogo) &&
					<span
						className="app-back-nav"
						hidden={backNavHide}
						data-qa-id="QA_mainMenu_toggle"
						onClick={this.toggleMainMenu}
					>
						<i className="icon-chevron-left" />
					</span>
				}
				{
					(hideLogo) &&
					<MenuCtnr visible={showMenu} />
				}
				{
					(hideLogo) &&
					<ToggleSideBar
						visible={showSideBar || collapseSideBar || showMobileView}
						collapsed={collapseSideBar}
						data-qa-id="app-header-toggle-sidebar"
						mobileMode={showMobileView}
						onClick={this.handleSideBarToggle}
						disableCollapse={disableCollapse}
						onCollapse={this.handleSideBarCollapse}
					/>
				}
				<div className="side-logo" style={logoWrapperStyle} hidden={hideLogo}>
					{
						makeLogoClickable ?
						<CancellableSimpleLink onClick={this.handleHomePage} title={I("Back to Main")}>
							<img src={collapseSideBar ? CENTION_LOGO_MINI : CENTION_LOGO} alt="" />
						</CancellableSimpleLink>:
						<div>
							<img src={collapseSideBar ? CENTION_LOGO_MINI : CENTION_LOGO} alt="" />
						</div>
					}
				</div>
			</div>
		);
	}
}

export default AppHeader;
