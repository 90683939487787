import React, { useState, useEffect, Component} from 'react';
import { I, webRoot } from '../../common/v5/config';
import { FooterButton } from '../../reactcomponents/Admin';
import SwitchCheckbox from '../../reactcomponents/SwitchCheckbox';
import { SelectInputRow } from '../../reactcomponents/Form';
import { formatValueToNameObj } from '../../common/v5/helpers';
global.BRIGHT = 6

const BrightSettingV5 = (props) => {
    const [id, setId] = useState(0);
    const [keepOrgBright, setKeepOrgBright] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const [referenceCode, setReferenceCode] = useState(0);
    const [selectedCode, setSelectedCode] = useState(0);

    const [brightLanguageCodeList, setBrightLanguageCodeList] = useState([]);

    useEffect(() => {
        const br = props.brightSettings;
        if (props.id !== id) {
            setEnabled(br.enabled);
            setReferenceCode(br.referenceCode);
            setSelectedCode(parseInt(br.selectedCode, 10)||0);
            setId(props.id);
            setKeepOrgBright(br.keepOrgBright);
            setBrightLanguageCodeList(br.brightLanguageCodeList);
        }
        if(br.setKeepOrgBright !== setKeepOrgBright){
            setKeepOrgBright(br.keepOrgBright);
        }
        if(br.referenceCode !== referenceCode){
            setReferenceCode(br.referenceCode);
        }
        if(br.selectedCode !== selectedCode){
            setSelectedCode(parseInt(br.selectedCode, 10));
        }
        if(br.enabled !== enabled){
            setEnabled(br.enabled);
        }
    }, [props]);

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        setReferenceCode(value);
    };
  
    const handleChangeData = (a, b) => {
        setSelectedCode(a);
    }

    const saveOrg = () => {
        props.saveOrganization(BRIGHT, {
            brightSettingsPresent: true,
            enabled: enabled,
            referenceCode: referenceCode,
            selectedCode: selectedCode,
            brightLanguageCodeList: brightLanguageCodeList,
            keepOrgBright: keepOrgBright,
        });
    };

    const handleInputCheck = (checked) => {
        setKeepOrgBright(!checked);
    };

    const handleCheckBoxCheck = (checked) => {
        setEnabled(!checked);
    };

    if (
        typeof keepOrgBright === 'undefined') {
        return null;
    }

    if (brightLanguageCodeList.length <= 0) {
        return <div>{I("Loading settings for Bright.")}</div>;
    }

    let keepOrg = null;
    if (props.usedFor !== "org") {
        keepOrg = (
        <div className="form-group-org">
            <label className="col-lg-12">{I("Keep organization settings")} :</label>
            <div className="col-lg-12">
            <SwitchCheckbox
                active={keepOrgBright}
                buttonClassName={""}
                id={"orgActiveSwitch"}
                name={"organisationSetting"}
                type="checkbox"
                onClick={handleInputCheck}
            />
            </div>
        </div>
        );
    }

    if (props.usedFor === "area" && keepOrgBright) {
        return (
        <div className="col-lg-12">
            {keepOrg}
            <FooterButton cancel={props.cancelToOrg} save={saveOrg} />
        </div>
        );
    }

    return (
        <div className="col-lg-12 bright-setting-v5">
        {keepOrg}
        <div className="form-group-org">
            <label className="col-lg-12">{I("Bright integration enabled")}:</label>
            <div className="col-lg-12">
            <SwitchCheckbox
                active={enabled}
                buttonClassName={""}
                id={"orgActiveSwitch"}
                name={"organisationSetting"}
                type="checkbox"
                onClick={handleCheckBoxCheck}
            />
            </div>
        </div>
        <div className="form-group-org">
            <label className="col-lg-12">{I("Bright Survey Reference Code")}:</label>
            <div className="col-lg-12">
            <input
                name="referenceCode"
                className="form-control input-sm"
                type="text"
                value={referenceCode}
                onChange={handleInputChange}
            />
            </div>
            <div className="col-lg-1 col-sm-4"></div>
        </div>
        <div className="form-group-org">
            <div className="col-lg-12">
            <SelectInputRow
                id="selectedCode"
                name="selectedCode"
                className="selectedCode"
                label={I('Bright Language Code')}
                option={formatValueToNameObj(brightLanguageCodeList, 1, 1)}
                value={parseInt(selectedCode, 10)}
                onSelect={handleChangeData}
            />
            </div>
        </div>
        <FooterButton cancel={props.cancelToOrg} save={saveOrg} />
        </div>
    );
};
export default BrightSettingV5;