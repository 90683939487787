import React from 'react';
import {I} from '../../common/v5/config.js';
import ProfilePhoto from '../../reactcomponents/ProfilePhoto';
import SquareCheckbox from '../../reactcomponents/SquareCheckbox';

export class AgentBook extends React.Component {
	constructor(props){
		super(props);
		this.handleCloseAgentBook = this.handleCloseAgentBook.bind(this);
		this.handleInsertAgent = this.handleInsertAgent.bind(this);
		this.handleSearchInput = this.handleSearchInput.bind(this);
		this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.state = {
			searchText: ""
		}
	}
	handleCloseAgentBook(e){
		this.state.searchText = "";
		this.props.onToggle(false);
	}
	handleInsertAgent(){
		this.state.searchText = "";
		this.props.onReplyAdddress();
		this.props.onToggle(false);
	}
	handleShowAgents(id){
		this.props.onShowAgents(id);
	}
	handleSearchInput(e){
		this.setState({searchText: e.target.value})
	}
	handleSearchSubmit(e){
		const p = this.props;
		this.props.onHandleSearch(p.show, this.state.searchText);
		e.preventDefault();
	}
	handleSelect(v, id) {
		this.props.onSelect(id, v);
	}
	render() {
		const p = this.props;
		let agentList = [], agentInfo = "";
		let cbClass = "contact-book-backdrop popup", btnDisable = true;
		if(this.props.show) {
			cbClass = "contact-book-backdrop popup open";
		}
		if(p.agentBook){
			$.each(p.agentBook, (i,v) => {
				if (p.agentList && p.agentList[v.id]) {
					agentList.push(
						<li key={i} className={v.id === p.agent.id ? "current" : ""}>
							<SquareCheckbox data-qa-id={v.id} onClick={this.handleSelect}
								checked={p.agentList[v.id].selected}
							/>
							<a data-qa-id={"agent-book-list-"+v.name} onClick={(e) => this.handleShowAgents(v.id)}>{v.name}</a>
						</li>
					);
				}
			});
			if(p.agent.id != 0 && p.agent.name != ""){
				agentInfo = (
					<div className="user-card">
						<div className="user-avatar">
							<ProfilePhoto width={"70"} isAgent={true}
								photo={p.agent.avatar}
								className={"conversation-photo"} />
						{/* } */}
						</div>
						<div className="user-data">
							<div className="user-name">
								{p.agent.name}
							</div>
							<div className="user-title">
								{/*Expert in IT-dept - todo when upload customer photo*/}
							</div>
						</div>
					</div>
				);
				// btnDisable = false;
			}
			btnDisable = false;
		}

		let AgentButton = "";
		if(p.replyType === "to"){
			AgentButton = <button className="btn-blue" disabled={btnDisable} onClick={this.handleInsertAgent}>{I("Add")}</button>
		}
		return (
			<div className={cbClass}>
				<div className="contact-book popup-inner">
					<div className="contact-book-sidebar">
						<div className="popup-title">
							<h2>{I("Agent list")}</h2>
						</div>
						<div className="contact-book-menu">
							<ul>
								<li className="current"><a href="#">{I("Agent")}</a></li>
							</ul>
						</div>
					</div>
					<div className="contact-book-contacts">
						<div className="contact-book-search">
							<form onSubmit={this.handleSearchSubmit}>
								<input className="search-field" type="text"
									value={this.state.searchText}
									placeholder={I('Search')}
									onChange={this.handleSearchInput}
								/>
								<button><i className="icon-search"></i></button>
							</form>
						</div>
						<div className="contact-book-list agent-book-list" data-custom-scrollbar>
							<ul>
								{agentList}
							</ul>
						</div>
					</div>
					<div className="contact-book-content">
						<div className="contact-book-content-header">

						</div>
						<div className="contact-book-content-body">
							{agentInfo}
							<div className="add-information">
								<div className="card-list">
									<table>
										<tbody>
											{/* {contactList} */}
										</tbody>
									</table>
								</div>
								{/*<div className="card-info">
								</div> - todo when upload customer photo*/
							    }
							</div>
						</div>
						<div className="button-row">
							{AgentButton}
						</div>
					</div>
					<div className="popup-close" onClick={this.handleCloseAgentBook}>
						<i className="icon-times"></i>
					</div>
				</div>
			</div>
			);
	}
}