import React from 'react';

export class ChuckNorrisFacts extends React.Component {
	constructor(props) {
		super(props);
		this.facts = [
			"Chuck Norris has counted to infinity. Twice.",
			"If you can see Chuck Norris, he can see you.",
			"There is no chin behind Chuck Norris' beard. There is only another fist.",
			"The Great Wall of China was originally created to keep Chuck Norris out. It failed miserably.",
			"Chuck Norris is the only man to ever defeat a brick wall in a game of tennis.",
			"Chuck Norris doesn't churn butter. He roundhouse kicks the cows and the butter comes straight out.",
			"When Chuck Norris sends in his taxes, he sends blank forms and includes only a picture of himself, crouched and ready to attack. Chuck Norris has not had to pay taxes ever.",
			"The quickest way to a man's heart is with Chuck Norris' fist.",
			"A Handicap parking sign does not signify that this spot is for handicapped people. It is actually in fact a warning, that the spot belongs to Chuck Norris and that you will be handicapped if you park there.",
			'Chuck Norris once shot down a German fighter plane with his finger, by yelling, "Bang!"',
			"Time waits for no man. Unless that man is Chuck Norris.",
			"In an average living room there are 1,242 objects Chuck Norris could use to kill you, including the room itself.",
			"Chuck Norris has already been to Mars; that's why there are no signs of life.",
			"Chuck Norris died 20 years ago, Death just hasn't built up the courage to tell him yet.",
			"Chuck Norris can lift up a chair with one hand... While he's sitting on it...",
			"When Chuck Norris looks in a mirror the mirror shatters, because not even glass is stupid enough to get in between Chuck Norris and Chuck Norris.",
			"A cop once pulled Chuck Norris over...Luckily, the cop left only with a warning.",
			"Chuck Norris has a grizzly bear carpet in his room. The bear isn't dead it is just afraid to move.",
			"Did u know Chuck Norris had a role in star wars. He was the force.",
			"Chuck Norris doesn't dial the wrong number, you pick up the wrong phone.",
			"While learning CPR Chuck Norris actually brought the practice dummy to life.",
			"Chuck norris went skydiving and his parachute failed to open, so he took it back the next day for a refund",
			"The Universe is not expanding.  It's running away from Chuck Norris.",
			"When Chuck Norris does a push up, he isn't lifting himself up, he's pushing the Earth down.",
			"Once a cobra bit Chuck Norris. After 5 days of extreme pain... the snake died.",
			"Chuck Norris broke the law once.  It still isn’t fixed.",
			"Chuck Norris can unscramble eggs.",
			"There used to be a street named after Chuck Norris, but it was changed because nobody crosses Chuck Norris and lives.",
			"Some magicans can walk on water, Chuck Norris can swim through land.",
			"Chuck Norris and Superman once fought each other on a bet. The loser had to start wearing his underwear on the outside of his pants.",
			"When Chuck Norris goes to donate blood, he declines the syringe, and instead requests a hand gun and a bucket.",
			"If you stare at this sentence for longer than 20 seconds Chuck Norris' face will be burned onto your retina.",
			"Chuck Norris can divide by zero.",
			"James Cameron wanted Chuck Norris to play the Terminator. However, upon reflection, he realized that would have turned his movie into a documentary, so he went with Arnold Schwarzenegger.",
			"Chuck Norris can slam a revolving door.",
			"The show Survivor had the original premise of putting people on an island with Chuck Norris. there were no survivors and the pilot episode tape has been burned.",
			"Chuck Norris doesn't bowl strikes, he just knocks down one pin and the other nine faint.",
			"What every sports player should say after winning? \"First of all, I would like to thank Chuck Norris for not competing.\"",
			"Chuck Norris will never have a heart attack. His heart isn't nearly foolish enough to attack him.",
			"Chuck Norris once went to court for a crime, the judge pleaded guilty.",
			"Chuck Norris threw a grenade and killed 50 people, then it exploded.",
			"Chuck Norris can light a fire by rubbing two ice-cubes together.",
			"Chuck Norris can strangle you with a cordless phone.",
			"Chuck Norris doesn't wear a watch. He simply decides what time it is.",
			"Few people can go down Niagra Falls in a barrel. Chuck Norris can go up Niagra Falls in a carboard box.",
			"When Chuck Norris enters into a courtroom, the judge stands up.",
			"Aliens believe in Chuck Norris.",
			"Chuck Norris can make scissors beat rock.",
		];
	}
	render() {
		return <div style={{marginTop:'100px',fontStyle:'italic'}}>
			{this.facts[(new Date()).getMilliseconds()%this.facts.length]}
		</div>
	}
}
