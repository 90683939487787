import React from 'react';

const BeautifySubject = ({text, marker}) => {
	const markerLen = marker.length;
	let pos = [];
	function x(t) {
		const n = t.indexOf(marker);
		if(n < 0) {
			if(t) {
				pos.push({icon: false, text: t});
			}
			return;
		}
		if(n > 0) {
			pos.push({icon: false, text: t.substr(0, n)});
		}
		pos.push({icon: true});
		x(t.substr(n+markerLen));
	}
	x(text);
	if(pos.length === 1) {
		const v = pos[0];
		if(v.icon) {
			return <span className='iconSubject'></span>;
		} else {
			return v.text;
		}
	}
	let spans = [];
	$.each(pos, (i,v) => {
		if(v.icon) {
			spans.push(<span key={'i'+i} className='iconSubject'></span>);
		} else {
			spans.push(<span key={'t'+i}>{v.text}</span>);
		}
	});
	return <span>{spans}</span>;
};

export default BeautifySubject;
