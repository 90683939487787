import React, { PureComponent, useCallback } from 'react';
import { compose } from 'recompose';
import classNames from 'classnames';
import EllipsisText from "react-ellipsis-text";
import { ErrandPreviewItem } from './WorkflowTable';
import SquareCheckbox from '../../reactcomponents/SquareCheckbox';
import Moment, {
	withFormatChange
	, withFullTime
} from '../../reactcomponents/Moment';
import ProfilePhoto from '../../reactcomponents/ProfilePhoto';
import { getErrandIcons, truncateHtml, cleanChannelIdentifier } from '../../common/v5/helpers.js';
import { I } from '../../common/v5/config.js';
import { FILE_EXCEED_MESSAGE, PREF_CONVERSATION_VIEW } from '../../common/v5/constants';
import { sanitizeText, parseDate, stripHTML } from '../../common/v5/helpers';
import moment from 'moment';
import styled from 'styled-components';
import { defaultFlagColor } from '../../styles/_variables';
import Measure from 'react-measure';
import { getMinutesBetweenDates } from '../../common/v5/utils';

class BriefErrandNotices extends PureComponent {
	constructor(props) {
		super(props);
		this.handleColClick = this.handleColClick.bind(this);
		this.handleGetCollabInfo = this.handleGetCollabInfo.bind(this);
		this.handleGetSlaTime = this.handleGetSlaTime.bind(this);
		this.state = {
			dimensions: {
				width: -1,
				height: -1,
			},
		}
	}
	handleColClick(e) {
		e.preventDefault();
		e.stopPropagation();
		if (this.props.canOffCollaborationLight) {
			if (this.props.onToggleLight) {
				this.props.onToggleLight(this.props.errand.id);
			}
		}
	}
	handleGetCollabInfo() {
		if (this.props.onGetCollabInfo) {
			this.props.onGetCollabInfo(this.props.errand.id);
		}
	}
	handleGetSlaTime() {
		if (this.props.onGetSlaTime && this.props.showSlaTimeFeature) {
			this.props.onGetSlaTime(this.props.errand.id);
		}
	}
	render() {
		const p = this.props, errand = p.errand, theme = p.theme.style.themeWarning;
		let warnIcon, alertIcon, collaborationMsg, collaborationUsers,
			hideDueDate = true, hideAlert = true, hideUsers = true,
			hidePinToTop = true, collaborationClass, collabTextClass, hideNewChatMessage = true,
			hideExpireChat = true, hideEndedChat = true, doneDate = "", hideLocked = true,
			hideInternalComment = true
		let postponeIcon, postponeDate = "", hidePostponeDate = true,
			slaTimeClass, slaTimeTextClass, slaTimeMsg, slaTimeDetail, hideSlaTime = true;

		let postponeWarningClass = "warning";

		if (errand.collaboration) {
			if (p.collaborationInfo) {
				collaborationUsers = p.collaborationInfo;
			}
			collaborationUsers = p.collaborationInfo;
			collaborationMsg = errand.collaboration.status;
			hideUsers = false;
			const c = errand.collaboration;
			if (c.light) {
				collaborationClass = "icon-user-fill light-bulb-on";
				collabTextClass = "block-with-text two-lines";
			} else {
				if (c.status) {
					collaborationClass = "icon-member";
					collabTextClass = "block-with-text";
				} else {
					hideUsers = true;
				}
			}
		}

		if (errand && errand.highpriority) {
			alertIcon = 'icon-caution';
			hideAlert = false;
		}
		if (errand && errand.pinToTop) {
			hidePinToTop = false;
		}
		if (errand && errand.locked) {
			hideLocked = false;
		}
		if (errand && errand.errandNotes) {
			hideInternalComment = false;
		}
		if (p.chat) {
			if (p.chat.expired) {
				hideExpireChat = false;
				warnIcon = 'icon-clock';
			}
			if (p.chat.dead) {
				hideEndedChat = false;
				alertIcon = 'icon-caution';
			}
			if (typeof p.errandNotes !== 'undefined' && p.errandNotes.length > 0) {
				hideInternalComment = false
			}
		}
		if (this.props.dueDateFeature && !p.chat
			&& errand.donedate.match(parseDate)) {
			doneDate = errand.donedate.replace(" 00:00", "");
			warnIcon = 'icon-clock';
			hideDueDate = false;
		}

		if (typeof errand.wasPostponed !== 'undefined' && errand.wasPostponed) {
			postponeWarningClass = "hadPostponedWarning"
			postponeIcon = 'icon-calendar-plus postpone-calendar-icon-warning';
			hidePostponeDate = false;
			postponeDate = I("Postponed time is up for the errand")
		}

		if (this.props.postponeDateFeature && !p.chat &&
			errand.postponedate > 0) {
			postponeWarningClass = "hasPostponedWarning"
			postponeDate = moment.unix(errand.postponedate).format('YYYY/MM/DD h:mm a')
			postponeIcon = 'icon-calendar-plus postpone-calendar-icon-warning';
			hidePostponeDate = false;

		}

		if (!errand.answered && theme != "System Warning Theme" && !p.chat && errand.generalAlertTime > 0) {
			slaTimeClass = "icon-bell";
			slaTimeTextClass = "block-with-text two-lines";
			slaTimeMsg = "SLA time remaining";
			if (p.slaTime) {
				slaTimeDetail = p.slaTime.days + " days " + p.slaTime.hours + " hours " + p.slaTime.mins + " minutes";
			}
			hideSlaTime = false;
		}

		const { width, height } = this.state.dimensions
		return (
			<Measure
				bounds
				onResize={contentRect => {
					this.setState({ dimensions: contentRect.bounds })
				}}
			>
				{({ measureRef }) => (
					<ul ref={measureRef} className={classNames("conversation-notices", { expanded: height > 72 })}>
						<li className="pinned-top" hidden={hidePinToTop} title={I("Pinned to top")} data-qa-id={"QA_errandFlag_pinned"}><i className='icon-pin'></i></li>
						<li className="locked-to-me" hidden={hideLocked} title={I("Locked to me")} data-qa-id={"QA_errandFlag_locked"}><i className='icon-lock'></i></li>
						<li className="warning" title={I("Chat expired")} hidden={hideExpireChat} data-qa-id={"QA_errandFlag_expired"}><i className={warnIcon}></i></li>
						<li className="high-priority" hidden={hideAlert} data-qa-id={"QA_errandFlag_highPriority"}><i className={alertIcon}></i></li>
						<li className="high-priority" title={I("Chat ended")} hidden={hideEndedChat} data-qa-id={"QA_errandFlag_chatEnded"}><i className={alertIcon}></i></li>
						<li className="internal-comment" title={I("Internal Comment")} hidden={hideInternalComment} data-qa-id={"QA_errandFlag_internalComment"}><i className="icon-internal-comment"></i></li>
						<li className="warning" title={doneDate} hidden={hideDueDate} data-qa-id={"QA_errandFlag_dueDate"}><i className={warnIcon}></i></li>
						<li className={postponeWarningClass} title={postponeDate} hidden={hidePostponeDate} data-qa-id={"QA_errandFlag_postponeDate"}><i className={postponeIcon}></i></li>
						<li className="waiting-users" hidden={hideUsers}
							onClick={this.handleColClick} data-qa-id={"QA_errandFlag_collab"}>
							<i className={collaborationClass} onMouseOver={this.handleGetCollabInfo}></i>
							<div className="notice-message">
								<div className="notice-message-title">{collaborationMsg}</div>
								<div className={"notice-message-text " + collabTextClass}>{collaborationUsers}</div>
							</div>
						</li>
						{this.props.showSlaTimeFeature &&
							<li className="warning" data-qa-id={"QA_errandFlag_slaTime"} hidden={hideSlaTime}>
								<i className={slaTimeClass} onMouseOver={this.handleGetSlaTime}></i>
								<div className="notice-message">
									<div className="notice-message-title">{slaTimeMsg}</div>
									<div className={"notice-message-text " + slaTimeTextClass}>{slaTimeDetail}</div>
								</div>
							</li>
						}
					</ul>
				)}
			</Measure>
		);
	}
}

export class NoDataBriefErrand extends PureComponent {
	constructor(props) {
		super(props);
		this.handleReload = this.handleReload.bind(this);
	}
	handleReload(e) {
		e.preventDefault();
		this.props.onReload(this.props.me);
	}
	render() {
		const p = this.props, id = "#" + p.me;
		return (
			<div className="conversation" ref={p.refCallback}>
				<div className="conversation-left-side" />
				<div className="conversation-center">
					<div className="conversation-name">
						<span className="conversation-name-id">{id}</span>
					</div>
					<div className="conversation-message" title={id}>
						<a href="#" onClick={this.handleReload}>
							<i className="fa fa-refresh" aria-hidden="true" />
							&nbsp;
							{I('Reload')}
						</a>
					</div>
				</div>
				<div className="conversation-right-side" />
			</div>
		);
	}
}

export class BriefSearchErrand extends PureComponent {
	render() {
		const p = this.props, erd = p.errand;
		let convClass = 'conversation read';
		if (this.props.currentOpened === erd.id) {
			convClass += ' open';
		}
		let photo = "", bodyMsg = erd.body;
		let errorIcon = '', nameOnContactCard = "";
		if (p.clientAvatar && p.clientAvatar.byId) {
			let ob = p.clientAvatar.byId[erd.fromId];
			if (ob) {
				nameOnContactCard = ob.nameOnContactCard;
				if (ob.avatar) {
					photo = ob.avatar.url;
				}
			}
		}
		if ((p.errand.channel == "Chat") || (p.errand.channel == "Twitter")
			|| (p.errand.channel == "Facebook") || (p.errand.channel == "WhatsApp")) {
			bodyMsg = stripHTML(erd.body);
		}
		return <ViewBriefErrand
			convClass={convClass}
			displayId={erd.displayId}
			fromName={erd.from}
			fromAddress={erd.fromAddress}
			body={bodyMsg}
			photo={photo}
			erd={erd}
			isSearch={true}
			me={p.me}
			errorIcon={errorIcon}
			createdTime={erd.createdTime}
			channel={erd.channel}
			showExactDayAndTime={p.showExactDayAndTime}
			nameOnContactCard={nameOnContactCard}
			{...p}
		>
		</ViewBriefErrand>;
	}
}

const NoAgoMoment = props => <Moment {...props} noAgo={true} />;

export const FullTimeMoment = compose(
	withFormatChange
	, withFullTime
)(NoAgoMoment);

export const ColoredFlag = styled.i`
	font-size: 16px;
	&:before {
		color: ${props => props.color ? props.color : defaultFlagColor};
	}
`;

const MinutesCountDownTimer = ({ minutes, show }) => {
	if (show) {
		return (
			<div className='conversation-name-info' style={{ background: '#FFB406', marginLeft: '5px' }}>
				<i className='icon-clock' style={{ marginLeft: 'auto' }}></i>
				<span className='conversation-name-id' style={{ paddingLeft: '5px' }}> {minutes} min </span>
			</div>
		);
	}
	return null;
};

class ViewBriefErrandBase extends PureComponent {
	constructor(props) {
		super(props);
		this.handleSelect = this.handleSelect.bind(this);
		this.handleClick = this.handleClick.bind(this);
	}
	handleSelect(v) {
		if (this.props.displayId !== this.props.activeCreatedErrand) {
			this.props.onSelect(this.props.me, v);
		}
	}
	handleClick() {
		const { cipherKey, me, displayId, activeCreatedErrand, sipMakeCallCurrentErrand, onClick } = this.props;
		if (displayId !== activeCreatedErrand || sipMakeCallCurrentErrand) {
			onClick(me, cipherKey);
		}
	}
	onMouseEnter() {
		const { me, onLoadPreview, previewActivated } = this.props;
		if (typeof onLoadPreview === 'function' && previewActivated.activate) {
			onLoadPreview(me);
		} else {
			return;
		}
	}
	onMouseLeave() {
	}
	wrap(match) {
		return '<span class="search-line">' + match + '</span>';
	}
	highlightSearchTerm(term, searchResult) {
		term = term.replace(/[+*?()[\]\\/]/gi, ''); //remove any accidental backslash
		let regex = new RegExp("" + term + "", 'gi');
		return searchResult.replace(regex, match => this.wrap(match));
	}
	handleMarkUpText(textArray, identifier, htmltag) {
		var array = textArray.split(identifier);
		var previous = "";
		var previous_i;
		for (let i = 0; i < array.length; i++) {
			if (i % 2) {
				//odd number
			}
			else if (i != 0) {
				previous_i = eval(i - 1);
				array[previous_i] = "<" + htmltag + ">" + previous + "</" + htmltag + ">";
			}
			previous = array[i];
		}
		var newtext = "";
		for (let i = 0; i < array.length; i++) {
			newtext += array[i];
		}
		return newtext;
	}
	render() {
		let p = this.props, msg = p.body;
		let msgSummary = msg, serviceName = "", isPm = false, activate = false, delayTime = 0;
		let fromName = "", nameOnContactCard = "", fromAddress = "";
		let text = p.previewData;
		if (p.erd.fbPms || p.erd.twitterPm) {
			isPm = true;
		}
		if (p.erd.serviceName) {
			serviceName = p.erd.serviceName;
		}
		if (p.previewActivated) {
			activate = p.previewActivated.activate;
			delayTime = p.previewActivated.delaytime;
		}

		let truncatedSummary = sanitizeText(msgSummary), chatClassName = "", hideChatTime = false;

		if (typeof p.term !== 'undefined') {
			if (p.term !== "") {
				truncatedSummary = this.highlightSearchTerm(p.term, truncatedSummary);
			}
		}

		if (p.chat) {
			if (p.chatInvitationMode) {
				hideChatTime = true;
			}
			chatClassName = "for-chat";
		}
		fromAddress = (p.fromAddress ? p.fromAddress : p.fromName);
		fromName = (p.fromName ? p.fromName : p.fromAddress);
		let serviceSource = p.channel;
		if (p.channel == "Chat" && p.erd.sourceName) {
			serviceSource = p.erd.sourceName;
		}
		let cleanedName = cleanChannelIdentifier(serviceSource, fromName);
		nameOnContactCard = (p.nameOnContactCard ? p.nameOnContactCard : cleanedName);
		let flags = [];
		if (p.erd && p.erd.isVIPTagged) {
			if (p.erd.flagColors && p.erd.flagColors.length > 0) {
				p.erd.flagColors.map(function (v) {
					flags.push(<ColoredFlag key={p.erd.id + "-" + v} className="icon-flag" color={v}></ColoredFlag>);
				});
			}
		}
		if (text) {
			text = this.handleMarkUpText(text, "**", "strong");
		}
		let hasActiveCall = false, outgoing = false;

		let minDiff = 0, showMinCountDown = false, activeIdBgColor = '';
		if (p.erd.scheduleCallback != "") {
			let timeNow = moment().toDate();
			let scheduleTs = moment(p.erd.scheduleCallback, "YYYY/MM/DD HH:mm").toDate();
			//check if schedule time and current time is within 30 min.
			minDiff = Math.round(getMinutesBetweenDates(timeNow, scheduleTs));
			if (minDiff > 0 && minDiff < 30) {
				hasActiveCall = true;
				showMinCountDown = true;
				activeIdBgColor = '#0c87f7';
			}
		}
		if (p.displayId === p.activeCreatedErrand || p.me === p.sipGetCurrentEid) {
			hasActiveCall = true;
			if (p.displayId === p.activeCreatedErrand) {
				outgoing = true;
			}
			activeIdBgColor = '#f4213f';
		}

		let showSentimentColumnFeature = false;
		if(features["machine-learning.text-classification"] && !features["machine-learning-hide-auto-classification-list"]) {
			showSentimentColumnFeature = true;
		}
		
		const MoodIndicator = ({}) => {
			let autoClassification = p.erd.autoClassification;
			if(p.erd && autoClassification){
			const determineMood = () => {
				let errandMood = "", errandEmoji = "";

				if (autoClassification) {
					const moodClassify = {};
					const validJSONClassify = JSON.parse(autoClassification);
					for (var prop in validJSONClassify) {
						if (Object.prototype.hasOwnProperty.call(validJSONClassify, prop)) {
							if (prop === "sentiment") {
								moodClassify[prop] = validJSONClassify[prop];
							}
						}
					}
					if (moodClassify["sentiment"] === "positive") {
						errandMood = "Positive";
						errandEmoji = "happy";
					} else if (moodClassify["sentiment"] === "negative") {
						errandMood = "Negative";
						errandEmoji = "sad";
					} else {
						errandMood = "Neutral";
						errandEmoji = "neutral";
					}
				}

				return { errandMood, errandEmoji };
			};

			const renderEmoji = (emotion) => {
				switch (emotion) {
					case 'happy':
						return '😃';
					case 'sad':
						return '😞';
					case 'neutral':
						return '😐';
					default:
						return '😐';
				}
			};

			const { errandMood, errandEmoji } = determineMood();

			return (
				<div>
					<span title={errandMood}>{renderEmoji(errandEmoji)}</span>
				</div>
			);
			}
		};
		return (<div id={'tooltip-' + p.me} className={classNames(p.convClass, { "has-active-call": hasActiveCall }, { "outgoing": outgoing }, { "opened": p.sipMakeCallCurrentErrand })} onClick={this.handleClick} data-high-priority={p.erd.highpriority ? 1 : 0} data-pinned={p.erd.pinToTop ? 1 : 0} data-qa-id={"QA_Errand" + p.index} ref={p.refCallback}>
			<div className="conversation-left-side">
				<div className="conversation-checkbox">
					<SquareCheckbox onClick={this.handleSelect} id={"QA_ErrandCb_" + serviceName}
						checked={p.select} hidden={p.noSelect} />
				</div>
				<div className="wrapper" >
					{
						showSentimentColumnFeature &&
						<div className="mood-indicator-wrapper">
							<MoodIndicator />
						</div>
					}
					<ProfilePhoto photo={p.photo}
						width={"35"} isAgent={false}
						className={"conversation-photo"} />
				</div>
				<div className="conversation-type" data-qa-id={"QA_errandType_" + p.channel}>
					{
						getErrandIcons((p.erd.service ? p.erd.service : p.erd.sourceId), p.chat, isPm, p.erd.sourceId)
					}
				</div>
			</div>
			<div className="conversation-center"
				onMouseEnter={() => this.onMouseEnter()}
				onMouseLeave={() => this.onMouseLeave()}>
				<div className="conversation-name">
					<div className="conversation-name-info" style={{ background: activeIdBgColor }}>
						<span className="conversation-name-id">
							{"#" + p.displayId}
						</span>
						{flags}
						<i className={p.errorIcon + " error-icon"} hidden={p.errorIcon ? false : true}> </i>
					</div>
					<MinutesCountDownTimer show={showMinCountDown} minutes={minDiff} />
					<div title={fromAddress}>
						<EllipsisText
							text={nameOnContactCard}
							length={25}
							data-qa-id="QA_errandList_contact_name"
						/>
					</div>
				</div>
				<div className={classNames("conversation-message ellipsis", chatClassName)} dangerouslySetInnerHTML={{ __html: truncatedSummary }}>
					{/* {truncatedSummary} */}
				</div>
				<span hidden={(p.lastChatClientMsg && !hideChatTime) ? false : true} title={I("Last seen")} className="moment-datetime last-seen">{p.lastChatClientMsg}</span>
				<FullTimeMoment
					date={p.createdTime}
					timezoneOffset={p.tzOffset}
					showExactDayAndTime={p.showExactDayAndTime}
				/>
				{(!p.mobile && p.canShowPreview && activate) && <ErrandPreviewItem
					body={text}
					activate={activate}
					delayTime={delayTime}
					hideMeta={true}
					onActiveSubject={() => void (0)}
					onLoad={() => void (0)}
					placement={"right"}
					subject={p.isSearch ? p.erd.subject : msg}
					initiator={PREF_CONVERSATION_VIEW}
					targetElement={'tooltip-' + p.me}
				/>
				}
			</div>
			<div className="conversation-right-side" hidden={p.chat ? false : true}>
				{p.chat && p.chat.unReadMsgs !== 0 && <div className="conversation-messages-count">{p.chat.unReadMsgs}</div>}
			</div>
			{p.children}
		</div>);
	}
}

const ViewBriefErrand = ({ onClick, ...props }) => (
	<ViewBriefErrandBase
		{...props}
		onClick={useCallback(id => onClick(id), [onClick])}
	/>
);

class BriefErrandBase extends PureComponent {
	readOrAnswered(e, d) {
		let res = '', isOpening;
		const theme = e.style.themeWarning;
		if (theme == "System Warning Theme") {
			res = ' errand-expired';
		} else if (theme == "System Warning Alert Theme") {
			res = ' errand-warning';
		}
		if (this.props.currentOpened === e.id) {
			res += ' open';
			isOpening = true;
		}
		if (e.acquired || d.statusMessage === I("Errand has been opened")) {
			res += ' read';
			if (!isOpening && d.collaboration && d.collaboration.light) {
				res += ' col-light-bulb';
			}
		} else {
			if (d.statusMessage === I("New") &&
				theme != "System Warning Theme" &&
				theme != "System Warning Alert Theme") {
				res += ' new';
			}
		}
		return res;
	}
	checkGrouped(e) {
		if (!this.props.noGroup && e.groupWith != 0) {
			return " grouped";
		} else {
			return "";
		}
	}
	chatStatus(chat) {
		if (chat) {
			if (chat.Role == 3) { // join a chat as guest
				return " chat-guest";
			} else if (chat.Role == 2) { // invited to join a chat
				return " chat-invite";
			} else if (chat.AgentIds.length > 1) { // more than 1 agent in a chat (group chat)
				return " group-chat";
			}
		}
		return "";
	}
	render() {
		const p = this.props, erd = p.errand, e = erd.data,
			convClass = 'conversation' + this.checkGrouped(erd) + this.readOrAnswered(erd, e) + this.chatStatus(p.chat);
		let msg, photo = "", nameOnContactCard = "";
		if (p.bodyAsMessage) {
			msg = e.body;
		} else {
			msg = e.subject;
		}
		let errorIcon = '';
		if (e.statusMessage === FILE_EXCEED_MESSAGE) {
			errorIcon = "error fa fa-exclamation";
		}
		let chatInfo = "", chatInvitationMode = false, lastChatClientMsg = "";
		if (p.chat) {
			if (p.chat.Role == 2) {
				chatInfo = I('You are invited to chat');
				chatInvitationMode = true;
			} else if (p.chat.InvitedAgentIds.length > 0) {
				let guess = p.chat.AgentIds.length - 1
					, invitedAgents = p.chat.InvitedAgentIds.length;
				chatInfo = I('Invited agent ({JOINT}/{INVITED})')
					.replace('{JOINT}', guess)
					.replace('{INVITED}', invitedAgents + guess);
				chatInvitationMode = true;
			}
			let message = p.chat.messages, fromClient = [], clientLastMsgTime = "";
			if (message && message.length > 0) {
				if (message[0].fromClient) {
					msg = stripHTML(message[0].text);
				} else {
					if (!p.chat.HasClientMessage) {
						msg = "";
					}
				}
				for (let i = 0; i < message.length; i++) {
					let msgInfo = message[i];
					if (msgInfo.fromClient) {
						fromClient.push(msgInfo);
					}
				}
			}
			if (fromClient && fromClient.length > 0) {
				clientLastMsgTime = fromClient[fromClient.length - 1].sent;
				lastChatClientMsg = moment.unix(clientLastMsgTime).fromNow();
			}
		}
		if (p.clientAvatar && p.clientAvatar.byId) {
			let ob = p.clientAvatar.byId[e.fromId];
			if (ob) {
				nameOnContactCard = ob.nameOnContactCard;
				if (ob.avatar) {
					photo = ob.avatar.url;
				}
			}
		}
		return <ViewBriefErrandBase
			cipherKey={e.cipherKey}
			convClass={convClass}
			displayId={e.displayId}
			fromAddress={e.fromAddress}
			fromName={e.fromName}
			body={msg}
			photo={photo}
			erd={e}
			me={p.me}
			errorIcon={errorIcon}
			createdTime={e.date}
			channel={e.serviceName}
			chatInvitationMode={chatInvitationMode}
			lastChatClientMsg={lastChatClientMsg}
			nameOnContactCard={nameOnContactCard}
			canShowPreview={this.props.canShowPreview}
			{...p}
		>
			<div hidden={chatInfo ? false : true} className="conversation-chat-info">
				{chatInfo}
			</div>
			{!p.noNotice ? <BriefErrandNotices errand={erd.data}
				errandNotes={erd.notes}
				chat={p.chat} dueDateFeature={p.dueDateFeature}
				postponeDateFeature={p.postponeDateFeature}
				canOffCollaborationLight={p.canOffCollaborationLight}
				onToggleLight={p.onToggleLight}
				collaborationInfo={p.collaborationInfo}
				onGetCollabInfo={this.props.onGetCollaborationInfo}
				showExactDayAndTime={p.showExactDayAndTime}
				onGetSlaTime={p.onGetSlaTime}
				slaTime={p.slaTime}
				theme={erd}
				showSlaTimeFeature={p.showSlaTimeFeature} />
				: null}
		</ViewBriefErrandBase>;
	}
}

const BriefErrand = ({ onClick, ...props }) => (
	<BriefErrandBase
		{...props}
		onClick={useCallback(id => onClick(id), [onClick])}
	/>
);

export class GroupableBriefErrand extends PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(id, cipherKey) {
		const { me, handleId, errand, onClick } = this.props;
		onClick(me, cipherKey);
	}
	render() {
		const { onClick, handleId, ...props } = this.props;
		return <BriefErrandBase {...props} onClick={this.handleClick} />;
	}
}

export const UngroupableErrand = props =>
	<BriefErrand {...props} noGroup={true} />;

export default BriefErrand;
