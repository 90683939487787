import React, { memo, useState } from 'react';
import { withHandlers, withProps } from 'recompose';
import AgentSocket from '../../common/v5/agentsocket';
import { doNothing, emptyObject } from '../../common/constants'
import { useCallbackWithValue } from '../../hooks/callback';
import { centionBlue, centionGreen } from '../../styles/_variables'
import {
	composeWithDisplayName,
	withUnmountWhenHidden
} from '../../reactcomponents/hocs';
import { HistoriesAndOthers } from '../../reactcomponents/HistoriesAndOthers';
import { Message, RightBarMsgPanel } from './Message';
import Question, { Answer } from './QuestionAnswer';
import SystemMessage from './SystemMessage';
import withSystemMessage from './SystemMessageWrapper';
import ClickableTitle from '../../reactcomponents/ClickableTitle';
import CallRinger from '../../reactcomponents/CallRinger';
import MessageGroup from '../../reactcomponents/MessageGroup';
import {
	ChatPanelWrapper,
	CollaborationRightSidepanel,
	CollaborationTriggerButton,
	InternalCommentTriggerButton,
	SidepanelTriggerBtn,
	AIAnswerRightSidepanel,
	KBLibraryRightSidepanel
} from '../../reactcomponents/Sidepanel';
import {
	renderFileHtml,
	getAppendAnswer,
	isJSONObject,
	sanitizeHtml,
	isDesktopMode,
	msToHMS
} from '../../common/v5/helpers';
import {
	call,
	startWebCamAction,
	getLocalStream,
	getRemoteStream,
	muteVideo,
	disableVideo,
	stopVideoCamera,
	startRecording,
	stopRecording,
	handleStopClientScreenShare,
	handleBlurBg,
	stopBlurBg,
	changeBg,
	removeBg,
	sendChangeBgOnlySignal,
	sendMouseMove,
	sendScrollDirection
} from '../../common/v5/webRtcCall';
import {
	AT_SAVED,
	MT_NOTE,
	PF_TIMESTAMP,
	ACT_MOUSE_CURSOR,
	ACT_KEYBOARD_INPUT,
	ACT_SCROLL_PAGE
} from '../../common/v5/constants';
import { ErrandBoxReply } from '../../views/v5/errand';
import ImageSelector, { backgroundData } from '../../reactcomponents/ImageSelector';
import VisitorPathWindow from '../../components/v5/VisitorPath';
import { AgentVideoFrameCtnr } from '../../containers/chat';
import { CollabContentsCtnr } from '../../containers/collaborate';
import { withOtherContactErrandHandlers } from '../../containers/oce';
import classNames from 'classnames';
import { I } from '../../common/globals';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import { getChevronIcon } from '../../common/v5/helpers';
import Dropdown from '../../reactcomponents/Dropdown';
import ColorPicker from '../../reactcomponents/ColorPicker';
import { RewriteAnswerBoxContentCtnr } from '../../views/v5/errandCtnrs';
import KnowledgeBaseCtnr from '../../views/v5/knowledgebase/knowledgeBaseCtnr';

const JSON_ENCODED = "\x01JSON";

export const ChatICWrapper = props => <MessageGroup title={I('INTERNAL COMMENT')} {...props} />;

const ChatSystemMessage = composeWithDisplayName(
	'ChatSystemMessage',
	memo,
	withProps({ parseFormat: PF_TIMESTAMP }),
	withSystemMessage
)(SystemMessage)

const noFilterMessage = { allMessages: true }

const OtherContactErrands = composeWithDisplayName(
	'OtherContactErrands',
	withUnmountWhenHidden,
	memo,
	withOtherContactErrandHandlers,
	withHandlers({
		onActionClick: ({ onActionClick, onPrint }) => (action, id, ...args) => {
			if (action === 'print') {
				onPrint(true, id)
				return
			}
			onActionClick(action, id, ...args)
		}
	}),
	withProps({
		filterPopup: noFilterMessage,
		forward: false,
		forwardSelection: emptyObject,
		hideSatisfaction: true,
		onSelectForward: doNothing,
		ready: true
	})
)(HistoriesAndOthers)

class ChatMessages extends React.Component {
	constructor(props) {
		super(props);
		this.handleActionClick = this.handleActionClick.bind(this);
		this.handleToggleExternalData = this.handleToggleExternalData.bind(this);
		this.scrollToBottom = this.scrollToBottom.bind(this);
		this.onHandleEmoticon = this.onHandleEmoticon.bind(this);
	}
	scrollToBottom() {
		if (!this.props.doScrollToBottom) {
			return;
		}
		if(this.el){
			let elem = this.el;
			elem.scrollTop = elem.scrollHeight;
		}
	}

	componentDidMount() {
		this.scrollToBottom();
	}

	componentDidUpdate(prevProps, prevState) {
		//Only scroll when new chat messages comes in
		//or when switching chats errand (using innerRef)
		let updatedInnerRef = null;
		if(prevProps.innerRef !== this.props.innerRef){
			updatedInnerRef = this.props.innerRef
		}
		if(this.props.chat.messages.length > prevProps.chat.messages.length || updatedInnerRef) {
			this.scrollToBottom();
		}
	}

	isJSONPayload(text) {
		return text.lastIndexOf(JSON_ENCODED, 0) === 0;
	}
	renderFileUploadMessage(j) {
		let url = j.fileDownload;
		let html = renderFileHtml(url, j.fileName);
		if (j.error) {
			html += '<span class="error">Error: ' + j.error + '</span>';
		}
		return html;
	}
	renderTemplateButtonMessage(j) {
		return j.text;
	}
	renderButtonPostbackMessage(j) {
		return j.buttonTitle;
	}
	maybeFormat(text) {
		try {
			var j = JSON.parse(text.replace(JSON_ENCODED, ''));
			switch(j.event) {
				case "FILE_UPLOAD":
					return this.renderFileUploadMessage(j);
				case "TEMPLATE_BUTTON":
					return this.renderTemplateButtonMessage(j);
				case "BUTTON_POSTBACK":
					return this.renderButtonPostbackMessage(j);
			}
		} catch (e) {
			return text;
		}
	}
	handleActionClick(action, note, errand){
		const p = this.props;
		switch(action) {
			case 'edit':
				p.onEditNoteClick(note, errand);
				break;
			case 'delete':
				p.onDeleteNoteClick(note, errand);
				break;
		}
	}
	handleChatActClick(action, errandId, msg){
		let editor = CKEDITOR.instances["chatEditor"];
		if(editor){
			switch(action) {
				case 'clone':
					editor.setData(getAppendAnswer(editor.getData(), msg));
					break;
			}
		}
	}
	handleToggleExternalData(){
		if(this.props.onToggleExternalData){
			this.props.onToggleExternalData();
		}
	}
	onSocialMediaUIAction =()=>{
		console.log("<Unhandled>");
	}
	onHandleEmoticon(action, mId, channel){
		let { chat } = this.props;
		this.props.onHandleEmoticon(action, mId, chat.sessionId, channel);
	}
	render() {
		const messages = [];
		const {
			chat,
			canTranslate,
			onTranslation,
			interfaceLang,
			toLang,
			fromCust,
			fromLang,
			showAgentRating,
			showChatSidePanel,
			showAIAnswerPanel,
			showKBLibraryPanel,
		} = this.props;
		let i = -1;
		let eid = chat.errand.id;
		let externalData = [];
		let externalDataClass = 'msg errand-message-group external-data';
		if(this.props.showExternalData) {
			externalDataClass += ' collapsed';
		}
		let channelId = chat?.errand?.data?.sourceId || 0; 	
		let areaId =chat?.errand?.data?.area || 0; 	
		if(chat.ExternalData && isJSONObject(chat.ExternalData) == false) {
			externalData.push(<div key={"chat-external-data"} data-qa-id="chat-external-data" className={externalDataClass}>
								<ClickableTitle title={I("External Data")} left={true} ready={true} onToggleShow={this.handleToggleExternalData} />
									<div className="message-group">
										<div className="message-data" dangerouslySetInnerHTML={{__html: sanitizeHtml(chat.ExternalData)}} />
									</div>
							</div>);
		}

		messages.push(
			<OtherContactErrands
				key='oc-errands'
				answerComponent={Answer}
				canTranslate={canTranslate}
				eid={eid}
				interfaceLang={interfaceLang}
				onActionClick={this.handleChatActClick}
				onTranslation={onTranslation}
				questionComponent={Question}
				channelId={channelId}
				areaId={areaId}
			/>
		)
		for (var m of chat.messages) {
			let msg;
			let props = {
				type: "CHAT",
				status: I("Sent"),
				parseFormat: PF_TIMESTAMP,
				time: m.sent,
				html: m.text,
			}
			, tickTitle
			, tickColor
			, tick
			;
			if (m.fromClient) {
				props.sender = "CLIENT";
				props.name = chat.errand.data.fromName;
				props.photo = this.props.clientAvatar;
				if(typeof chat.ClientAvatar !== 'undefined' && chat.ClientAvatar !== ""){
					props.photo = chat.ClientAvatar;
				}
				props.isAgent = false;
			} else if (m.aid > 0) {
				props.sender = "AGENT";
				props.fromOtherAgent = m.aid != initialChatData.agentID;
				props.name = m.agent;
				if (this.props.agentList[m.aid]) {
					props.photo = this.props.agentList[m.aid].avatar;
				}
				props.isAgent = true;
				let tickColor = 'lightgray', tickTitle = I('Message sent.');
				if (!props.fromOtherAgent) {
					if (chat.errand) {
						switch(chat.errand.data.sourceId) {
							case Workflow.Errand.SERVICE_FACEBOOK:
							case Workflow.Errand.SERVICE_WHATSAPP:
								let receiptClass = "icon-new-tick-double";
								if(m.receipt === "delivered" || m.watermark === "delivered"){
									tickTitle = I('Message delivered to client.');
									receiptClass = "icon-new-tick-double";
									tickColor = 'grey';
								}else if (m.receipt === "read" || m.watermark === "read"){
									tickTitle = I('Message read by client.');
									receiptClass = "icon-new-read-tick-double";
									tickColor = centionGreen;
								}else if (m.receipt === "failed" || m.watermark === "failed"){
									receiptClass = "fa fa-exclamation";
									tickColor = 'red';
									tickTitle = I('Message failed to send.');
								}
								tick = <i title={tickTitle} className={receiptClass} style={{color: tickColor, marginLeft:"5px"}}/>;
								break;
							default:
								if (m.read) {
									tickTitle = I('Client has received this message.');
									tickColor = centionBlue;
								} else {
									tickTitle = I('Client has not received this message.');
									tickColor = 'grey';
								}
								tick = <i title={tickTitle} className="icon-new-tick-single" style={{color: tickColor, marginLeft:"5px"}}/>;
								break;
						}
					}
				}
			} else {
				props.sender = "SYSTEM";
			}

			i++;
			if (props.sender != "SYSTEM") {
				if (this.isJSONPayload(props.html)) {
					props.html = this.maybeFormat(props.html);
				}
				msg = <Message notErrand={true} key={"msg-"+eid+"-"+i} me={m.text} tick={tick}
					showSize={0} clone={true}
					canTranslate={canTranslate} interfaceLang={interfaceLang} toLang={toLang} fromCust={fromCust} fromLang={fromLang}
					channelId={chat.errand.data.sourceId}
					socialEmoticon={m.emoticon}
					messageId={m.id}
					agentName={chat.errand.data.agent}
					eid={eid}
					statusId={0}
					myErrandId={eid}
					onTranslation={onTranslation}
					onActionClick={this.handleChatActClick}
					onClickEmoticon={this.onHandleEmoticon}
					onSocialMediaUIAction={this.onSocialMediaUIAction}
					{...props}/>;
			} else {
				if (isJSONObject(m.text)) {
					let sm = JSON.parse(m.text);
					if(sm.video) {
						msg = <AgentRecordedVids key={"vidMsg-"+eid+"-"+i} src={sm.video} size={sm.size} sid={sm.sessionId} isAgent={sm.isAgent} duration={sm.length} />
					} else {
						msg = (
							<ChatSystemMessage
								key={i}
								getAgent={this.props.getAgent}
								json={m.text}
								timestamp={m.sent}
							/>
						)
					}
				} else {
					msg = (
						<ChatSystemMessage
							key={i}
							getAgent={this.props.getAgent}
							json={m.text}
							timestamp={m.sent}
						/>
					)
				}
			}
			messages.push(msg);
		}
		if(chat.unsent) {
			for (var m of chat.unsent) {
				i++;
				let props = {
					type: "CHAT",
					sender: "AGENT",
					name: chat.errand.data.agent,
					photo: chat.errand.data.agentAvatar,
					html: m.text,
					showSize: 0,
					isAgent: true,
					error: m.error,
				};
				if (m.error) {
					props.status = I("Send failed: {ERROR}").replace("{ERROR}", m.error)
				} else {
					props.status = I("Sending...");
				}
				messages.push(<Message notErrand={true} key={"msg-"+eid+"-"+i} {...props}/>);
			}
		}

		let previewDOM, preview;
		if (chat.preview) {
			if (chat.previewType == 0) {
				let html = I("Client is typing: {MESSAGE}")
					.replace('{MESSAGE}', chat.preview)
					;
				preview = <i className="typing" dangerouslySetInnerHTML={{__html:html}}></i>;
			} else {
				preview = <i className="typing">{I("Client is typing...")}</i>;
			}
			previewDOM = <div className='errand-message' key={'chat-preview'}>
					<div className='errand-message-content'>
						{preview}
					</div>
				</div>
		}
		let customStyle = {};
		if (showChatSidePanel || showAIAnswerPanel || showKBLibraryPanel) {
			customStyle = { width: "calc( 100% - 340px )"}
		}

		return <div className={"external-chat" + (this.props.showReply ? '-show-reply' : '')} style={customStyle} ref={el=>{this.el=el}}>
			{externalData}
			{messages}
			{previewDOM}
		</div>;
	}
};

const SidePanelContentBlock = ({className, children, hidden}) => {
	return <div hidden={hidden} className={"content-container " + className}>
		{children}
	</div>
}

class ChatSidePanel extends React.Component {
	constructor(props) {
		super(props);
	}
	handleCloseSidePanel = () => {
		this.props.onToggleErrandChatSidePanel(false);
		this.props.onToggleChatRatingBox(false);
		this.props.onToggleVisitorPath(false);
	}
	handleCloseVisitorPath = () => {
		this.props.onToggleVisitorPath(false);
	}
	render() {
		const {
			chat
			, clientChatSatisfaction
			, csmData
			, showAgentRating
			, showVisitorPath
			, showChatSidePanel
			, chatMessagesHeight
		} = this.props
		let cs = this.props.chatSatisfaction;
		return (
			<div className='chatmessage-sidepanel' >
				{showChatSidePanel &&
					<div className="chat-errand sidepanel" style={{height: chatMessagesHeight}}>
						<div className="panel-header">
							<span className="panel-actions" onClick={this.handleCloseSidePanel}><i className="fa fa-times"></i></span>
							<span className="heading">{I("Chat panel")}</span>
						</div>
						<div className="panel-body">
							<SidePanelContentBlock className="client-satisfaction">
								<ClientSatisfaction
									clientChatSatisfaction={clientChatSatisfaction}
									csmData={csmData}
									showAgentRating={showAgentRating}
									{...this.props}
								/>
							</SidePanelContentBlock>
							{showAgentRating &&
							<SidePanelContentBlock className="agent-satisfaction" hidden={!cs.isToggleOn}>
								<AgentChatSatisFactionMeter onCloseSidePanel={this.handleCloseSidePanel} {...this.props} />
							</SidePanelContentBlock>}
							{showVisitorPath &&
								<SidePanelContentBlock className="visitor-path">
								<VisitorPathWindow chat={chat}
									showVisitorPath={showVisitorPath}
									closeVisitorPath={this.handleCloseVisitorPath}
								/>
							</SidePanelContentBlock>}
						</div>
					</div>
				}
			</div>
		)
	}
}

class AgentChatSatisFactionMeter extends React.Component {
	handleToggle =()=>{
		this.props.onToggleChatRatingBox(true);
	}

	handleCancelButton = () => {
		this.props.onToggleChatRatingBox(false);
	}

	handleSendButton = () => {
		let sessionId = this.props.chat.sessionId;
		let cs = this.props.chatSatisfaction;
		let r = cs.ratingPoint;
		let t = cs.chatRatingText;
		this.props.onSendChatRating(sessionId, r, t);

		this.props.onCloseSidePanel();
	}

	handleLikeIcon = () => {
		this.props.onHandleChatLike('likechat');
	}

	handleUnlikeIcon = () => {
		this.props.onHandleChatUnlike('unlikechat');
	}

	handleChangeChatText = (e) => {
		this.props.handleChatRatingBody(e.target.value);
	}

	render() {
		let cs = this.props.chatSatisfaction;
		return (
			<div className='agent-satisfaction'>
				<div className="row-agent">
					<label id="textfields.label" className="feedback-heading">{I("Please rate this chat")}</label><br/>
					<div className="satisfaction-icon-container">
						<i id="closedLikedChat" title="Rate chat good" className={"upvote icon-smile" + (cs.isLikeIconActive ? " addButtonActive" : "")} onClick={this.handleLikeIcon}></i>
						<i id="closedUnlikedChat" title="Rate chat bad" className={"downvote icon-frown" + (cs.isUnlikeIconActive ? " addButtonActive" : "")} onClick={this.handleUnlikeIcon}></i>
						<input type="hidden" id="ratingId" value="1"/>
					</div>
				</div>
				{(cs.isLikeIconActive || cs.isUnlikeIconActive) && this.props.allowTextCommentChatRating &&
					<div className="row-agent">
						<textarea id="comments" placeholder={I("Leave a comment (optional)")} className="" rows="3" onChange={this.handleChangeChatText}></textarea>
					</div>
				}
				<div className="row-agent text-right">
					{/* <button id="buttonCancelAfterChatClose" className="leaveButton" type="button" onClick={this.handleCancelButton}>{I("Cancel")}</button> */}
					{/* <button id="buttonSendAfterChatClose" className="leaveButton" type="button" onClick={this.handleSendButton}>{I("Send")}</button> */}
					<button id="buttonSendAfterChatClose" data-qa-id="chat-send-history-btn" className={"btn-blue leaveButton" + (!cs.ratingPoint ? " disabled" : "")} type="button" onClick={this.handleSendButton}>{I("Send")}</button>
				</div>
			</div>
		)
	}
}

class ClientSatisfaction extends React.Component {
	handleToggle =()=>{
		this.props.onToggleClientChatRatingBox(true);
	}

	handleCloseButton = () => {
		this.props.onToggleClientChatRatingBox(false);
	}
	render() {
		let cs = this.props.clientChatSatisfaction;
		let csm = this.props.csmData;
		return (
			<div className='client-satisfaction'>
				<div className="row-client title">
					<label className="rating-label">{I("Customer Feedback ")}</label>
				</div>
				<div className="row-client">
				  <div className="feedback-rating icon-block">
						{ csm.rating === ""? <i title={I("no ratings yet")} className={"icon-neutral"}></i> : null}
						{ csm.rating === 1 ? <i className={"upvote icon-smile"}></i> : null}
						{ csm.rating === 0 ? <i className={"downvote icon-frown"}></i> : null}
				  </div>
					{ csm.message !== "" && csm.message.length > 0  &&
						<div className="feedback-comment">
							{/* <label className="comment">{I("Comment: ")}</label> */}
							<span className="text">{csm.message}</span>
						</div>
					}
				</div>
		  </div>
		 )
	}
}

class VideoToolbar extends React.Component {
	constructor(props) {
		super(props);
		this.handleRecord = this.handleRecord.bind(this);
		this.handleStopRecord = this.handleStopRecord.bind(this);
		this.handleStartCall = this.handleStartCall.bind(this);
		this.handleStopCall = this.handleStopCall.bind(this);
		this.handleStartWebcam = this.handleStartWebcam.bind(this);
		this.handleBlurBackground = this.handleBlurBackground.bind(this);
		this.handleStopBlurBackground = this.handleStopBlurBackground.bind(this);
		this.handleChangeBackground = this.handleChangeBackground.bind(this);
		this.handleToggleBg = this.handleToggleBg.bind(this);
		this.handleResetBackground = this.handleResetBackground.bind(this);
		this.handleScrollDown = this.handleScrollDown.bind(this);
		this.handleScrollUp = this.handleScrollUp.bind(this);
		this.handleScrollLeft = this.handleScrollLeft.bind(this);
		this.handleScrollRight = this.handleScrollRight.bind(this);
		this.handleToggleCoBrowseActions =  this.handleToggleCoBrowseActions.bind(this);
		this.handleAgentControlAction = this.handleAgentControlAction.bind(this);
		this.handleDrawing = this.handleDrawing.bind(this);
		this.handleChangeColor = this.handleChangeColor.bind(this);
		this.handleUndoDraw = this.handleUndoDraw.bind(this);
		this.handleClearDraw = this.handleClearDraw.bind(this);
		this.state = {
			callRequesting: false,
			showStartCamera: true,
			coBrowseMode: 0,
			blurMode: false,
			bgMode: false,
			showBgSelector: false,
			showCoBrowseActions: false,
			showScrollOpt: false,
			scrollActive: false,
			drawingMode: false
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps.isAgentVideoCallShowing !== this.props.isAgentVideoCallShowing) {
			if(this.props.isAgentVideoCallShowing){
				//this.setState({ showStartCamera: false});
			}
		}
		if(prevProps.callInProgress && !this.props.callInProgress){
			this.setState({ showStartCamera: true, bgMode: false, blurMode: false});
		}
		if(prevState.callRequesting && !this.state.callRequesting){
			this.setState({ showStartCamera: true, bgMode: false, blurMode: false });
		}
		if(!prevProps.isAgentVideoCallShowing && this.props.isAgentVideoCallShowing) {
			this.setState({ showStartCamera: true, bgMode: false, blurMode: false });
		}
		if(prevProps.onCoBrowseMode && !this.props.onCoBrowseMode) {
			this.setState({showCoBrowseActions: false, showScrollOpt: false, scrollActive: false });
			this.props.onMouseToggle(false);
			this.props.onKeyboardToggle(false);
		}
		if(prevProps.agentFullScreen && !this.props.agentFullScreen) {
			this.setState({showCoBrowseActions: false, showScrollOpt: false, scrollActive: false });
			this.props.onMouseToggle(false);
			this.props.onKeyboardToggle(false);
		}
		if(!prevProps.agentFullScreen && this.props.agentFullScreen) {
			if(this.props.onCoBrowseMode) {
				this.setState({showCoBrowseActions: true });
			}
		}
	}
	handleRecord(){
		this.props.onHandleRecord(true);
	}
	handleStopRecord(){
		this.props.onHandleRecord(false);
	}
	handleStartCall(){
		this.props.onStartCall();
	}
	handleStopCall(){
		this.props.onHangupCall(false);
	}
	handleStartWebcam(){
		this.setState({ showStartCamera: false });
		startWebCamAction(this.props.sessionId);
		this.props.onCameraStart(true);
	}
	handleBlurBackground(){
		handleBlurBg(this.props.sessionId);
		this.setState({blurMode: true, bgMode: false, showBgSelector: false});
	}
	handleStopBlurBackground() {
		stopBlurBg(this.props.sessionId);
		this.setState({blurMode: false, showBgSelector: false});
	}
	handleChangeBackground(img) {
		this.props.onSelectBackground(img);
		if(!this.state.bgMode) {
			changeBg(this.props.sessionId, img);
			this.setState({bgMode: true, blurMode: false, showBgSelector: false});
		} else {
			this.setState({ showBgSelector: false});
			sendChangeBgOnlySignal(this.props.sessionId, img);
		}
	}
	handleResetBackground() {
		removeBg(this.props.sessionId);
		this.setState({bgMode: false, showBgSelector: false});
		this.props.onSelectBackground("");
	}
	handleToggleBg() {
		this.setState({ showBgSelector: !this.state.showBgSelector });
	}
	handleScrollDown() {
		this.props.onScrollDown();
	}
	handleScrollUp() {
		this.props.onScrollUp();
	}
	handleScrollLeft() {
		this.props.onScrollDown();
	}
	handleScrollRight() {
		this.props.onScrollUp();
	}
	handleToggleCoBrowseActions() {
		this.setState({showCoBrowseActions: !this.state.showCoBrowseActions });
	}
	handleAgentControlAction(action) {
		switch(action) {
			case ACT_MOUSE_CURSOR:
				this.props.onMouseToggle(!this.props.mouseActive);
				break;
			case ACT_KEYBOARD_INPUT:
				this.props.onKeyboardToggle(!this.props.keyboardActive);
				break;
			case ACT_SCROLL_PAGE:
				this.setState({
					scrollActive: !this.state.scrollActive
					,showScrollOpt: !this.state.showScrollOpt
				});
				break;
			default:
				console.log("no valid action");
				break;
		}
	}
	handleDrawing() {
		this.setState({drawingMode: !this.state.drawingMode});
		this.props.onDrawingMode(!this.state.drawingMode);
	}
	handleUndoDraw() {
		this.props.onUndoHighlight();
	}
	handleClearDraw() {
		this.props.onClearHighlight();
	}
	handleChangeColor(color) {
		this.props.onSelectHighlightColor(color);
	}
	render() {
		let disableAudioTitle = I("Disable mic"), iconAudio = "icon-microphone", disableVideoTitle = I("Disable camera"), iconToggleVideo = "icon-video-call";
		let remoteVidWrapper = "remote-video-wrapper", localVidWrapper = "local-video-wrapper";
		if(this.props.agentMuted) {
			disableAudioTitle = I("Enable mic");
			iconAudio = "icon-microphone-mute";
		}
		if(this.props.agentCameraOff) {
			iconToggleVideo = "icon-video-call-disable";
			disableVideoTitle = I("Enable camera");
		}
		let iconFullScreen = "icon-fullscreen";
		let vidToolbarClassName = "cention-video-agent-header", iconResizeVid = "icon-minimize";
		if(this.props.agentFullScreen) {
			iconFullScreen = "icon-fullscreen-end";
			vidToolbarClassName = "cention-video-agent-header cVidToolbar";
		} else {
			vidToolbarClassName = "cention-video-agent-header";
		}
		if(this.props.agentMinimized && !this.props.agentFullScreen){
			iconResizeVid = "icon-maximize";
			localVidWrapper += " hidden";
			remoteVidWrapper += " minimized";

		}
		let recordClassName = "icon-record", recordingTitle = I("Record");
		if(this.props.isRecording) {
			recordClassName = "icon-stop";
			recordingTitle = I("Stop recording");
		}
		let callBtnClassName = "call-btn circle-btns", enableHangUp = false;
		if(this.props.isCalling){
			callBtnClassName += " disabled";
			enableHangUp = true;
		}else{
			if(this.props.callInProgress){
				enableHangUp = true;
			}else {
				enableHangUp = false;
			}
		}
		//scroll action will move 100px range of position
		let coBrowseActionOpt = [
			{id: ACT_MOUSE_CURSOR, name: <span className={classNames("cobrowse-act",{active: this.props.mouseActive})}>{I("Mouse")} <i className="icon-single-tick" hidden={!this.props.mouseActive}></i><i className="icon-times" hidden={this.props.mouseActive}></i></span>},
			{id: ACT_KEYBOARD_INPUT, name: <span className={classNames("cobrowse-act",{active: this.props.keyboardActive})}>{I("Keyboard")}<i className="icon-single-tick" hidden={!this.props.keyboardActive}></i><i className="icon-times" hidden={this.props.keyboardActive}></i></span>},
			{id: ACT_SCROLL_PAGE, name: <span className={classNames("cobrowse-act",{active: this.state.scrollActive})}>{I("Scroll")}<i className="icon-single-tick" hidden={!this.state.scrollActive}></i><i className="icon-times" hidden={this.state.scrollActive}></i></span>},
		]
		let showCoBrowseTools = false, showDrawingTools = false;
		if(this.props.agentFullScreen && this.props.onCoBrowseMode) {
			showCoBrowseTools = true;
			if(this.state.drawingMode) {
				showDrawingTools = true;
			}
		}
		//TODO: feature to let agent highlight/drawing on client's page, using diff approach atm
		//const HIGHLIGHT = false;
		return <div className={this.props.className} hidden={this.props.hide}>
				{
					/* HIGHLIGHT &&
						<div>
							<div id="start-draw" className="circle-btns" title={I("Highlight")} onClick={this.handleDrawing} hidden={!showCoBrowseTools}><i className="icon-draw"></i></div>
							<div hidden={!showDrawingTools}>
								<ColorPicker id="drawColorPicker" popoverPos={"top"} onChangeColor={this.handleChangeColor} selected={this.props.highlightColor} hideSelected={true} />
							</div>
							<div id="undo-draw" className="circle-btns" title={I("Undo highlighting")} onClick={this.handleUndoDraw} hidden={!showDrawingTools}><i className="icon-return"></i></div>
							<div id="clear-draw" className="circle-btns" title={I("Clear highlighting")} onClick={this.handleClearDraw} hidden={!showDrawingTools}><i className="icon-reload"></i></div>
						</div> */
				}
				{
					showCoBrowseTools &&
					<Dropdown
						id={"coBrowse-action-selector"}
						title={<i className="icon-control"></i>}
						className={"cobrowse-tools circle-btns"}
						show={this.state.showCoBrowseActions}
						multiSelect={false}
						onToggle={this.handleToggleCoBrowseActions}
						data={coBrowseActionOpt}
						onSelect={this.handleAgentControlAction}
						selected={""}
						readOnly={false}
					/>
				}
				<div id="scroll-up" className="circle-btns" title={I("Scroll up")} onClick={this.handleScrollUp} hidden={!this.state.showScrollOpt}><i className="icon-arrow-up"></i></div>
				<div id="scroll-down" className="circle-btns" title={I("Scroll down")} onClick={this.handleScrollDown} hidden={!this.state.showScrollOpt}><i className="icon-arrow-down"></i></div>
				<div id="scroll-left" className="circle-btns" title={I("Scroll left")} onClick={this.handleScrollLeft} hidden={!this.state.showScrollOpt}><i className="icon-arrow-left"></i></div>
				<div id="scroll-right" className="circle-btns" title={I("Scroll right")} onClick={this.handleScrollRight} hidden={!this.state.showScrollOpt}><i className="icon-arrow-right"></i></div>
				{(!this.state.showStartCamera || this.props.callInProgress) &&
					<ImageSelector id={this.props.sessionId} showSelector={this.state.showBgSelector} onToggle={this.handleToggleBg} blurMode={this.state.blurMode} bgMode={this.state.bgMode} onBlurBg={this.handleBlurBackground} onStopBlurBg={this.handleStopBlurBackground} onResetBg={this.handleResetBackground} onSelectBg={this.handleChangeBackground} />
				}
				<div id="disable-mic-button" title={disableAudioTitle} className={"circle-btns"} onClick={this.props.agentMuted ? this.props.onEnableAudio : this.props.onMuteAudio} hidden={!this.props.callInProgress}><i className={iconAudio}></i></div>
				<div id="endButton" className={"circle-btns"} title={disableVideoTitle} onClick={this.props.agentCameraOff ? this.props.onEnableVideo : this.props.onDisableVideo} hidden={!this.props.callInProgress}><i className={iconToggleVideo}></i></div>
				{(this.state.showStartCamera || this.props.isClientRejectVidCall) &&
					<div id="start-webcam" className="webcam-bttn circle-btns" title={I("Start webcam")} onClick={this.handleStartWebcam} hidden={this.props.callInProgress}><i className="icon-video-call"></i></div>
				}
				{this.props.allowAgentVideoCall && !this.state.showStartCamera &&
					<div id="start-call" className={callBtnClassName} title={I("Start call")} onClick={this.handleStartCall} hidden={this.props.callInProgress || this.props.isClientRejectVidCall}><i className="icon-call"></i></div>
				}
				<div id="hangup-button" className="hangup-bttn circle-btns" title={I("Hang up")} onClick={this.handleStopCall} hidden={!enableHangUp}><i className="icon-call"></i></div>
				{(this.props.allowVideoRecording && !this.props.allowAutoVideoRecording) &&
					<div id="record-button" className="record-bttn" title={recordingTitle} onClick={this.props.isRecording ? this.handleStopRecord : this.handleRecord} hidden={!this.props.callInProgress}><i className={recordClassName}></i></div>
				}
				<div className="resize-bttns" title={this.props.agentFullScreen ? I("Exit full screen") : I("Enter full screen")} onClick={this.props.onToggleFullScreen}><i className={iconFullScreen}></i></div>
				<div className="resize-bttns" title={this.props.agentMinimized ? I("Maximize video") : I("Minimize video")} onClick={this.props.onMinimizeVideo}><i className={iconResizeVid}></i></div>
			</div>
	}
}

export class AgentVidTimer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			time: 0,
			isOn: false,
			start: 0
		}
		this.startTimer = this.startTimer.bind(this);
		this.stopTimer = this.stopTimer.bind(this);
		this.resetTimer = this.resetTimer.bind(this);
	}
	componentDidMount(){
		this.resetTimer();
	}
	componentWillUnmount(){
		if(this.props.videoCallInProgress) {
			this.stopTimer();
		}
		this.resetTimer();
	}
	componentDidUpdate(prevProps, prevState) {
		if(this.props.sessionId === prevProps.sessionId) {
			if(!prevProps.videoCallInProgress) {
				if(this.props.videoCallInProgress) {
					this.startTimer();
				}
			} else {
				if(prevProps.videoCallInProgress) {
					if(!this.props.videoCallInProgress) {
						this.stopTimer();
						this.resetTimer();
					}
				}
			}
		}
	}
	startTimer() {
		this.setState({
			isOn: true,
			time: this.state.time,
			start: Date.now() - this.state.time
		})
		this.timer = setInterval(() => this.setState({
			time: Date.now() - this.state.start
		}), 1);
	}
	stopTimer() {
		this.setState({isOn: false})
		clearInterval(this.timer)
		this.props.onStop(this.state.time);
	}
	resetTimer() {
		this.setState({time: 0, isOn: false});
	}
	render() {
		let time = msToHMS(this.state.time);
		return(
			<div className="cention-video-timer" hidden={!this.props.videoCallInProgress}>
				<i className="icon-recording" hidden={!this.props.recording}/>
				{time}
			</div>
			)
	}
}

const StyledVidSharingInfo = styled.div`
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
	line-height: 1;
	text-align: center;
	color: #eaeaea;
	transition: color, background-color .2s ease;
`

const StyledCloseIcon = styled.i`
	position: absolute;
	width: 22px;
	height: 22px;
	padding: 5px;
	top: 5px;
	right: 5px;
	text-align: center;
	color: #000;
	cursor: pointer;
	transition: color, background-color .2s ease;
	background: #ffffff80;
	&:before {
		color: #000;
	}
`
const StyledLoadingIcon = styled.i`
	position: absolute;
	padding: 5px;
	top: ${props => props.top ? props.top : '30%'};
	left: ${props => props.left ? props.left : '50%'};
	text-align: center;
	color: ${props => props.color ? props.color : '#000'};
	cursor: pointer;
	transition: color, background-color .2s ease;
	&:before {
		color: ${props => props.color ? props.color : '#000'};
		font-size: ${props => props.fontSize ? props.fontSize : '20px'};
		opacity: ${props => props.opacity ? props.opacity : 0.5};
	}
`

const StyledVideoBackground = styled.div`
	width: 100%;
	height: 100%;
	// max-height: 225px;
	background-image: url('${props => props.bg ? props.bg : ""}');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: transparent;
`

const FS_REMOTE = 1, FS_LOCAL = 2,  FS_REMOTE_DISPLAY = 3, FS_LOCAL_DISPLAY = 4;

const AvatarOverlay = ({avatar, className, onClick, hide}) => {
	return (
		<div className={className} hidden={hide} onClick={onClick}>
			{avatar
				? <img className='photo' src={avatar}/>
				: <i className="icon-user-fill"></i>
			}
		</div>
	)
}

function VideoWrapperWithBackground({background, currentFS, fullscreen, selfExtractionDone}) {
	return <StyledVideoBackground id="vid-bg-container"
		className={classNames("bg-canvas-mask",
			{cVidFullScreen: (currentFS === FS_LOCAL && fullscreen)}
		)}
		bg={background}
	>
	<StyledLoadingIcon
		hidden={selfExtractionDone}
		title={I("Extracting background")}
		className="fa fa-spinner fa-spin"
		left="40%"
		fontSize="40px"
		color="#D5D5D5"
		opacity={1}
		aria-hidden="true"
	/>
	</StyledVideoBackground>
}

export class AgentVideoFrame extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			muted: false,
			blinded: false,
			minimized: false,
			calling: false,
			agentInitiatedCall: false,
			recording: false,
			screenShareMode: false,
			currentFullScreen: FS_REMOTE,
			showThumbnail: true,
			selectedVideoBg: "",
			showDrawingCanvas: false,
			startDraw: false,
			highlightColor: "#FF5733",
			clientVidLoading: false,
			cameraStarted: false,
			backgroundSet: []
		}
		this.handleStartCall = this.handleStartCall.bind(this);
		this.handleHangUpCall = this.handleHangUpCall.bind(this);
		this.handleDisableVideo = this.handleDisableVideo.bind(this);
		this.handleEnableVideo = this.handleEnableVideo.bind(this);
		this.handleMuteVideo = this.handleMuteVideo.bind(this);
		this.handleEnableAudio = this.handleEnableAudio.bind(this);
		this.handleFullScreenVideo = this.handleFullScreenVideo.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleMinVideo = this.handleMinVideo.bind(this);
		this.handleCloseVidFrame = this.handleCloseVidFrame.bind(this);
		this.handleStopTimer = this.handleStopTimer.bind(this);
		this.handleRecording = this.handleRecording.bind(this);
		this.handleAutoRecording = this.handleAutoRecording.bind(this);
		this.makeFullScreen = this.makeFullScreen.bind(this);
		this.handleToggleThumbnail = this.handleToggleThumbnail.bind(this);
		this.handleCloseRemoteShare = this.handleCloseRemoteShare.bind(this);
		this.onPlaying = this.onPlaying.bind(this);
		this.handleVideoBg = this.handleVideoBg.bind(this);
		this.handleMouseMove = this.handleMouseMove.bind(this);
		this.handleMouseDown = this.handleMouseDown.bind(this);
		this.handleMouseUp = this.handleMouseUp.bind(this);
		this.handleMouseClick = this.handleMouseClick.bind(this);
		this.handleAgentKeyDown = this.handleAgentKeyDown.bind(this);
		this.handleScrollDown = this.handleScrollDown.bind(this);
		this.handleScrollUp = this.handleScrollUp.bind(this);
		this.handleOnDrawingMode = this.handleOnDrawingMode.bind(this);
		this.handleHighlightColor = this.handleHighlightColor.bind(this);
		this.setCanvasRef = this.setCanvasRef.bind(this);
		this.handleUndoHighlight = this.handleUndoHighlight.bind(this);
		this.handleClearHighlight = this.handleClearHighlight.bind(this);
		this.handleMouseActive = this.handleMouseActive.bind(this);
		this.handleKeyboardActive = this.handleKeyboardActive.bind(this);
		this.handleClientVidPlay = this.handleClientVidPlay.bind(this);
		this.handleCamStart = this.handleCamStart.bind(this);
	}
	componentDidMount(){
		document.addEventListener("keydown", this.handleKeyDown, false);
		$(window).on(
			"beforeunload.autoSave"
			, e => this.props.onWindowBeforeUnload(
				this.props.videoCallInProgress
				, this.props.showCoBrowseFrame
				, e
			)
		);
		let backgroundSet = []
		backgroundData.map((bg) => {
			let xhr = new XMLHttpRequest();
			const bgImgPath = process.env.CLOUDFRONT_URL + "/img/background/"
			xhr.open("GET", bgImgPath+bg.img);
			xhr.responseType = "blob";
			xhr.onload = (e) => {
				let urlCreator = window.URL || window.webkitURL;
				const imageUrl = urlCreator.createObjectURL(e.target.response);
				backgroundSet.push({title: bg.img, path: imageUrl});
			}
			xhr.send();
		});
		this.setState({ backgroundSet: backgroundSet });
	}
	componentDidUpdate(prevProps, prevState) {
		if(this.props.info.sessionId === prevProps.info.sessionId) {
			if(!prevProps.clientRejectVidCall && this.props.clientRejectVidCall){
				//client rejecting call
				this.setState({ calling: false, cameraStarted: false });
			}else{
				if(!prevProps.videoCallInProgress && this.props.videoCallInProgress){
					//client answer call
					this.setState({ calling: false, cameraStarted: true });
				}
				if(prevProps.videoCallInProgress && !this.props.videoCallInProgress){
					this.setState({ calling: false, agentInitiatedCall: false, cameraStarted: false });
					//if automatic recording feature is on, stop recording here
					if(this.props.allowAutoVideoRecording) {
						this.handleAutoRecording(false);
					} else {
						if(this.state.recording) {
							this.handleRecording(false);
						}
					}

					//if fullscreen is on, exit full screen
					//but on co browse mode, full screen must still happens
					if(!this.props.showCoBrowseFrame) {
						if(this.props.videoCallFullScreen) {
							this.props.onVideoFullScreen(false);
						}
					}
					//if minimize, set it back to normal
					if(this.state.minimized) {
						this.setState({ minimized: false });
					}
				}
				//show video loaded once co browsing/screen sharing initialized
				if(this.props.showCoBrowseFrame && !prevProps.showCoBrowseFrame) {
					this.setState({ clientVidLoading: true });
				}
				//if co browsing turned, on fullscreen mode, show remote display as fs
				if(this.props.videoCallFullScreen && !prevProps.videoCallFullScreen) {
					if(this.props.showCoBrowseFrame) {
						this.setState( {currentFullScreen: FS_REMOTE_DISPLAY })
					}
				}
			}
			if(prevProps.hide && !this.props.hide ) {
				this.setState({ cameraStarted: false });
			}
		}
	}
	componentWillUnmount(){
		document.removeEventListener("keydown", this.handleKeyDown, false);
		$(window).off("beforeunload.autoSave");
		if(this.props.videoCallInProgress) {
			this.handleHangUpCall(true);
		}
		if(this.state.recording) {
			stopRecording(this.props.info.sessionId);
		}
		if(this.props.showCoBrowseFrame) {
			//TODO: stop co browsing ,should warn
			this.handleCloseRemoteShare();
		}
		this.setState({cameraStarted: false});
		if(this.state.backgroundSet.length > 0) {
			this.state.backgroundSet.map((bg) => {
				let urlCreator = window.URL || window.webkitURL;
				urlCreator.revokeObjectURL(bg.path);
			});
		}
	}
	handleStopTimer(time) {
		this.props.onUpdateVidChatTimer(msToHMS(time));
		AgentSocket.SendEvent('AGENT_UPDATE_TIMER_VID_CHAT', {
			timer: time,
			sessionId: this.props.info.sessionId,
		});
	}
	handleCamStart(status) {
		this.setState({cameraStarted: status});
	}
	handleStartCall() {
		this.setState({ calling: true, agentInitiatedCall: true });
		this.props.onAgentCall();
		let sessionId = this.props.info.sessionId,
		clientId = this.props.info.errand.data.fromId,
		agentId = this.props.info.errand.data.agentId;
		call(sessionId);
	}
	handleHangUpCall(bySystem) {
		this.setState({ calling: false, agentInitiatedCall: false });
		if(this.props.info) {
			let sessionId = this.props.info.sessionId
			, agentId = this.props.info.errand.data.agentId;
			this.props.onHangUpCall(sessionId, bySystem);
		}
	}
	handleDisableVideo() {
		this.setState({ blinded: true });
		if(this.props.videoCallInProgress){
			disableVideo(true);
			AgentSocket.SendEvent('agent-disable-video', {
				sessionId: this.props.info.sessionId,
			});
		} else {
			stopVideoCamera();
		}
	}
	handleEnableVideo() {
		this.setState({ blinded: false });
		disableVideo(false);
		AgentSocket.SendEvent('agent-enable-video', {
			sessionId: this.props.info.sessionId,
		});
	}
	handleMuteVideo() {
		this.setState({ muted: true });
		muteVideo(true);
		AgentSocket.SendEvent('agent-mute-video', {
			sessionId: this.props.info.sessionId,
		});
	}
	handleEnableAudio() {
		this.setState({ muted: false });
		muteVideo(false);
		AgentSocket.SendEvent('agent-unmute-video', {
			sessionId: this.props.info.sessionId,
		});
	}
	handleKeyDown(e) {
		//This should trigger only on full screen mode
		if(this.props.videoCallFullScreen) {
			e.preventDefault();
			//Press "Escape" key to toggle full screen
			if(e.key === "Escape" || e.key === "Esc") {
				this.props.onVideoFullScreen(false);
				//todo: only on co browse mode
				this.setState({showDrawingCanvas: false});
			}
		}
	}
	handleFullScreenVideo() {
		let toggleFS = !this.props.videoCallFullScreen;
		if(this.props.onVideoFullScreen) {
			if(this.state.minimized && !this.props.videoCallFullScreen) {
				this.props.onVideoFullScreen(true);
			} else {
				this.props.onVideoFullScreen(toggleFS);
			}
			if(this.props.screenShareMode) {
				//show remote display as fs
				if(this.props.isAgentSharingScreen){
					this.setState({ minimized: false, currentFullScreen: FS_LOCAL_DISPLAY });
				} else if (this.props.isClientSharingScreen) {
					this.setState({ minimized: false, currentFullScreen: FS_REMOTE_DISPLAY });
				}
			} else {
				this.setState({ minimized: false, currentFullScreen: FS_REMOTE });
			}
		}
	}
	handleMinVideo() {
		this.props.onVideoFullScreen(false);
		//Minimize - Make remote video small, and hide local video
		//Maximize - Make it appear as default size
		this.setState({ minimized: !this.state.minimized });
	}
	handleCloseVidFrame() {
		//stopping video and exit video frame
		this.props.onCloseVideoFrame(this.props.info.sessionId);
	}
	handleAutoRecording(record) {
		let streams = {};
		if(this.state.agentInitiatedCall) {
			let localStream = getLocalStream(), remoteStream = getRemoteStream();
			streams = {localStream: localStream, remoteStream: remoteStream};
		}
		if(record) {
			//I think this never used
			startRecording(this.props.info.sessionId, streams);
		} else {
			stopRecording(this.props.info.sessionId);
		}
	}
	handleRecording(record) {
		let streams = {};
		if(this.state.agentInitiatedCall) {
			let localStream = getLocalStream(), remoteStream = getRemoteStream();
			streams = {localStream: localStream, remoteStream: remoteStream};
		}
		if(record) {
			startRecording(this.props.info.sessionId, streams);
			this.setState({ recording: true });
		} else {
			stopRecording(this.props.info.sessionId);
			this.setState({ recording: false });
		}
	}
	handleCloseRemoteShare() {
		let force = false;
		if(!this.props.videoCallInProgress) {
			force = true;
		}
		handleStopClientScreenShare(this.props.info.sessionId, true, force);
	}
	makeFullScreen(vid) {
		//only during full screen mode that this is triggered
		if(this.props.videoCallFullScreen && this.state.currentFullScreen !== vid) {
			this.setState({ currentFullScreen: vid });
		}
	}
	handleToggleThumbnail(){
		this.setState({
			showThumbnail: !this.state.showThumbnail
		})
	}
	onPlaying(){
		const canvas = document.getElementById('agentVidCanvas');
		const videoElement = document.getElementById('CentionChatVideoFrameLocalAgent');
		canvas.height = videoElement.videoHeight;
		canvas.width = videoElement.videoWidth;
	}
	handleVideoBg(img) {
		if(this.state.backgroundSet.length > 0) {
			this.state.backgroundSet.map((bg) => {
				if(bg.title == img) {
					this.setState({ selectedVideoBg: bg.path });
				}
			})
		}
	}
	handleMouseMove(pos) {
		let data = {};
		if(this.props.agentMouseControl) {
			data = {
				pos: pos
			}
			sendMouseMove(this.props.info.sessionId, "mouse-move", data);
		} else if (this.state.showDrawingCanvas && this.state.startDraw) {
			//drawing mode
			data = {
				pos: pos,
				highlightMode: this.state.showDrawingCanvas,
				color: this.state.highlightColor
			}
			sendMouseMove(this.props.info.sessionId, "start-highlight", data);
		}
	}
	getVideoSize(vid) {
		var cs = getComputedStyle(vid);
		var w = parseInt(cs.getPropertyValue("width"), 10);
		var h = parseInt(cs.getPropertyValue("height"), 10);
		return {width: w, height: h};
	}
	handleMouseDown(pos) {
		let data = {
			pos: pos,
		}
		if(this.props.agentMouseControl) {
			sendMouseMove(this.props.info.sessionId, "mouse-down", data);
		} else if(this.state.showDrawingCanvas) {
			let vidElem = document.getElementById("client-display-view")
			var size = this.getVideoSize(vidElem);
			data.size = size;
			this.setState({ startDraw: true });
			sendMouseMove(this.props.info.sessionId, "init-highlight", data);
		}
	}
	handleMouseUp() {
		if(this.props.agentMouseControl) {
			sendMouseMove(this.props.info.sessionId, "mouse-up");
		} else if (this.state.showDrawingCanvas){
			this.setState({ startDraw: false });
			sendMouseMove(this.props.info.sessionId, "stop-highlight");
		}
	}
	handleMouseClick(pos) {
		if(this.props.agentMouseControl) {
			sendMouseMove(this.props.info.sessionId, "mouse-click", {pos: pos});
		}
	}
	handleAgentKeyDown(key) {
		if(this.props.agentKeyboardControl) {
			sendMouseMove(this.props.info.sessionId, "keydown", {key: key});
		}
	}
	handleScrollDown() {
		sendScrollDirection(this.props.info.sessionId, "scroll-down");
	}
	handleScrollUp() {
		sendScrollDirection(this.props.info.sessionId, "scroll-up");
	}
	handleOnDrawingMode(show) {
		//show transparent drawing canvas
		this.setState({showDrawingCanvas: show});
		if(!show) {
			//send signal stop drawing mode to client
			sendMouseMove(this.props.info.sessionId, "stop-drawing");
		}
	}
	handleHighlightColor(color) {
		this.setState({ highlightColor: color });
	}
	setCanvasRef(ref) {
		this.drawCanvasRef = ref;
		return ref;
	}
	handleUndoHighlight() {
		if(this.drawCanvasRef) {
			this.drawCanvasRef.undo();
		}
	}
	handleClearHighlight() {
		if(this.drawCanvasRef) {
			this.drawCanvasRef.clear();
		}
	}
	handleMouseActive(toggle) {
		this.props.onToggleMouseControl(toggle);
		if(toggle) {
			sendScrollDirection(this.props.info.sessionId, "mouse-start");
		} else {
			sendScrollDirection(this.props.info.sessionId, "mouse-stop");
		}
	}
	handleKeyboardActive(toggle) {
		this.props.onToggleKeyboardControl(toggle);
	}
	handleClientVidPlay() {
		this.setState({ clientVidLoading: false });
	}
	render() {
		const ENABLE_RINGER = true, ENABLE_TIMER = true;
		const { isClientSharingScreen
		, isAgentSharingScreen
		, clientAvatar
		} = this.props
		let meetingId = this.props.info.errand.data.displayId,
		clientName = this.props.info.errand.data.fromName,
		clientMute = this.props.videoCallClientMute,
		clientOffWebCam = this.props.videoCallClientOffWebCam,
		agentAvatar = this.props.info.errand.data.agentAvatar,
		agentName = this.props.info.errand.data.agent;
		let disableAudioTitle = I("Disable mic"), iconAudio = "icon-microphone-mute", disableVideoTitle = I("Disable camera"), iconToggleVideo = "icon-video-call-disable";
		if(this.state.muted) {
			disableAudioTitle = I("Enable mic");
			iconAudio = "icon-microphone";
		}
		if(this.state.blinded) {
			iconToggleVideo = "icon-video-call";
			disableVideoTitle = I("Enable camera");
		}
		let remoteVidWrapper = "remote-video-wrapper"
		,localVidWrapper = "local-video-wrapper"
		,localShareWrapper = "local-screen-wrapper"
		,remoteShareWrapper = "remote-screen-wrapper";

		let iconFullScreen = "icon-fullscreen"
		, remoteVid = "remote-video"
		, localVid = "local-video"
		, localVidCamDisable = "cam-disabled"
		, remoteVidCamDisable = "cam-disabled"
		, localShareVid = "local-share-video"
		, remoteShareVid = "remote-share-video";


		let vidToolbarClassName = "cention-video-agent-header", iconResizeVid = "icon-minimize";
		if(this.props.videoCallFullScreen) {
			iconFullScreen = "icon-fullscreen-end";
			vidToolbarClassName = "cention-video-agent-header cVidToolbar";
			if(this.state.currentFullScreen === FS_REMOTE) {
				//client is on full screen
				remoteVidWrapper += " noTHUMBNAIL";
				remoteVid += " cVidFullScreen";
				remoteVidCamDisable += " cVidFullScreen";

				localVidWrapper +=  " cVidThumbnail";
				remoteShareWrapper += " cVidThumbnail";
				localShareWrapper += " cVidThumbnail";

			} else if(this.state.currentFullScreen === FS_LOCAL) {
				//agent is on full screen
				localVidWrapper += " noTHUMBNAIL";
				localVid += " cVidFullScreen";
				localVidCamDisable += " cVidFullScreen";

				remoteVidWrapper += " cVidThumbnail";
				remoteShareWrapper += " cVidThumbnail";
				localShareWrapper += " cVidThumbnail";

			} else if(this.state.currentFullScreen === FS_REMOTE_DISPLAY) {
				//remote shared screen on full screen
				remoteShareWrapper += " noTHUMBNAIL";
				remoteShareVid += " cVidFullScreen";

				remoteVidWrapper += " cVidThumbnail";
				localVidWrapper +=  " cVidThumbnail";
				localShareWrapper += " cVidThumbnail";

				if(this.props.agentMouseControl) {
					remoteShareVid += " cursor-control";
				}

			} else if(this.state.currentFullScreen === FS_LOCAL_DISPLAY) {
				//local shared screen on fullscreen
				localShareWrapper += " noTHUMBNAIL";
				localShareVid += " cVidFullScreen";

				remoteVidWrapper += " cVidThumbnail";
				localVidWrapper +=  " cVidThumbnail";
				remoteShareWrapper += " cVidThumbnail"
			}
		} else {
			remoteVid = "remote-video";
			localVid = "local-video";
			vidToolbarClassName = "cention-video-agent-header";
			remoteShareVid = "remote-share-video common";
			localShareVid = "local-share-video";
		}

		//On minimized mode
		if(this.state.minimized && !this.props.videoCallFullScreen){
			iconResizeVid = "icon-maximize";
			localVidWrapper += " hidden";
			remoteVidWrapper += " minimized";
			if(!this.props.videoCallInProgress && this.props.isClientSharingScreen) {
				remoteShareWrapper += " minimized";
				localShareWrapper += " hidden";
			} else if(!this.props.videoCallInProgress && !this.props.isClientSharingScreen && this.props.isAgentSharingScreen) {
				localShareWrapper += " minimized";
			} else{
				remoteShareWrapper += " hidden";
				localShareWrapper += " hidden";
			}
		}
		if(this.props.showCoBrowseFrame) {
			remoteShareWrapper += " cobrowse-scroll";
		}
		const videoStyleOverlay = {
			opacity: "0.5",
			right: "0",
			bottom: "0",
			minHeight: "100%"
		}
		let closeBtnRightPos = "0px";
		if(this.props.videoCallInProgress) {
			if(this.props.isClientSharingScreen && this.props.isAgentSharingScreen) {
				closeBtnRightPos = "0px"
			} else {
				closeBtnRightPos = "150px";
			}
		}
		const flexStyleRemoteDisplayVid = {
			flexGrow: "unset"
		}
		const flexStyleLocalDisplayVid = {
			flexGrow: "unset",
			position: "relative"
		}
		let cntrRemoteVid = false, cntrLocalVid = false;
		if(this.props.videoCallInProgress && this.props.isClientSharingScreen && !this.props.isAgentSharingScreen) {
			cntrRemoteVid = true;
		} else if(this.props.videoCallInProgress && this.props.isAgentSharingScreen && !this.props.isClientSharingScreen) {
			cntrLocalVid = true;
		}
		let vidFrameTitle = I("Video call");
		if(this.props.showCoBrowseFrame) {
			vidFrameTitle = I("Co-browsing");
		} else if(this.props.isClientSharingScreen || this.props.isAgentSharingScreen) {
			vidFrameTitle = I("Screen sharing");
		}
		let videoCallInProgress = this.props.videoCallInProgress;
		return (
		<Draggable bounds={"parent"}>
			<div className={classNames(
				"cention-video-agent",
				{minimized: this.state.minimized},
				{fullscreen: this.props.videoCallFullScreen})}
				id="CentionChatVideoAgentFrame"
				hidden={this.props.hide}>
				<div className="cention-video-close" hidden={this.props.videoCallInProgress} onClick={this.handleCloseVidFrame} ><i className="icon-times"></i></div>
				<div className={vidToolbarClassName}>
					{this.props.videoCallFullScreen &&
						<div data-qa-id={"vid-thumbnail-toggler"} className="thumbnail-toggler" onClick={this.handleToggleThumbnail}>
							<i className={getChevronIcon(!this.state.showThumbnail)} />
						</div>
					}
					<div className="header-top" hidden={this.state.minimized && !this.props.videoCallFullScreen}>
						<span className="cention-video-title"><i className="icon-video-call"></i>{vidFrameTitle}</span>
						<VideoToolbar
							className="cention-video-agent-buttons"
							sessionId={this.props.info.sessionId}
							hide={this.state.minimized}
							agentMuted={this.state.muted}
							agentCameraOff={this.state.blinded}
							agentMinimized={this.state.minimized}
							agentFullScreen={this.props.videoCallFullScreen}
							onEnableAudio={this.handleEnableAudio}
							onMuteAudio={this.handleMuteVideo}
							onStartCall={this.handleStartCall}
							onHangupCall={this.handleHangUpCall}
							onEnableVideo={this.handleEnableVideo}
							onDisableVideo={this.handleDisableVideo}
							onToggleFullScreen={this.handleFullScreenVideo}
							onMinimizeVideo={this.handleMinVideo}
							callInProgress={this.props.videoCallInProgress}
							allowAgentVideoCall={this.props.allowAgentVideoCall}
							onHandleRecord={this.handleRecording}
							allowVideoRecording={this.props.allowVideoRecording}
							allowAutoVideoRecording={this.props.allowAutoVideoRecording}
							isAgentVideoCallShowing={this.props.isAgentVideoCallShowing}
							isClientRejectVidCall={this.props.clientRejectVidCall}
							isCalling={this.state.calling}
							isRecording={this.state.recording}
							allowScreenSharing={this.props.allowScreenSharing}
							allowCoBrowsing={this.props.allowCoBrowsing}
							onSelectBackground={this.handleVideoBg}
							onCoBrowseMode={this.props.showCoBrowseFrame}
							onScrollDown={this.handleScrollDown}
							onScrollUp={this.handleScrollUp}
							onDrawingMode={this.handleOnDrawingMode}
							highlightColor={this.state.highlightColor}
							onUndoHighlight={this.handleUndoHighlight}
							onClearHighlight={this.handleClearHighlight}
							onSelectHighlightColor={this.handleHighlightColor}
							mouseActive={this.props.agentMouseControl}
							keyboardActive={this.props.agentKeyboardControl}
							onMouseToggle={this.handleMouseActive}
							onKeyboardToggle={this.handleKeyboardActive}
							onCameraStart={this.handleCamStart}
						/>
					</div>
					<div className="header-bottom" hidden={this.state.minimized && !this.props.videoCallFullScreen}>
						<div className="info-section">
							<span className={classNames("meeting-info", {online: this.props.videoCallInProgress})}>{I("Meeting ID:")} </span>
							<span className="meeting-sessionId">{meetingId}</span>
							<span style={{padding: "0 5px"}}>|</span>
							<span className="meeting-user-title">{I("Participants:")} </span>
								<span> {clientName} <i hidden={!clientMute} className="icon-microphone-mute"></i>, {agentName}</span>
						</div>
						{ENABLE_TIMER &&
							<AgentVidTimer sessionId={this.props.info.sessionId} videoCallInProgress={this.props.videoCallInProgress} recording={this.props.recording} onStop={this.handleStopTimer} />
						}
					</div>
				</div>
				<div className={classNames("cention-video-thumbnail-container", {vidsThumbNailsWrapperOnFs: this.props.videoCallFullScreen})}>
					<div className={classNames("cention-video-agent-vids", {minimized: (this.state.minimized && !this.props.videoCallFullScreen), "hide-thumb": !this.state.showThumbnail})}>
						<div hidden={this.state.cameraStarted} style={{margin: "auto"}}>{I("Click camera icon to switch on webcam")}</div>
						{/*remote video */}
						<div id="remoteAgentVideoWrapper"  className={"common " + remoteVidWrapper}>
							{clientOffWebCam &&
								<AvatarOverlay className={remoteVidCamDisable} avatar={clientAvatar} hide={!videoCallInProgress} onClick={(e) => this.makeFullScreen(FS_REMOTE)}/>
							}
							<video id="CentionChatVideoFrameRemoteAgent" label="testClient" className={remoteVid} autoPlay playsInline onClick={(e) => this.makeFullScreen(FS_REMOTE)}></video>
							<div className="user-name" hidden={this.state.minimized}>
								<span title={clientName} className="text-wrap ellipsis">
									{clientName}
								</span>
								<i hidden={!clientMute} className="icon-microphone-mute"></i>
							</div>
							<VideoToolbar
								className="cention-video-toolbar"
								sessionId={this.props.info.sessionId}
								hide={!this.state.minimized}
								agentMuted={this.state.muted}
								agentCameraOff={this.state.blinded}
								agentMinimized={this.state.minimized}
								agentFullScreen={this.props.videoCallFullScreen}
								onEnableAudio={this.handleEnableAudio}
								onMuteAudio={this.handleMuteVideo}
								onStartCall={this.handleStartCall}
								onHangupCall={this.handleHangUpCall}
								onEnableVideo={this.handleEnableVideo}
								onDisableVideo={this.handleDisableVideo}
								onToggleFullScreen={this.handleFullScreenVideo}
								onMinimizeVideo={this.handleMinVideo}
								callInProgress={this.props.videoCallInProgress}
								onHandleRecord={this.handleRecording}
								allowVideoRecording={this.props.allowVideoRecording}
								allowAutoVideoRecording={this.props.allowAutoVideoRecording}
								isAgentVideoCallShowing={this.props.isAgentVideoCallShowing}
								isClientRejectVidCall={this.props.clientRejectVidCall}
								isCalling={this.state.calling}
								isRecording={this.state.recording}
								allowScreenSharing={this.props.allowScreenSharing}
								allowCoBrowsing={this.props.allowCoBrowsing}
								onSelectBackground={this.handleVideoBg}
								onCoBrowseMode={this.props.showCoBrowseFrame}
								onScrollDown={this.handleScrollDown}
								onScrollUp={this.handleScrollUp}
								onDrawingMode={this.handleOnDrawingMode}
								highlightColor={this.state.highlightColor}
								onUndoHighlight={this.handleUndoHighlight}
								onClearHighlight={this.handleClearHighlight}
								onSelectHighlightColor={this.handleHighlightColor}
								mouseActive={this.props.agentMouseControl}
								keyboardActive={this.props.agentKeyboardControl}
								onMouseToggle={this.handleMouseActive}
								onKeyboardToggle={this.handleKeyboardActive}
								onCameraStart={this.handleCamStart}
							/>
						</div>
						{/*local video*/}
						<div id="localAgentVideoWrapper" className={"common " + localVidWrapper}>
							<video id="CentionChatVideoFrameLocalAgent" label="testAgent" onPlaying={this.onPlaying} className={localVid} autoPlay playsInline muted onClick={(e) => this.makeFullScreen(FS_LOCAL)}></video>
							<canvas hidden id="agentVidCanvas" onClick={(e) => this.makeFullScreen(FS_LOCAL)}
								className={classNames("bg-canvas-blur",
									{cVidFullScreen: (this.state.currentFullScreen === FS_LOCAL && this.props.videoCallFullScreen)}
								)}></canvas>
							{this.state.blinded &&
								<AvatarOverlay className={localVidCamDisable} avatar={agentAvatar} hide={!videoCallInProgress} onClick={(e) => this.makeFullScreen(FS_LOCAL)}/>
							}
							<div id="bg-container" hidden
								className={classNames("bg-container",
								{cVidFullScreen: (this.state.currentFullScreen === FS_LOCAL && this.props.videoCallFullScreen)}
							)}>
								<VideoWrapperWithBackground
									background={this.state.selectedVideoBg}
									currentFS={this.state.currentFullScreen}
									fullscreen={this.props.videoCallFullScreen}
									selfExtractionDone={this.props.videoExtractionDone}
								/>
								<canvas id="canvasBgAgent" onClick={(e) => this.makeFullScreen(FS_LOCAL)}
									className={classNames(
										{"bg-canvas": !this.props.videoCallFullScreen || (this.state.currentFullScreen !== FS_LOCAL && this.props.videoCallFullScreen)},
										{"bg-canvas-mask": (this.state.currentFullScreen === FS_LOCAL && this.props.videoCallFullScreen)},
										{cVidFullScreen: (this.state.currentFullScreen === FS_LOCAL && this.props.videoCallFullScreen)}
									)}
									>
								</canvas>
							</div>
							<div className="user-name"><span title={agentName} className="text-wrap ellipsis">{agentName}</span></div>
						</div>
						{/*local agent screen sharing*/}
						<div id="CentionChatRemoteDisplay" className={isClientSharingScreen && isAgentSharingScreen ? "mixed" : "single"}>
							<div id="CentionChatAgentScreenShareWrapper" className={"common " + localShareWrapper}
							hidden={!this.props.isAgentSharingScreen} onClick={(e) => this.makeFullScreen(FS_LOCAL_DISPLAY)}>
								<div className={"overlay"}>
									<video id="agent-display-view" className={localShareVid} style={videoStyleOverlay} autoPlay loop playsInline muted></video>
									<div className="share-info">{I("You are sharing your screen")}</div>
								</div>
								<div className="user-name">
									<span title={agentName} style={{marginRight: "5px"}} className="text-wrap ellipsis">{agentName}</span>
									<i className="icon-sharescreen"></i>
								</div>
							</div>
						</div>
						{/*remote client screen sharing or cobrowsing */}
						{/* TODO: Don't implement agent drawing , just make the effect happen on client side.
							Below lines of code will be removed
						*/}
						{
							/*this.state.showDrawingCanvas &&
								<DrawCanvas
									id="cobrowse-drawing"
									setRef={this.setCanvasRef}
									clientSelectedDisplay={this.props.clientSelectedDisplay}
									onMouseDown={this.handleMouseDown}
									onMouseUp={this.handleMouseUp}
									onMouseMove={this.handleMouseMove}
									brushColor={this.state.highlightColor} />
							*/
						}
						<div id="CentionChatRemoteDisplay" className={isClientSharingScreen && isAgentSharingScreen ? "mixed" : "single"}>
							<div id="CentionChatClientScreenShareWrapper" className={"common " + remoteShareWrapper}
							hidden={!this.props.isClientSharingScreen && !this.props.showCoBrowseFrame} onClick={(e) => this.makeFullScreen(FS_REMOTE_DISPLAY)}>
								<ClientScreenVideo
									id="client-display-view"
									className={remoteShareVid}
									onPlay={this.handleClientVidPlay}
									coBrowseMode={this.props.showCoBrowseFrame}
									clientSelectedDisplay={this.props.clientSelectedDisplay}
									fullScreenMode={this.state.currentFullScreen === FS_REMOTE_DISPLAY && this.props.videoCallFullScreen}
									mouseControl={this.props.agentMouseControl}
									keyboardControl={this.props.agentKeyboardControl}
									highlightMode={this.state.showDrawingCanvas}
									onMouseMove={this.handleMouseMove}
									onMouseDown={this.handleMouseDown}
									onMouseClick={this.handleMouseClick}
									onKeyDown={this.handleAgentKeyDown}
									onMouseUp={this.handleMouseUp}>
								</ClientScreenVideo>
								<StyledCloseIcon className="icon-times" rightPos={closeBtnRightPos} hidden={this.props.videoCallFullScreen} onClick={this.handleCloseRemoteShare} />
								<div className="user-name" hidden={this.state.minimized}>
									<span title={clientName} style={{marginRight: "5px"}} className="text-wrap ellipsis">{clientName}</span>
									<i className="icon-sharescreen"></i>
								</div>
								<StyledLoadingIcon hidden={!this.state.clientVidLoading} className="fa fa-spinner fa-spin" aria-hidden="true"/>
								<VideoToolbar
									className="cention-video-toolbar"
									sessionId={this.props.info.sessionId}
									hide={!this.state.minimized}
									agentMuted={this.state.muted}
									agentCameraOff={this.state.blinded}
									agentMinimized={this.state.minimized}
									agentFullScreen={this.props.videoCallFullScreen}
									onEnableAudio={this.handleEnableAudio}
									onMuteAudio={this.handleMuteVideo}
									onStartCall={this.handleStartCall}
									onHangupCall={this.handleHangUpCall}
									onEnableVideo={this.handleEnableVideo}
									onDisableVideo={this.handleDisableVideo}
									onToggleFullScreen={this.handleFullScreenVideo}
									onMinimizeVideo={this.handleMinVideo}
									callInProgress={this.props.videoCallInProgress}
									onHandleRecord={this.handleRecording}
									allowVideoRecording={this.props.allowVideoRecording}
									allowAutoVideoRecording={this.props.allowAutoVideoRecording}
									isAgentVideoCallShowing={this.props.isAgentVideoCallShowing}
									isClientRejectVidCall={this.props.clientRejectVidCall}
									isCalling={this.state.calling}
									isRecording={this.state.recording}
									allowScreenSharing={this.props.allowScreenSharing}
									allowCoBrowsing={this.props.allowCoBrowsing}
									onSelectBackground={this.handleVideoBg}
									onCoBrowseMode={this.props.showCoBrowseFrame}
									onScrollDown={this.handleScrollDown}
									onScrollUp={this.handleScrollUp}
									onDrawingMode={this.handleOnDrawingMode}
									highlightColor={this.state.highlightColor}
									onSelectHighlightColor={this.handleHighlightColor}
									onUndoHighlight={this.handleUndoHighlight}
									onClearHighlight={this.handleClearHighlight}
									mouseActive={this.props.agentMouseControl}
									keyboardActive={this.props.agentKeyboardControl}
									onMouseToggle={this.handleMouseActive}
									onKeyboardToggle={this.handleKeyboardActive}
									onCameraStart={this.handleCamStart}
								/>
							</div>
						</div>
						{/*end remote shared */}
					</div>
				</div>
				{ENABLE_RINGER &&
					<CallRinger play={this.state.calling || this.props.isClientCalling} />
				}
			</div>
		</Draggable>
		)
	}
}

const StyledVideoInfo = styled.div`
	background-color: #f5eef8;
	padding: 0px 0px 0px 14px;
	display: inline-block;
	font-size: 12px;
	width: 100%;
`
const StyledPlayIcon = styled.div`
	position: absolute;
	width: 25px;
	height: 25px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	line-height: 1;
	text-align: center;
	color: #eaeaea;
	transition: color, background-color .2s ease;
	&:before {
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		font-family: "Font Awesome 5 Free";
		font-weight: 900;
		font-size: 25px;
		opacity: 0.5;
		content: '${props => props.playing ? "" : "\f04b"}';
	}
`
const StyledDownloadIcon = styled.i`
	padding: 5px;
	&:before {
		color: #FFF;
	}
`
export class AgentRecordedVids extends React.PureComponent {
	constructor(props){
		super(props);
		this.play = this.play.bind(this);
		this.stop = this.stop.bind(this);
		this.state = {
			playing: false
		}
	}
	play() {
		let recordedVideo = this.recVid;
		recordedVideo.play();
		this.setState({ playing: true });
	}
	stop() {
		let recordedVideo = this.recVid;
		recordedVideo.pause();
		recordedVideo.currentTime = 0;
		this.setState({ playing: false });
	}
	render() {
		const vidWrapperStyle = {
			width: "230px",
			height: "173px",
			display: "inline-block",
			position: "relative",
			marginRight: "5px",
			marginLeft: "25px",
		}
		const vidRecordedStyle = {
			width: "100%",
			maxWidth: "230px",
			height: "173px",
			background: "#000000",
			outline: "none"
		}
		const vidMetaStyle = {
			float: "right",
			background: "#0c87f7"
		}
		let duration = new Date(this.props.duration * 1000).toISOString().substr(11, 8);
		let who = (this.props.isAgent ? I("Agent") : I("Client"));
		let infoText = I("{WHO} recorded call").replace('{WHO}', who);
		return(
			<div className="cention-video-recorded" style={vidWrapperStyle}>
				<video id="cention-recorded" controls={this.state.playing ? true : false} preload="metadata" playsInline ref={vid => {this.recVid = vid}} src={this.props.src} style={vidRecordedStyle}></video>
				<StyledPlayIcon playing={this.state.playing} onClick={this.play}></StyledPlayIcon>
				<StyledVideoInfo>
					<div style={{float: "left", width: "85%", padding: "3px 0px 0px 0px"}}>
						<span style={{float: "left"}}>{infoText}</span><span style={{float: "right"}}>{duration}
						</span>
					</div>
					<div style={vidMetaStyle}>
						<a href={this.props.src} target="_blank">
							<StyledDownloadIcon className="icon-download" />
						</a>
						<span hidden>{this.props.size}</span>
					</div>
				</StyledVideoInfo>
			</div>
		)
	}
}

class ChatErrandBase extends React.PureComponent {
	constructor(props) {
		super(props);

		this.nSeenMessages = 0;
		this.atBottom = true;
		this.key = 0;

		this.onScroll = this.onScroll.bind(this);
		this.scrollToBottom = this.scrollToBottom.bind(this);
	}

	getAgent(id) {
		// returning null here causes SystemMessage to use the stored
		// names in the chat message itself (can be outdated).
		return null;
	}

	atTop() {
		return this.el && this.el.scrollTop == 0;
	}
	isAtBottom() {
		return this.el && this.el.scrollHeight - this.el.scrollTop <= this.el.clientHeight + 150;
	}
	onScroll() {
		this.hideScrollDownButton();
		if (this.isAtBottom()) {
			this.atBottom = true;
			this.nSeenMessages = this.props.chat.messages.length;
		} else {
			if (this.atBottom) {
				this.atBottom = false;
			}
		}
	}
	scrollToBottom() {
		this.el.scrollTop = this.el.scrollHeight;
		this.hideScrollDownButton();
	}
	hideScrollDownButton() {
		if (!this.scrollDownButton) {
			return;
		}
		this.scrollDownButton.style.display = 'none';
	}

	handleMobileClose = () => {
		this.props.onToggleErrandOptionMobile(false);
	}

	handleToggleChatRating = () => {
		this.props.onToggleChatRatingBox(true);
		this.props.onToggleErrandChatSidePanel(true);
	}
	handleToggleVisitorPath = () => {
		this.props.onToggleVisitorPath(true);
		this.props.onToggleErrandChatSidePanel(true);
	}

	handleToggleICSidepanel = () => {
		this.props.onToggleRightPanel(true);
	}

	handleMakeSipCall = () => {
		if(this.props.chat && this.props.chat.enableCallbackRequest) {
			if(this.props.onMakeSIPCall) {
				this.props.onMakeSIPCall(this.props.chat.callbackNumber);
			}
		}
	}
	render() {
		const {
			chat,
			hasIC,
			showCollaboratePanel,
			showICPanel,
			onCloseCollaboratePanel,
			onOpenCollaboratePanel,
			showAIAnswerPanel,
			onExpandAIAnswer,
			onMaximizePanel,
			onCloseAIAnswerPanel,
			showKBLibraryPanel,
			onCloseKBLibraryPanel,
			onExpandKBLibrary,
			isKBPanelExpanded,
			libraryAnswerShown,
			onBackToMainKBList,
			expandKnowledgeBasePanel,
			...p
		} = this.props;
		const { collaboration } = chat.errand.data;
		const eid = chat.errand.id;
		let errorNotice;
		if (chat.error) {
			errorNotice = <div className={"error"}>
				{I("An error occurred while loading this chat: {ERROR}").replace("{ERROR}", chat.error)}
			</div>;
		}

		if (!this.el || this.atBottom) {
			this.nSeenMessages = chat.messages.length;
		}

		let scrollTop = this.el?this.el.scrollTop:0
		, scrolledToBottom = !this.el || this.atBottom
		, nNewMessages = chat.messages.length-this.nSeenMessages
		, newMessageNotice
		;
		if (this.el && !(scrolledToBottom || chat.hasNewMessageFromSelf) && nNewMessages) {
			// this ensures that it is re-shown after becoming
			// invisible after scrolling a bit.
			this.key = (this.key+1) % 2;
			newMessageNotice = <button key={this.key} className="btn-blue"
				style={{
					position: 'absolute',
					top: (scrollTop + 5) + 'px',
					right: '5px',
					zIndex: 1,
					display: 'block',
					fontSize: '13px'
				}}
				onClick={this.scrollToBottom}
				ref={(el)=>{this.scrollDownButton=el}}
				>
				{I("New message received ({COUNT})").replace('{COUNT}', nNewMessages)}
			</button>;
		}

		let chatMessagesHeight = "calc(100vh - 273px)", msgRightPanelHeightBody = "calc(100vh - 321px)";
		if(isDesktopMode()){
			chatMessagesHeight = "calc(100vh - 283px)";
			msgRightPanelHeightBody = "calc(100vh - 333px)";
		}
		if(!p.showHeader) {
			chatMessagesHeight = "calc(100vh - 180px)";
			msgRightPanelHeightBody = "calc(100vh - 227px)";
		}
		let showAgentRating = false;
		if(features['chat.optional-satisfaction-agent-to-client']){
			showAgentRating = true;
		}
		let clientSatisfaction = null;
		let ccs = p.clientChatSatisfaction;
		let chatSM = chat.satisfactionMeter;

		if( typeof chatSM !== 'undefined' && chatSM !== null && chatSM.rating !== "") {
			clientSatisfaction = <ClientSatisfaction clientChatSatisfaction={ccs} csmData={chatSM} showAgentRating={showAgentRating} {...this.props} />;
		}
		let ICPanelStyle = {}, showICTriggerBtn = false;
		if (hasIC) {
			showICTriggerBtn = true;
			if (showICPanel) {
				ICPanelStyle = {
					height: msgRightPanelHeightBody,
				}
			}
		}
		let isClosed = false;
		if (chat) {
			//or maybe remove dynamic height?
			chatMessagesHeight = "100%";
			if(chat.dead || chat.expired) {
				isClosed = true;
			}
		}
		return <div className="wrap-options-page">
				{
					!isClosed &&
						(features['chat.video-call'] || features['chat.agent-video-call'] || features['chat.agent-video-co-browse'] || features['chat.agent-video-screen-sharing']) &&
							<AgentVideoFrameCtnr
								id={"agentVideoChat" + chat.sessionId}
								info={chat}
								hide={!chat.videoCall}
								clientAvatar={p.clientAvatar}
							/>
				}
					<div style={{height: chatMessagesHeight}} className={"errand-box-chatmessages" + (chat.dead ? " chat-dead" : "") + (p.internatChatWindow ? " internal-chat-open" : "")}
						ref={el=>{this.el=el}}
						onScroll={this.onScroll}
					>
						<div className="sidepanel-trigger-container">
							<SidepanelTriggerBtn
								className={"rating"}
								onClick={this.handleToggleChatRating}
								icon={"icon-star"}
								text={I("Rate the chat")}
							/>
							<SidepanelTriggerBtn
								className={"visitor"}
								hidden={!chat.visitorPaths}
								onClick={this.handleToggleVisitorPath}
								icon={"fas fa-location-arrow"}
								text={I("Visitor path")}
							/>
							<InternalCommentTriggerButton
								hidden={!showICTriggerBtn}
								onClick={this.handleToggleICSidepanel}
							/>
							<CollaborationTriggerButton
								hidden={!collaboration ||showCollaboratePanel}
								onClick={onOpenCollaboratePanel}
							/>
						</div>
						{showICPanel &&
							<RightBarMsgPanel
								eid={eid}
								customStyle={ICPanelStyle}
								notes={chat.errand.notes}
								msgType={MT_NOTE}
								attachmentType={AT_SAVED}
								currentEditNote={p.currentEditNote}
								lockEditErrandNote={p.lockEditErrandNote}
								onEditNoteClick={p.onEditNoteClick}
								onDeleteNoteClick={p.onDeleteNoteClick}
								onClose={p.onToggleRightPanel}
							/>
						}
						<ChatPanelWrapper hidden={!showCollaboratePanel ||!collaboration}>
							<CollaborationRightSidepanel
								onClosePanel={onCloseCollaboratePanel}
								show={true}
							>
								<CollabContentsCtnr eid={eid} />
							</CollaborationRightSidepanel>
						</ChatPanelWrapper>
						<ChatSidePanel
							clientChatSatisfaction={ccs}
							csmData={chatSM}
							showAgentRating={showAgentRating}
							chatMessagesHeight={chatMessagesHeight}
							{...this.props}
						/>
						<AIAnswerRightSidepanel
							show={showAIAnswerPanel}
							onExpandAIAnswer={onExpandAIAnswer}
							onMaximizePanel={onMaximizePanel}
							onClosePanel={onCloseAIAnswerPanel}
							>
							<RewriteAnswerBoxContentCtnr eid={eid} isChat={true} />
						</AIAnswerRightSidepanel>
						<KBLibraryRightSidepanel
							show={showKBLibraryPanel}
							onClosePanel={onCloseKBLibraryPanel}
							onExpandKBLibrary={onExpandKBLibrary}
							isKBPanelExpanded={isKBPanelExpanded}
							showBackToButton={libraryAnswerShown}
							onBackToMainKBList={onBackToMainKBList}
							isExpanded={expandKnowledgeBasePanel}
						>
							<KnowledgeBaseCtnr forErrand={true} forChat={true} />
						</KBLibraryRightSidepanel>
						<ChatMessages
							agentList={p.agentList}
							canTranslate={p.canTranslate}
							chat={chat}
							clientAvatar={p.clientAvatar}
							currentReply={p.currentReply}
							doScrollToBottom={scrolledToBottom ||
								chat.hasNewMessageFromSelf ||
								chat.hasNewMessageFromClient
							}
							innerRef={this.el}
							interfaceLang={p.interfaceLang}
							fromCust={p.fromCust}
							fromLang={p.fromLang}
							getAgent={this.getAgent}
							lockEditErrandNote={p.lockEditErrandNote}
							onDeleteNoteClick={p.onDeleteNoteClick}
							onEditNoteClick={p.onEditNoteClick}
							onHandleEmoticon={p.onHandleChatEmoticon}
							onToggleExternalData={p.onToggleExternalData}
							onToggleShow={p.onToggleShow}
							onTranslation={p.getTranslation}
							show={p.show}
							showAgentRating={showAgentRating}
							showChatSidePanel={p.showChatSidePanel ||
								showICPanel ||
								showCollaboratePanel
							}
							showExternalData={p.showExternalData}
							showAIAnswerPanel={showAIAnswerPanel}
							showKBLibraryPanel={showKBLibraryPanel}
							showReply={p.showReply}
							toLang={p.toLang}
						/>
						{errorNotice}
						{newMessageNotice}
					</div>
					<ErrandBoxReply
						hideReply={false}
						currentReply={p.currentReply}
						onToggleExpand={p.onToggleReply}
						expandReply={p.showReply}
						state={p.currentState.state}
						chat={chat}
						mobileView={p.mobileView}
						onToggleErrandOptionMobile={p.onToggleErrandOptionMobile}
						mobile={p.mobile}
						isClosed={isClosed}
						onMakeCall={this.handleMakeSipCall}
						rightSidePanelOpen={showAIAnswerPanel || showKBLibraryPanel || showICPanel || showCollaboratePanel}
					/>
				</div>;
	}
};

const ChatErrandRaw = ({ hasCollaboration, showRightPanel, ...props }) => {
	const [collaboratePanel, setCollaboratePanel] = useState(false)
	const { chat: { errand: { notes } }, currentReply } = props
	const hasIC = notes && notes.length > 0
	const showICPanel = hasIC && showRightPanel
	return (
		<ChatErrandBase
			hasIC={hasIC}
			showCollaboratePanel={collaboratePanel &&
				!showICPanel &&
				hasCollaboration
			}
			showICPanel={showICPanel}
			onOpenCollaboratePanel={useCallbackWithValue(true, setCollaboratePanel)}
			onCloseCollaboratePanel={useCallbackWithValue(false, setCollaboratePanel)}
			{...props}
		/>
	)
}

export const ChatErrand = composeWithDisplayName(
	'ChatErrand',
	withProps(({ chat }) => ({ hidden: !chat })),
	withUnmountWhenHidden,
	memo
)(ChatErrandRaw)

export class ClientScreenVideo extends React.Component {
	constructor(props) {
		super(props);
		this.handleMouseMove = this.handleMouseMove.bind(this);
		this.handleMouseDown = this.handleMouseDown.bind(this);
		this.handleMouseUp = this.handleMouseUp.bind(this);
		this.handleMouseClick = this.handleMouseClick.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.resetEvents = this.resetEvents.bind(this);
		this.getVideoSize = this.getVideoSize.bind(this);
		this.getCursorPosition = this.getCursorPosition.bind(this);
		this.handlePlay = this.handlePlay.bind(this);

		this.clientVidRef = React.createRef();

		this.state = {
			mousedown: false
		}
	}
	componentDidUpdate() {
		if(this.clientVidRef) {
			if(this.props.coBrowseMode && this.props.fullScreenMode) {
				let vidElem = this.clientVidRef.current;
				if(this.props.mouseControl || this.props.highlightMode || this.props.keyboardControl) {
					vidElem.addEventListener("mousemove", this.handleMouseMove);
					vidElem.addEventListener("mousedown", this.handleMouseDown);
					vidElem.addEventListener("mouseup", this.handleMouseUp);
					vidElem.addEventListener("click", this.handleMouseClick);
					document.addEventListener("keydown", this.handleKeyDown);
				} else if (!this.props.mouseControl || !this.props.highlightMode) {
					vidElem.removeEventListener("mousemove", this.handleMouseMove);
					vidElem.removeEventListener("mousedown", this.handleMouseDown);
					vidElem.removeEventListener("mouseup", this.handleMouseUp);
					vidElem.removeEventListener("click", this.handleMouseClick);
				} else if (!this.props.keyboardControl) {
					document.removeEventListener("keydown", this.handleKeyDown);
				}
			} else {
				this.resetEvents();
			}
		}
	}
	componentWillUnmount() {
		this.resetEvents();
	}
	getVideoSize(vid) {
		var cs = getComputedStyle(vid);
		var w = parseInt(cs.getPropertyValue("width"), 10);
		var h = parseInt(cs.getPropertyValue("height"), 10);
		return {width: w, height: h};
	}
	getCursorPosition(e) {
		//FIXME: This seems only accurate when 100% view (not zoomed/unzoomed)
		let vidElem = this.clientVidRef.current;
		var size = this.getVideoSize(vidElem);
		var scaleX = vidElem.videoWidth / size.width;
		var scaleY = vidElem.videoHeight / size.height;
		var rect = vidElem.getBoundingClientRect();  // absolute position of element
		var x = ((e.clientX - rect.left) * scaleX + 0.5)|0;
		var y = ((e.clientY - rect.top ) * scaleY + 0.5)|0;
		return {x: x, y: y};
	}
	handleMouseMove(e) {
		let pos = this.getCursorPosition(e);
		var obj = {"x" : pos.x, "y": pos.y, "drag": this.state.mousedown};
		this.props.onMouseMove(obj);
	}
	handleMouseDown(e) {
		this.setState({mousedown: true});

		let pos = this.getCursorPosition(e);
		var obj = {"x" : pos.x, "y": pos.y};
		this.props.onMouseDown(obj);
	}
	handleMouseUp(e) {
		this.props.onMouseUp();
		this.setState({mousedown: false});
	}
	handleMouseClick(e) {
		let pos = this.getCursorPosition(e);
		var obj = {"x" : pos.x, "y": pos.y}
		this.props.onMouseClick(obj);
	}
	handleKeyDown(e) {
		this.props.onKeyDown(e.key);
	}
	handlePlay() {
		this.props.onPlay();
	}
	resetEvents() {
		if(this.clientVidRef) {
			let vidElem = this.clientVidRef.current;
			vidElem.removeEventListener("mousemove", this.handleMouseMove);
			vidElem.removeEventListener("mousedown", this.handleMouseDown);
			vidElem.removeEventListener("mouseup", this.handleMouseUp);
			vidElem.removeEventListener("click", this.handleMouseClick);
			document.removeEventListener("keydown", this.handleKeyDown);
		}
	}
	render() {
		return <video
			ref={this.clientVidRef}
			id={this.props.id}
			className={this.props.className}
			onPlay={this.handlePlay}
			autoPlay loop playsInline muted>
		</video>
	}
};
