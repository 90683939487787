import React from 'react';
import TagItemList from './TagItemList';
import { I } from '../../common/v5/config.js';

export default class Classification extends React.Component {
	constructor(props) {
		super(props);
		this.handleAddTag = this.handleAddTag.bind(this);
		this.handleDeleteTag = this.handleDeleteTag.bind(this);
	}
	handleAddTag(value) {
		this.props.onAddTag(value, this.props.taggingErrandId,
			this.props.isSameCurrentErrand, this.props.manual);
	}
	handleDeleteTag(index) {
		this.props.onDeleteTag(index, this.props.taggingErrandId,
			this.props.isSameCurrentErrand, this.props.manual);
	}
	render() {
		const p = this.props;
		if(!p.enable) {
			return null;
		}
		let title;
		if (p.manual || p.isSameCurrentErrand) {
			title = I('You have not chosen a classification for this errand.');
		} else {
			title = I('Errand {DISPLAY_ID} in area {AREA_NAME}')
				.replace('{DISPLAY_ID}', p.displayId)
				.replace('{AREA_NAME}', p.areaName);
		}
		return <TagItemList show={true} eid={p.eid} onCancel={p.onCancel}
			onConfirm={p.onConfirm} classificationTitle={title} tags={p.tags}
			classificationMessage={I("You can navigate the list by pressing the up and down arrows and select a classification by pressing space.")}
			classificationSubject={p.subject} selectedTags={p.selectedTags}
			classificationIconicSubject={p.iconicSubject}
			hideAddErrandLink={true} condition={p.condition} tagsFetchReady={p.tagsFetchReady}
			displayId={p.displayId} areaName={p.areaName} backDropDisable={p.backDropDisable}
			onAddTag={this.handleAddTag} onDeleteTag={this.handleDeleteTag} />;
	}
};
