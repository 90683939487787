import React, { PureComponent } from 'react';
import { mapProps } from 'recompose';
import {
	InfiniteLoader
	, List
} from 'react-virtualized';
import {
	Popover
	, PopoverHeader
	, PopoverBody
} from 'reactstrap';
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';
import { canAcquire } from '../../common/v5/helpers';
import { withTypeOnClick } from '../../reactcomponents/hocs';
import Anchor from '../../reactcomponents/Anchor';
import { UngroupableErrand, NoDataBriefErrand } from './BriefErrand';
import SquareCheckbox from '../../reactcomponents/SquareCheckbox';
import { I } from '../../common/v5/config';
import {
	ACQ_ERD_HISTORY,
	ACQ_ERD_OTHER,
	ACQ_ERD_SELECTED_TOP,
	ACQ_ERD_SELECTED_BOTTOM,
	ACQ_ERD_MY,
	ACQ_ERD_LINKED,
	AET_ACQUIRE,
	AET_HISTORIES,
	AET_OPEN,
	CTX_REVIEW,
	OTHER_CONTACTS_FETCH_SIZE,
	AET_OPENTYPE_OWN,
	AET_OPENTYPE_MYERRANDS,
	AET_LINKED,
	MESSAGE_MUST_SELECT_ERRAND
} from '../../common/v5/constants';
import Feature from '../../components/v5/Feature';

const OneErrandBorder = ({
	children
	, className
	, withMouseEvents
	, ...props
}) => {
	if (!withMouseEvents) {
		delete props.onMouseEnter;
		delete props.onMouseLeave;
	}
	return (
		<div {...props}
			className={classNames("single-acquire-errand", className)}
		>
			{children}
		</div>
	);
}

const defErrandListHeight = 250
	, defShorterHeight = 176
	, defErrandListWidth = 402
	, defButtonsHeight = 46
	, defSingleRowHeight = 95
	, defPopupHeight = 395
	, defPopupAcquiredHeight = 295
	;
class OneAcquireErrand extends PureComponent {
	constructor(props) {
		super(props);
		this.handleSelect = this.handleSelect.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleMouseOver = this.handleMouseOver.bind(this);
		this.handleMouseOut = this.handleMouseOut.bind(this);
		this.handleRef = this.handleRef.bind(this);
	}
	componentWillUnmount() {
		this.props.onMouseOverOut(null);
	}
	handleSelect(me, v) {
		const p = this.props;
		p.onSelect(p.type, me, v);
	}
	handleClick(e) {
		this.props.onClick(
			this.props.me
			, this.props.chat
			, this.props.currentErrand
			, this.props.ocId
			, this.props.data
		);
	}
	handleMouseOver(e) {
		this.props.onMouseOverOut(this.childRef, this.props.me);
	}
	handleMouseOut(e) {
		this.props.onMouseOverOut(null);
	}
	handleRef(ref) {
		// NOTE: the reason to break the encapsulation here is because
		// reactstrap require target element and using child ref can position
		// the popover closer to the child element.
		this.childRef = ref;
	}
	render() {
		const {
				currentContext
				, type
				, ...p
			} = this.props
			, data = p.data
			;
		if(!data) {
			return <OneErrandBorder style={p.style}>
					<NoDataBriefErrand me={p.me} onReload={p.onReloadErrand} />
				</OneErrandBorder>;
		}
		const agentId = data.data.agentId
			, sameCurrent = p.currentErrand && p.currentErrand === data.id
			;
		let noSelect = !canAcquire(p.myAgentId, agentId, data.data)
			&& !(currentContext === CTX_REVIEW
				&& (type === ACQ_ERD_SELECTED_TOP
					|| type === ACQ_ERD_SELECTED_BOTTOM));
		if (!noSelect && sameCurrent) {
			// do not allow to select self or deleted errand or closed
			// errand
			noSelect = true;
		}
		if (features['link-errand']) {
			if (p.dataType && p.dataType == AET_LINKED) {
				noSelect = false
			}
		}
		return (
			<OneErrandBorder
				style={p.style}
				// purposely leave the mounse events code attached and control
				// with this single flag as probably future can be useful.
				withMouseEvents={false}
				onMouseEnter={this.handleMouseOver}
				onMouseLeave={this.handleMouseOut}
			>
				<UngroupableErrand errand={data} me={p.me} select={p.checked}
					onSelect={this.handleSelect} onClick={this.handleClick}
					noSelect={noSelect} noNotice={true}
					refCallback={this.handleRef}
					currentOpened={p.ocId} />
			</OneErrandBorder>
		);
	}
}

const ACQ_ERRAND_CONV_SCROLL = 'aquired-errands-conversations rel-scroll';

class AcquireListBase extends PureComponent {
	constructor(props) {
		super(props);
		this.rowRenderer = this.rowRenderer.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
	}
	handleSelect(type, me, v) {
		this.props.onSelect(type, me, v);
	}
	rowRenderer({
			key,         // Unique key within array of rows
			index,       // Index of row within collection
			isScrolling, // The List is currently being scrolled
			isVisible,   // This row is visible within the List (eg it is not an overscanned row)
			style        // Style object to be applied to row (to position it)
		}) {
		const {
				type
				, errands
				, myAgentId
				, currentContext
				, currentErrand
				, ocId
				, chat
				, onOpenErrand
				, onMouseOverOut
				, onReloadErrand
				, data
				, dataType
			} = this.props
			, id = data.list[index]
			;
		let checked
			, sourceType
			, e
			;
		if (chat && currentErrand === id) {
			e = chat.errand;
		} else {
			e = errands[id];
		}
		if (data.opr && data.opr[id]) {
			checked = data.opr[id].selected;
		}
		if (data.type) {
			sourceType = data.type[id];
		} else {
			sourceType = type;
		}
		return (
			<OneAcquireErrand
				currentContext={currentContext}
				me={id}
				type={sourceType}
				key={key}
				data={e}
				checked={checked}
				onSelect={this.handleSelect}
				style={style}
				index={index}
				myAgentId={myAgentId}
				currentErrand={currentErrand}
				ocId={ocId}
				chat={chat}
				onClick={onOpenErrand}
				onMouseOverOut={onMouseOverOut}
				onReloadErrand={onReloadErrand}
				dataType={dataType}
			/>
		);
	}
	render() {
		let height, width;
		if (this.props.height) {
			height = this.props.height;
		} else {
			height = defErrandListHeight;
		}
		if (this.props.width) {
			width = this.props.width;
		} else {
			width = defErrandListWidth;
		}
		return <List
				className={classNames(
					ACQ_ERRAND_CONV_SCROLL
					, this.props.className
				)}
				onRowsRendered={this.props.onRowsRendered}
				ref={this.props.registerChild}
				width={width}
				height={height}
				rowCount={this.props.data.list.length}
				// this is need to trigger refresh when checkbox change
				list={this.props.data.list}
				errands={this.props.errands}
				selections={this.props.data.opr}
				rowHeight={defSingleRowHeight}
				rowRenderer={this.rowRenderer}
				noRowsRenderer={this.props.noRowsRenderer}
			/>;
	}
};

const noErrandStr = I("No errand");

function emptyErrand() {
	return noErrandStr;
	// return I('Fetching in progress...');
}

function withAcquireList(Component) {
	return props => <Component {...props} noRowsRenderer={emptyErrand} />;
}

const AcquireList = withAcquireList(AcquireListBase);

function withShorterHeight(Component) {
	return props => <Component {...props} height={defShorterHeight} />;
}

function withPopupShorterHeight(Component) {
	return props => <Component {...props} height={defPopupAcquiredHeight} />;
}

const ShortAcquireListBase = withShorterHeight(AcquireListBase);

const PopupShortAcquireListBase = withPopupShorterHeight(AcquireListBase);

const ShortAcquireList = withAcquireList(ShortAcquireListBase);

class AcquireErrandList extends PureComponent {
	constructor(props) {
		super(props);
		this.isRowLoaded = this.isRowLoaded.bind(this);
		this.loadMoreRows = this.loadMoreRows.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
	}
	isRowLoaded({index}) {
		return !!this.props.data.list[index];
	}
	loadMoreRows({startIndex, stopIndex}) {
		console.log("dbg: acquire errand load more:", {startIndex, stopIndex});
		return this.props.onLoadMoreAcquireErrands(
			this.props.ocId
			, startIndex+this.props.data.filterSize
		);
	}
	handleSelectAll(v) {
		this.props.onSelectAll(v);
	}
	render() {
		const {
				data
				, show
				, showPopup
				, onLoadMoreAcquireErrands
				, infinite
				, noMore
				, tab
				, ...props
			} = this.props;
		if(!data || !data.ready) {
			return <div hidden={!show}>
					{I('Fetching in progress...')}
				</div>;
		}
		if(!infinite) {
			return <div hidden={!show}>
					<AcquireList data={data} {...props} />
				</div>;
		}
		let infiniteRowCount = data.list.length;
		if(!noMore) {
			infiniteRowCount += OTHER_CONTACTS_FETCH_SIZE;
		}

		let openTypeOwned = true
		let openTypeMyErrands = false
		let openType =  this.props.openType
		if (tab == AET_OPEN && openType == AET_OPENTYPE_OWN) {
			openTypeOwned = true
			openTypeMyErrands = false
		} else if (tab == AET_OPEN && openType == AET_OPENTYPE_MYERRANDS ) {
			openTypeOwned = false
			openTypeMyErrands = true
		}

		let checkBoxHidden = false
		return <div hidden={!show}>
				<div hidden={checkBoxHidden} className="checkDiv">
					<SquareCheckbox
						className="selectAll"
						label={I('Select all')}
						checked={props.selectAll}
						onClick={this.handleSelectAll}
						hidden={!(tab == AET_OPEN || tab == AET_LINKED)}
					/>
					<Feature tag="link-errand">
						<SquareCheckbox
							className="owned"
							label={I('Owned')}
							hidden={!(tab == AET_OPEN)}
							checked={openType==AET_OPENTYPE_OWN}
							selectectedOpenType={AET_OPENTYPE_OWN}
							data-qa-id={AET_OPENTYPE_OWN}
							name={AET_OPENTYPE_OWN}
							// this.props.onSwitchOpenType()
							//onClick={this.handleOwnedTab}
							onClick={this.props.onSwitchOpenType}
						/>
						<SquareCheckbox
							className="showMyErrands"
							label={I('Show My Errands')}
							hidden={!(tab == AET_OPEN)}
							checked={openType==AET_OPENTYPE_MYERRANDS}
							selectectedOpenType={AET_OPENTYPE_MYERRANDS}
							data-qa-id={AET_OPENTYPE_MYERRANDS}
							name={AET_OPENTYPE_MYERRANDS}
							//onClick={this.handleShowMyErrandsTab}
							onClick={this.props.onSwitchOpenType}
						/>
					</Feature>
				</div>
				<InfiniteLoader minimumBatchSize={OTHER_CONTACTS_FETCH_SIZE}
					isRowLoaded={this.isRowLoaded}
					loadMoreRows={this.loadMoreRows}
					rowCount={infiniteRowCount}>
					{({onRowsRendered, registerChild}) => (
					<AcquireList data={data} onRowsRendered={onRowsRendered}
						registerChild={registerChild} {...props}
					/>)}
				</InfiniteLoader>
			</div>;
	}
};

const InfiniteAcquire = props => (
	<AcquireErrandList {...props} infinite={true} />
);

const OpenErrands = props => (
	<InfiniteAcquire {...props} type={ACQ_ERD_OTHER} />
);

const MyErrands = props => (
	<InfiniteAcquire {...props} type={ACQ_ERD_MY} />
);

const LinkedErrands = props => (
	<InfiniteAcquire {...props} type={ACQ_ERD_LINKED} />
);

const noButtonHeight = defErrandListHeight + defButtonsHeight;

const AcquireHistories = props => (
	<InfiniteAcquire
		{...props}
		type={ACQ_ERD_HISTORY}
		height={noButtonHeight}
	/>
);

const hrStyle = {marginTop: "8px", marginBottom: "8px", borderColor: "#0c87f7"};

const HRLine = () => <hr style={hrStyle} />;

function selectFromOpenErrand() {
	return I("Please select and acquire from open errands tab.");
}

const BorderWithTop = ({show, hasTop, data, children, ...props}) => {
	if (!show) {
		return null;
	} else if (!hasTop) {
		return <div>{children}</div>;
	}
	return (
		<div>
			<ShortAcquireList
				{...props}
				className="top"
				type={ACQ_ERD_SELECTED_TOP}
				data={data}
			/>
			<HRLine />
			{children}
		</div>
	);
};

const SelectedErrands = ({show, data, ...props}) => {
	const { top, bottom } = data;
	let hasTop
		, height
		;
	if (top && top.ready && top.list && top.list.length) {
		hasTop = true;
	} else {
		height = noButtonHeight;
	}
	return (
		<BorderWithTop {...props} show={show} hasTop={hasTop} data={top}>
			<ShortAcquireListBase
				{...props}
				type={ACQ_ERD_SELECTED_BOTTOM}
				data={bottom}
				noRowsRenderer={selectFromOpenErrand}
				height={height}
			/>
		</BorderWithTop>
	);
};

const PopupSelectedErrands = ({show, data, ...props}) => {
	const { top, bottom } = data;
	let hasTop
		, height
		;
	if (top && top.ready && top.list && top.list.length) {
		hasTop = true;
	} else {
		height = noButtonHeight;
	}
	return (
		<BorderWithTop {...props} show={show} hasTop={hasTop} data={top}>
			<PopupShortAcquireListBase
				{...props}
				type={ACQ_ERD_SELECTED_BOTTOM}
				data={bottom}
				noRowsRenderer={selectFromOpenErrand}
				height={height}
			/>
		</BorderWithTop>
	);
};

const VisibleAnchorWithoutClick = ({hidden, ...props}) => <Anchor {...props} />;

const VisibleAnchor = withTypeOnClick(VisibleAnchorWithoutClick);

const TabTitle = ({notClickable, tab, ...props}) => {
	let Tag, className;
	if (!notClickable) {
		Tag = VisibleAnchor;
	} else {
		Tag = "span";
	}
	if (tab !== props.type) {
		className = "";
	} else {
		className = "current";
	}
	return <Tag {...props} className={className} />;
};

const ReplacableTitle = ({title, count, replace}) => (
	<span>{title.replace(replace, count)}</span>
);

const replaceErrandCountStr = "{ERRAND_COUNT}";

function loadableTitle(n, noMore, busyTitle, title) {
	if (n < 0) {
		return (
			<span>
				{busyTitle + ' '}
				<i className="fa fa-spinner fa-spin" aria-hidden="true" />
			</span>
		);
	}
	if (!noMore) {
		n += '+';
	}
	return (
		<ReplacableTitle
			title={title}
			count={n}
			replace={replaceErrandCountStr}
		/>
	);
}

class AcquireErrandsFilter extends PureComponent {
	constructor(props) {
		super(props);
		this.tabs = [
			{
				type: AET_OPEN
				, renderer: this.openTitlee
				, size: "otherSize"
				, noMore: "otherNoMore"
			}
		];
		if (features['link-errand']) {
			this.tabs.push(
				{
					type: AET_LINKED
					, renderer: this.linkedTitle
					, size: "otherSize"
					, noMore: "otherNoMore"
				}
			)
		}
		this.tabs.push(
			{
				type: AET_HISTORIES
				, renderer: this.historyTitle
				, size: "historySize"
				, noMore: "historyNoMore"
			}
			, {
				type: AET_ACQUIRE
				, renderer: this.acquireTitle
				, size: "acquireSize"
			}
		)
	}

	openTitlee(n, noMore) {
		return loadableTitle(
			n
			, noMore
			, I("Open")
			, I("Open")
//			, I("Open ({ERRAND_COUNT})")
		);
	}

	openTitle(n, noMore) {
		return loadableTitle(
			n
			, noMore
			, I("Open errands")
			, I("Open errands ({ERRAND_COUNT})")
		);
	}

	linkedTitle(n, noMore) {
		return loadableTitle(
			n
			, noMore
			, I("Linked")
			, I("Linked")
		);
	}

	historyTitle(n, noMore) {
		return loadableTitle(
			n
			, noMore
			, I("History")
			, I("History ({ERRAND_COUNT})")
		);
	}
	acquireTitle(n) {
		return (
			<ReplacableTitle
				title={I("Acquired ({ERRAND_COUNT})")}
				count={n}
				replace={replaceErrandCountStr}
			/>
		);
	}
	render() {
		let tabs = [];
		$.each(this.tabs, (i, { type, renderer, size, noMore }) => {
			if((this.props.showHistory && this.tabs[i].type === AET_HISTORIES) ||  this.tabs[i].type !== AET_HISTORIES){
				tabs.push(
					<TabTitle
						key={type}
						type={type}
						tab={this.props.tab}
						onClick={this.props.onSwitchTab}
					>
						{renderer(this.props[size], this.props[noMore])}
					</TabTitle>
				);
			}
	});
		return <div className="aquired-errands-filter">{tabs}</div>;
	}
}

class OneButton extends PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(e) {
		this.props.onClick(this.props.type, e);
	}
	render() {
		const {busy, className, disabled, hidden, text, title} = this.props;
		let busyIcon = null;
		if(busy) {
			busyIcon = <i className="fa fa-spinner fa-spin"
				aria-hidden="true" />;
		}
		return <button className={className} onClick={this.handleClick}
				title={title} disabled={disabled} hidden={hidden}>
				{busyIcon}&nbsp;{text}
			</button>;
	}
}

const AcquireErrandsFrame = ({className, children, title, buttons, onButtonClick}) => {
	let bttn = null;
	if(buttons && buttons.length) {
		let bttns = [];
		$.each(buttons, (i,v) => {
			let title, disabled;
			if(v.disabled) {
				disabled = true;
				title = v.disabled.reason;
			} else {
				title = v.text;
			}
			const type = v.type;
			bttns.push(<OneButton key={type} className={v.className}
				title={title} onClick={onButtonClick} text={v.text} type={type}
				busy={v.busy} disabled={disabled} hidden={v.hidden} />);
		});
		bttn = <div className="aquired-errands-aquire">{bttns}</div>;
	}
	return <div className={className}>
			<div className="aquired-errands-title">{title}</div>
			{children}
			{bttn}
		</div>;
};

class OtherPreview extends PureComponent {
	constructor(props) {
		super(props);
		this.handleMouseEnter = this.handleMouseEnter.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);
		this.toggle = this.toggle.bind(this);
	}
	handleMouseEnter() {
		this.props.onMouseEnterLeave(true);
	}
	handleMouseLeave() {
		this.props.onMouseEnterLeave(false);
	}
	toggle() {
		// TODO: do nothing now
	}
	render() {
		const {
				chat,
				className,
				errands,
				onMouseEnter,
				onMouseLeave,
				preview,
				previewWip,
				target
			} = this.props;
		if(!target) {
			return null;
		}
		const {ref, id} = target
			, previewData = preview[id]
			;
		let body
			, data
			;
		if (chat && chat.errand.id === id) {
			data = chat.errand.data;
		} else {
			data = errands[id].data
		}
		if(typeof previewData !== 'undefined') {
			body = <div dangerouslySetInnerHTML={{__html: previewData}} />;
		} else if(previewWip) {
			body = <i className="fa fa-spinner fa-spin" aria-hidden="true" />;
		} else {
			body = null;
		}
		return (
			<Popover className={className} placement="bottom" isOpen={true}
				target={ref} toggle={this.toggle}>
				<div onMouseEnter={this.handleMouseEnter}
					onMouseLeave={this.handleMouseLeave}>
					<PopoverHeader>{data.displayId}</PopoverHeader>
					<PopoverBody>{body}</PopoverBody>
				</div>
			</Popover>
		);
	}
};

function doNothing() {}

function getListLength(obj) {
	if (!obj || !obj.list) {
		return 0;
	}
	return obj.list.length;
}

class StaticAcquireErrands extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			enteredPreview: false,
			target: null
		};
		this.handleButtonClick = this.handleButtonClick.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleHidePreview = this.handleHidePreview.bind(this);
		this.handleMouseOverOut = this.handleMouseOverOut.bind(this);
		this.handleOpenErrands = this.handleOpenErrands.bind(this);
		this.handleOpenHistories = this.handleOpenHistories.bind(this);
		this.previewMouseEnterLeave = this.previewMouseEnterLeave.bind(this);
		this.handleSwitchTab = this.handleSwitchTab.bind(this);
		this.handleSwitchOpenType = this.handleSwitchOpenType.bind(this);
		this.tabs = [
			{
				key: "others"
			}
			, {
				key: "histories"
			}
			, {
				key: "acquire"
			}
		];
	}
	componentWillUnmount() {
		if(this.delayHidePreview) {
			clearTimeout(this.delayHidePreview);
		}
		this.unmounted = true;
	}
	handleClickOutside(evt) {
		this.props.onClickOutside();
	}
	handleButtonClick(type) {
		if(type === 'select') {
			this.props.onAcquireErrands();
		} else if(type === 'link') {

			if (this.props.selectedLinkErrandMyErrands.length == 0 ) {
				alert (MESSAGE_MUST_SELECT_ERRAND)
			} else {
				this.props.onLinkErrands(this.props.selectedLinkErrandMyErrands,this.props.currentErrand);
			}
		} else if(type === 'unlink') {
			if (this.props.selectedLinkedErrands.length == 0 ) {
				alert (MESSAGE_MUST_SELECT_ERRAND)
			} else {
				let allSelected = false
				if (this.props.selectedLinkedErrands.length == this.props.linkedErrands.list.length) {
					allSelected = true
				}
				this.props.onUnlinkErrands(this.props.selectedLinkedErrands, this.props.currentErrand, allSelected);
			}
		} else if(type === 'addtoreply') {
			if (this.props.selectedLinkedErrandsAddToReplyId.length == 0 ) {
				alert (MESSAGE_MUST_SELECT_ERRAND)
			} else {
				this.props.onAddLinkedToReply(this.props.selectedLinkedErrandsAddToReplyId,
					this.props.selectedLinkedErrandsAddToReplyDisplayId,
					this.props.currentErrand);
			}
		} else if(type === 'all') {
			this.props.onAcquireAllErrands();
		}
	}
	handleHidePreview() {
		if(this.delayHidePreview) {
			clearTimeout(this.delayHidePreview);
		}
		if (this.unmounted) {
			return;
		}
		this.delayHidePreview = setTimeout(function() {
			if(!this.state.enteredPreview) {
				this.setState({target: null});
			}
		}.bind(this), 100);
	}
	handleMouseOverOut(target, id) {
		if(target) {
			if(this.delayHidePreview) {
				clearTimeout(this.delayHidePreview);
			}
			if(!this.state.target || this.state.target.id !== id) {
				if(this.props.onPreview) {
					this.props.onPreview(id);
				}
				if(!this.state.target) {
					this.setState({
						enteredPreview: false,
						target: {ref: target, id},
					});
				} else {
					this.setState({enteredPreview: false, target: null},
						function() {
							this.setState({target: {ref: target, id}});
						}.bind(this));
				}
			}
		} else {
			this.handleHidePreview();
		}
	}
	handleSwitchTab(type) {
		this.props.onSwitchAcquire(type);
	}
	handleOpenErrands(e) {
		this.props.onSwitchAcquire(true);
	}
	handleOpenHistories(e) {
		this.props.onSwitchAcquire(false);
	}
	previewMouseEnterLeave(enter) {
		this.setState({enteredPreview: enter});
		if(!enter) {
			this.handleHidePreview();
		}
	}
	handleSwitchOpenType(checked, qaid, name) {
		this.props.onSwitchOpenType(name);
	}
	render() {
		const {
				acquire
				, className
				, currentContext
				, others: other
				, histories: history
				, linkErrandMyErrands
				, linkedErrands
				, tab
				, currentErrand
				, chat
				, ocId
				, myAgentId
				, noMore
				, noMoreHistory
				, onOpenErrand
				, onReloadErrand
				, onShowOtherContactErrand
				, onSelectAllAcquireErrand
				, onSelectAllLinkErrandMyErrand
				, onSelectAllLinkedErrand
				, acquireErrandAllSelected
				, myErrandAllSelected
				, linkedErrandAllSelected
				, ...p
			} = this.props
			, { top: threaded, bottom } = acquire
			, totalAcquire = getListLength(threaded) + getListLength(bottom)
			;
		let acqClass = 'aquired-errands-dropdown dt-dropdown'
			;
		if(p.showAcquire) {
			acqClass += ' dropped';
		}
		let openType =  this.props.openType
		let othersLen = -1
			, historiesLen = -1
			, acquireSize = -1
			, showOpen = ( tab === AET_OPEN && openType == AET_OPENTYPE_OWN)
			, showHistory = tab === AET_HISTORIES
			, showSelect = tab === AET_ACQUIRE
			, showMyErrands = ( tab === AET_OPEN && openType == AET_OPENTYPE_MYERRANDS)
			, showLinkedErrands = ( tab === AET_LINKED )
			;
		if(other.ready) {
			othersLen = getListLength(other);
		}
		if (threaded.ready || totalAcquire > 0) {
			acquireSize = totalAcquire;
		}
		if(history.ready) {
			historiesLen = getListLength(history);
		}
		if(!othersLen && !historiesLen && !acquireSize) {
			return <AcquireErrandsFrame className={classNames(className, acqClass)}
					title={I('Other contacts')}>
					<div className="aquired-errands-info">{noErrandStr}</div>
				</AcquireErrandsFrame>;
		}
		return <AcquireErrandsFrame className={classNames(className, acqClass)}
				title={I('Other contacts')} buttons={p.buttons}
				onButtonClick={this.handleButtonClick}>
				<AcquireErrandsFilter otherSize={othersLen} tab={tab}
					historySize={historiesLen} acquireSize={acquireSize}
					otherNoMore={noMore} historyNoMore={noMoreHistory}
					onSwitchTab={this.handleSwitchTab}
					showHistory={true} />
				<div className='slimScrollDiv rel-scroll'>
					<OpenErrands show={showOpen} errands={p.errands}
						data={other} onSelect={p.onSelectAcquireErrand}
						onLoadMoreAcquireErrands={p.onLoadMoreAcquireErrands}
						myAgentId={myAgentId} currentErrand={currentErrand}
						onOpenErrand={onShowOtherContactErrand} noMore={noMore}
						onMouseOverOut={this.handleMouseOverOut} ocId={ocId}
						onReloadErrand={onReloadErrand} chat={chat}
						onSelectAll={onSelectAllAcquireErrand}
						selectAll={acquireErrandAllSelected}
						tab={tab}
						openType={this.props.openType} onSwitchOpenType={this.handleSwitchOpenType} dataType={AET_OPENTYPE_OWN}/>
					{features['link-errand'] &&<MyErrands show={showMyErrands} errands={p.errands}
						data={linkErrandMyErrands} onSelect={p.onSelectLinkErrandMyErrand}
						onLoadMoreAcquireErrands={p.onLoadMoreAcquireErrands}
						myAgentId={myAgentId} currentErrand={currentErrand}
						onOpenErrand={onShowOtherContactErrand} noMore={noMore}
						onMouseOverOut={this.handleMouseOverOut} ocId={ocId}
						onReloadErrand={onReloadErrand} chat={chat}
						onSelectAll={onSelectAllLinkErrandMyErrand}
						selectAll={myErrandAllSelected}
						tab={tab}
						openType={this.props.openType} onSwitchOpenType={this.handleSwitchOpenType}
						dataType={AET_OPENTYPE_MYERRANDS}/>
					}
					{features['link-errand'] && <LinkedErrands show={showLinkedErrands} errands={p.errands}
						data={linkedErrands} onSelect={p.onSelectLinkedErrand}
						onLoadMoreAcquireErrands={p.onLoadMoreAcquireErrands}
						myAgentId={myAgentId} currentErrand={currentErrand}
						onOpenErrand={onShowOtherContactErrand} noMore={noMore}
						onMouseOverOut={this.handleMouseOverOut} ocId={ocId}
						onReloadErrand={onReloadErrand} chat={chat}
						onSelectAll={onSelectAllLinkedErrand}
						selectAll={linkedErrandAllSelected}
						tab={tab}
						openType={this.props.openType} onSwitchOpenType={this.handleSwitchOpenType}
						dataType={AET_LINKED}/>
					}
					<AcquireHistories show={showHistory} errands={p.errands}
						data={history} onSelect={p.onSelectAcquireErrand}
						onLoadMoreAcquireErrands={p.onLoadMoreHistories}
						myAgentId={myAgentId} currentErrand={currentErrand}
						onOpenErrand={onShowOtherContactErrand}
						noMore={noMoreHistory}
						onMouseOverOut={this.handleMouseOverOut} ocId={ocId}
						onReloadErrand={onReloadErrand} chat={chat} tab={tab}
						openType={this.props.openType} dataType={AET_ACQUIRE}/>
					<SelectedErrands
						chat={chat}
						currentContext={currentContext}
						currentErrand={currentErrand}
						data={acquire}
						errands={p.errands}
						myAgentId={myAgentId}
						ocId={ocId}
						onMouseOverOut={this.handleMouseOverOut}
						onOpenErrand={onOpenErrand}
						onReloadErrand={onReloadErrand}
						onSelect={p.onSelectAcquireErrand}
						show={showSelect}
					/>
				</div>
				<OtherPreview className="aquired-errands-preview"
					onMouseEnterLeave={this.previewMouseEnterLeave}
					errands={p.errands} target={this.state.target}
					preview={p.preview} previewWip={p.previewWip}
					chat={chat} currentErrand={currentErrand} />
			</AcquireErrandsFrame>;
	}
}

class StaticAcquireErrandsModal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			enteredPreview: false,
			target: null
		};
		this.handleButtonClick = this.handleButtonClick.bind(this);
		this.handleHidePreview = this.handleHidePreview.bind(this);
		this.handleMouseOverOut = this.handleMouseOverOut.bind(this);
		this.handleOpenErrands = this.handleOpenErrands.bind(this);
		this.previewMouseEnterLeave = this.previewMouseEnterLeave.bind(this);
		this.handleSwitchTab = this.handleSwitchTab.bind(this);
		this.handleCloseAcquireErrand = this.handleCloseAcquireErrand.bind(this);
		this.handleSwitchOpenType = this.handleSwitchOpenType.bind(this);

		this.tabs = [
			{
				key: "others"
			}
			, {
				key: "myErrands"
			}
			, {
				key: "acquire"
			}
		];
	}
	componentWillUnmount() {
		if(this.delayHidePreview) {
			clearTimeout(this.delayHidePreview);
		}
		this.unmounted = true;
	}
	handleCloseAcquireErrand(){
		this.props.onToggle(false);
	}
	handleButtonClick(type) {
		if(type === 'select') {
			this.props.onAcquireErrands();
		} else if(type === 'link') {

			if (this.props.selectedLinkErrandMyErrands.length == 0 ) {
				alert (MESSAGE_MUST_SELECT_ERRAND)
			} else {
				this.props.onLinkErrands(this.props.selectedLinkErrandMyErrands,this.props.currentErrand);
			}
		} else if(type === 'unlink') {
			if (this.props.selectedLinkedErrands.length == 0 ) {
				alert (MESSAGE_MUST_SELECT_ERRAND)
			} else {
				let allSelected = false
				if (this.props.selectedLinkedErrands.length == this.props.linkedErrands.list.length) {
					allSelected = true
				}
				this.props.onUnlinkErrands(this.props.selectedLinkedErrands,this.props.currentErrand, allSelected);
			}
		} else if(type === 'addtoreply') {
			if (this.props.selectedLinkedErrandsAddToReplyId.length == 0 ) {
				alert (MESSAGE_MUST_SELECT_ERRAND)
			} else {
				this.props.onAddLinkedToReply(this.props.selectedLinkedErrandsAddToReplyId,
					this.props.selectedLinkedErrandsAddToReplyDisplayId,
					this.props.currentErrand);
			}
		} else if(type === 'all') {
			this.props.onAcquireAllErrands();
		}
	}
	handleHidePreview() {
		if(this.delayHidePreview) {
			clearTimeout(this.delayHidePreview);
		}
		if (this.unmounted) {
			return;
		}
		this.delayHidePreview = setTimeout(function() {
			if(!this.state.enteredPreview) {
				this.setState({target: null});
			}
		}.bind(this), 100);
	}
	handleMouseOverOut(target, id) {
		if(target) {
			if(this.delayHidePreview) {
				clearTimeout(this.delayHidePreview);
			}
			if(!this.state.target || this.state.target.id !== id) {
				if(this.props.onPreview) {
					this.props.onPreview(id);
				}
				if(!this.state.target) {
					this.setState({
						enteredPreview: false,
						target: {ref: target, id},
					});
				} else {
					this.setState({enteredPreview: false, target: null},
						function() {
							this.setState({target: {ref: target, id}});
						}.bind(this));
				}
			}
		} else {
			this.handleHidePreview();
		}
	}
	handleSwitchTab(type) {
		this.props.onSwitchAcquire(type);
	}
	handleOpenErrands(e) {
		this.props.onSwitchAcquire(true);
	}
	previewMouseEnterLeave(enter) {
		this.setState({enteredPreview: enter});
		if(!enter) {
			this.handleHidePreview();
		}
	}
	handleSwitchOpenType(checked, qaid, name) {
		this.props.onSwitchOpenType(name);
	}
	render() {
		const {
				acquire
				, className
				, currentContext
				, others: other
				, histories: history
				, linkErrandMyErrands
				, linkedErrands
				, tab
				, currentErrand
				, chat
				, ocId
				, myAgentId
				, noMore
				, noMoreHistory
				, onOpenErrand
				, onReloadErrand
				, onShowOtherContactErrand
				, onSelectAllAcquireErrand
				, onSelectAllLinkErrandMyErrand
				, onSelectAllLinkedErrand
				, acquireErrandAllSelected
				, myErrandAllSelected
				, linkedErrandAllSelected
				, show
				, ...p
			} = this.props
			, { top: threaded, bottom } = acquire
			, totalAcquire = getListLength(threaded) + getListLength(bottom)
			;
		let acqClass = 'popup-acquired-errands-dropdown',
			ccClass = "contact-card-popup popup"
			;
		if(show) {
			acqClass += ' dropped';
			ccClass = "contact-card-popup popup open";
		}else{
			return null;
		}

		let openType =  this.props.openType
		let othersLen = -1
			, historiesLen = -1
			, acquireSize = -1
			, showOpen = ( tab === AET_OPEN && openType == AET_OPENTYPE_OWN)
			, showHistory = tab === AET_HISTORIES
			, showSelect = tab === AET_ACQUIRE
			, showMyErrands = ( tab === AET_OPEN && openType == AET_OPENTYPE_MYERRANDS)
			, showLinkedErrands = ( tab === AET_LINKED )
			;
		if(other.ready) {
			othersLen = getListLength(other);
		}
		if (threaded.ready || totalAcquire > 0) {
			acquireSize = totalAcquire;
		}
		if(history.ready) {
			historiesLen = getListLength(history);
		}
		if(!othersLen && !historiesLen && !acquireSize) {
			return <div className={ccClass}>
					<div className="popup-acquire-inner">
						<AcquireErrandsFrame className={classNames(className, acqClass)}
							title={I('Other contacts')}>
							<div className="aquired-errands-info">{noErrandStr}</div>
						</AcquireErrandsFrame>
						<div className="popup-close" data-qa-id={"QA_cc_closeAcquireErrand"}
								onClick={this.handleCloseAcquireErrand}>
								<i className="icon-times"></i>
						</div>
					</div>
				   </div>;
		}
		return <div className={ccClass}>
				 <div className="popup-acquire-inner">
					<AcquireErrandsFrame className={classNames(className, acqClass)}
					title={I('Other contacts')} buttons={p.buttons}
					onButtonClick={this.handleButtonClick}>
					<AcquireErrandsFilter otherSize={othersLen} tab={tab}
						historySize={historiesLen} acquireSize={acquireSize}
						otherNoMore={noMore} historyNoMore={noMoreHistory}
						onSwitchTab={this.handleSwitchTab}
						showHistory={false} />
						<div className='slimScrollDiv rel-scroll'>
							<OpenErrands show={showOpen} showPopup={true} errands={p.errands}
								data={other} onSelect={p.onSelectAcquireErrand}
								onLoadMoreAcquireErrands={p.onLoadMoreAcquireErrands}
								myAgentId={myAgentId} currentErrand={currentErrand}
								onOpenErrand={onShowOtherContactErrand} noMore={noMore}
								onMouseOverOut={this.handleMouseOverOut} ocId={ocId}
								onReloadErrand={onReloadErrand} chat={chat}
								onSelectAll={onSelectAllAcquireErrand}
								selectAll={acquireErrandAllSelected}
								height={defPopupHeight} tab={tab}
								openType={this.props.openType} onSwitchOpenType={this.handleSwitchOpenType}/>
							{features['link-errand'] && <MyErrands show={showMyErrands} showPopup={true} errands={p.errands}
								data={linkErrandMyErrands} onSelect={p.onSelectLinkErrandMyErrand}
								onLoadMoreAcquireErrands={p.onLoadMoreAcquireErrands}
								myAgentId={myAgentId} currentErrand={currentErrand}
								onOpenErrand={onShowOtherContactErrand} noMore={noMore}
								onMouseOverOut={this.handleMouseOverOut} ocId={ocId}
								onReloadErrand={onReloadErrand} chat={chat}
								onSelectAll={onSelectAllLinkErrandMyErrand}
								selectAll={myErrandAllSelected}
								height={defPopupHeight} tab={tab}
								openType={this.props.openType} onSwitchOpenType={this.handleSwitchOpenType}/>
							}
							{features['link-errand'] &&<LinkedErrands show={showLinkedErrands} errands={p.errands}
								data={linkedErrands} onSelect={p.onSelectLinkedErrand}
								onLoadMoreAcquireErrands={p.onLoadMoreAcquireErrands}
								myAgentId={myAgentId} currentErrand={currentErrand}
								onOpenErrand={onShowOtherContactErrand} noMore={noMore}
								onMouseOverOut={this.handleMouseOverOut} ocId={ocId}
								onReloadErrand={onReloadErrand} chat={chat}
								onSelectAll={onSelectAllLinkedErrand}
								selectAll={linkedErrandAllSelected}
								tab={tab}
								openType={this.props.openType} onSwitchOpenType={this.handleSwitchOpenType}/>
							}
							<PopupSelectedErrands
								chat={chat}
								currentContext={currentContext}
								currentErrand={currentErrand}
								data={acquire}
								errands={p.errands}
								myAgentId={myAgentId}
								ocId={ocId}
								onMouseOverOut={this.handleMouseOverOut}
								onOpenErrand={onOpenErrand}
								onReloadErrand={onReloadErrand}
								onSelect={p.onSelectAcquireErrand}
								show={showSelect}
							/>
						</div>
					</AcquireErrandsFrame>
					<div className="popup-close" data-qa-id={"QA_cc_closeAcquireErrand"}
						onClick={this.handleCloseAcquireErrand}>
						<i className="icon-times"></i>
					</div>
				 </div>
			   </div>;
	}
}

const AcquireErrandsBase = onClickOutside(StaticAcquireErrands);

const RawAcquireErrands = ({ showPopup, ...props }) => (
	<AcquireErrandsBase {...props} disableOnClickOutside={showPopup} />
);

const AcquireErrands = mapProps(({
	currentContext
	, onOpenErrand
	, onOpenErrandWhenReview
	, ...props
}) => {
	if (currentContext === CTX_REVIEW) {
		onOpenErrand = onOpenErrandWhenReview;
	}
	return {currentContext, onOpenErrand, ...props};
})(RawAcquireErrands)

export const AcquireErrandsModal = mapProps(({
	currentContext
	, onOpenErrand
	, onOpenErrandWhenReview
	, ...props
}) => {
	if (currentContext === CTX_REVIEW) {
		onOpenErrand = onOpenErrandWhenReview;
	}
	return {currentContext, onOpenErrand, ...props};
})(StaticAcquireErrandsModal)

export default AcquireErrands;
