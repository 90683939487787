import React, { useCallback, useState } from 'react';
import { default as OldCkeditor } from '../Ckeditor';

const getFormData = (id, fileData, name, fileType) => {
	let formData = new FormData()
		, eventType
		;
	formData.append('uploadfile', fileData);
	if (typeof fileType !== 'undefined') {
		// FIXME: trying to get the file type extension for future use For now,
		// when errand viewed, the pasted image file name appear without
		// extension.
		formData.append('fileType', fileType);
		if (!name && typeof fileType === "string") {
			name = fileType.replace("/", ".");
		}
	}
	if (!name) {
		eventType = "paste";
	} else {
		eventType = "drag";
		formData.append('fileNameOnly', name);
	}
	formData.append('eventType', eventType);
	formData.append(
		'random'
		, parseFloat(
			Math.floor(Math.random()*6)
			+ ''
			+ id
			+ ''
			+ Math.floor('' + new Date() / 1000)
		)
	);
	return formData;
}

const fileToData = (id, fileObject) => {
	// if(!this.isWithinSizeLimit(v.size)) {
	// 	alert(v.name + "\n" + I("The uploaded file has exceeded the max allowed size."));
	// 	return;
	// }
	return getFormData(
		id
		, fileObject
		, fileObject.name
		, fileObject.type
	);
}

const formData = event => {
	if (event.data) {
		return fileToData(1, event.data);
	}
};

export default class Ckeditor extends React.PureComponent {
	constructor (props) {
		super(props);
		this.displayName = "V5Ckeditor";
		this.handleDragDropFiles = this.handleDragDropFiles.bind(this);
		this.setTextInputRef = element => {
			this.ckeRef = element;
		};
	}
	componentDidUpdate({ insertionText: prevInsertionText}) {
		const { insertionText } = this.props;
		if ( insertionText && insertionText !== prevInsertionText
				&& !prevInsertionText) {
					const { ckeRef } = this;
					if ( ckeRef) {
						ckeRef.getInstance().insertHtml(insertionText);
						this.props.onResetInsertionText();
					}
				}
	}
	handleDragDropFiles (event) {
		if (typeof this.props.onDragnDropFiles !== 'function') {
			return;
		}
		const fd = formData(event);
		if (fd) {
			const { name, type } = event.data
				, callback = this.props.onDragnDropFiles(fd, {name, type}, event.data)
				;
			if (callback && typeof callback.then === 'function') {
				callback.then((file) => {
					const ckeRef = this.ckeRef;
					let { download, value } = file;
					if (ckeRef && value.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {
						const context = '<img class="cke-dropped" src="'
								+ download
								+ '" alt="'
								+ value
								+ '" />'
							, cke = ckeRef.getInstance()
							;
						cke.updateElement();
						ckeRef.flagChangesNotFromProp();
						cke.insertHtml(context);
					}
					if(this.props.onFileDrop){
						this.props.onFileDrop(file);
					}
				})
				.catch(() => {
					console.info(e);
				});
			}
		}
	}
	render () {
		const { onDragnDropFiles, ...props } = this.props;
		return (
			<OldCkeditor
				ref={this.setTextInputRef}
				onDragnDropFiles={this.handleDragDropFiles}
				{...props}
			/>
		);
	}
}

export const useContentChange = callback => useCallback(e => {
	callback(e.editor.getData())
}, [callback])

// TODO: not tested
// useInsertionText return items in following order:
//   1) function for caller to pass in text to be inserted into Ckeditor.
//   2) text that must be passed into Ckeditor as 'insertionText'.
//   3) function that must be passed into Ckeditor as 'onResetInsertionText'.
export const useInsertionText = () => {
	const [insertionText, setText] = useState('')
	const onResetInsertionText = useCallback(() => { setText('') }, [])
	return [setText, insertionText, onResetInsertionText]
}
