import React from 'react';
import BriefErrand from './BriefErrand';

class ChatErrandList extends React.Component {
	render() {
		let list = [], p = this.props, d = p.data;
		$.each(p.errandListChat, (i,chat) => {
			let errand = chat.errand;
			list.push(<BriefErrand
				errand={errand}
				key={errand.id}
				me={errand.id}
				index={"Chat"+i}
				onClick={id => {
					p.setMessagesSeen(chat);
					p.onClick(chat.errand.id, chat, false);
				}}
				chat={chat}
				currentOpened={p.currentOpened}
				tzOffset={p.tzOffset}
				onSelect={p.onSelectOne}
				select={chat.ui.selected}
				clientAvatar={p.clientAvatar}
				onGetCollaborationInfo={this.onGetCollaborationInfo}
				collaborationInfo={p.collaborationInfo}
				previewData={p.previewData}
				previewActivated={p.previewActivated}
				onLoadPreview={p.onLoadPreview}
				mobile={p.mobile}
				canShowPreview={this.props.canShowPreview}
			/>);
		});
		return <div className="chat-errands-list">{list}</div>;
	}
};

export default ChatErrandList;
