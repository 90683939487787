import React, { useState, useEffect, Component } from 'react';
import { I, webRoot } from '../../common/v5/config';
import { FooterButton } from '../../reactcomponents/Admin';
import SwitchCheckbox from '../../reactcomponents/SwitchCheckbox';

const ArtificialSettingV5 = (props) => {
  const [id, setId] = useState(0);
  const [enabled, setEnabled] = useState(false);
  const [url, setUrl] = useState("");
  const [keepOrgArtificialSol, setKeepOrgArtificialSol] = useState(false);

  useEffect(() => {
    if (props.id !== id) {
        setEnabled(props.artificialSettings.enabled);
        setUrl(props.artificialSettings.url);
        setId(props.id);
        setKeepOrgArtificialSol(props.artificialSettings.keepOrgArtificialSol);
    }
    if(props.artificialSettings.url !== url){
        setUrl(props.artificialSettings.url);
    }
    if(props.artificialSettings.enabled !== enabled){
        setEnabled(props.artificialSettings.enabled);
    }
  }, [props]);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    setUrl(value);
  };

  const saveOrg = () => {
    props.saveOrganization(ARTIFICIALSOL, {
        enabled: enabled,
        url: url,
        externalSearchPresent: true,
        keepOrgArtificialSol: keepOrgArtificialSol
    });
  };

  const handleInputCheck = (checked) => {
    setKeepOrgArtificialSol(!checked);
  };

  const handleCheckBoxCheck = (checked) => {
    setEnabled(!checked);
  };

  if (typeof props.artificialSettings === "undefined") {
    return <div>{I("Loading settings for Artificial Solutions.")}</div>;
  }

  let keepOrg = null;
  if (props.usedFor !== "org") {
    keepOrg = (
      <div className="form-group-org">
        <label className="col-lg-12">{I("Keep organization settings")} :</label>
        <div className="col-lg-12">
          <SwitchCheckbox
            active={keepOrgArtificialSol}
            buttonClassName={""}
            id={"orgActiveSwitch"}
            name={"organisationSetting"}
            type="checkbox"
            onClick={handleInputCheck}
          />
        </div>
      </div>
    );
  }

  if (props.usedFor === "area" && keepOrgArtificialSol) {
    return (
      <div className="col-lg-12">
        {keepOrg}
        <FooterButton cancel={props.cancelToOrg} save={saveOrg} />
      </div>
    );
  }
  return (
    <div className="col-lg-12">
      {keepOrg}
      <div className="form-group-org">
        <label className="col-lg-12">{I("Enable")}:</label>
        <div className="col-lg-12">
          <SwitchCheckbox
            active={enabled}
            buttonClassName={""}
            id={"orgActiveSwitch"}
            name={"organisationSetting"}
            type="checkbox"
            onClick={handleCheckBoxCheck}
          />
        </div>
      </div>
      <div className="form-group-org">
        <label className="col-lg-12">{I("URL")}:</label>
        <div className="col-lg-12">
          <input
            name="url"
            className="form-control input-sm"
            type="text"
            value={url}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <FooterButton cancel={props.cancelToOrg} save={saveOrg} />
    </div>
  );
};
export default ArtificialSettingV5;