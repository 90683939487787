import React from 'react';
import ToggleSwitch from './ToggleSwitch';
import SidebarSettingsList from "../../reactcomponents/SidebarSettingsList";
import { sideBarCollapsedWidth, desktopSideBarWidth } from '../../styles/_variables';
import { BTN_TXT_SAVE } from '../../common/v5/constants';

export default class ChatSourceConfig extends React.Component {
	constructor(props) {
		super(props);
	}
	handleToggleChat = (enable) => {
		this.props.onToggle(Workflow.Errand.SERVICE_CHAT, enable);
	}
	handleToggleFacebook = (enable) => {
		this.props.onToggle(Workflow.Errand.SERVICE_FACEBOOK, enable);
	}
	handleToggleTwitter = (enable) => {
		this.props.onToggle(Workflow.Errand.SERVICE_TWITTER, enable);
	}
	handleToggleLINE = (enable) =>{
		this.props.onToggle(Workflow.Errand.SERVICE_LINE, enable);
	}
	handleToggleWA = (enable) =>{
		this.props.onToggle(Workflow.Errand.SERVICE_WHATSAPP, enable);
	}
	handleToggleTG = (enable) =>{
		this.props.onToggle(Workflow.Errand.SERVICE_TELEGRAM, enable);
	}
	handleToggleVB = (enable) =>{
		this.props.onToggle(Workflow.Errand.SERVICE_VIBER, enable);
	}
	handleToggleIG = (enable) =>{
		this.props.onToggle(Workflow.Errand.SERVICE_INSTAGRAM, enable);
	}
	handleSipVoiceToggle = (enable) =>{
		this.props.onToggle(Workflow.Errand.SERVICE_SIP_VOICE, enable);
	}
	handleEmailToggle = (enable) =>{
		console.info("toggle toggle");
		this.props.onToggle(Workflow.Errand.SERVICE_EMAIL, enable);
	}
	handleCloseAndSave = () => {
		let serviceTypes = [
		Workflow.Errand.SERVICE_EMAIL,
		Workflow.Errand.SERVICE_CHAT,
		Workflow.Errand.SERVICE_FACEBOOK,
		Workflow.Errand.SERVICE_TWITTER,
		Workflow.Errand.SERVICE_LINE,
		Workflow.Errand.SERVICE_WHATSAPP,
		Workflow.Errand.SERVICE_TELEGRAM,
		Workflow.Errand.SERVICE_VIBER,
		Workflow.Errand.SERVICE_INSTAGRAM,
		Workflow.Errand.SERVICE_SIP_VOICE,
		], disabled = [];
		serviceTypes.forEach(st => {
			if (!this.isEnabled(st)) {
				disabled.push(st);
			}
		});
		this.props.onSave(disabled.toString());
	}
	isEnabled = (serviceType) => {
		let enabled = true;
		if (this.props.chatConfig.data && typeof this.props.chatConfig.data[serviceType] !== "undefined") {
			enabled = this.props.chatConfig.data[serviceType];
		}
		return enabled;
	}
	render() {
		let { hasChat, hasFacebook, hasTwitter, hasLINEChat, hasWAChat, hasTGChat, hasVBChat, enabledLINEChat, enabledChat, enabledFacebook, enabledTwitter, enabledWAChat, enabledTGChat, enabledVBChat, hasIGChat, enabledIGChat, hasSipVoice, enabledSipVoice, isQueuedManaged, enabledEmail } = this.props;
		let chatChannels = hasChat || hasFacebook || hasTwitter || hasLINEChat || hasWAChat || hasTGChat || hasVBChat || hasIGChat || hasSipVoice;

		let saveTitle = BTN_TXT_SAVE;
		if (!chatChannels) {
			saveTitle = I("Chat channels not available");
		}

		let marginLeft = sideBarCollapsedWidth;
		if (!this.props.collapseSideBar) {
			marginLeft = desktopSideBarWidth;
		}
		const channelListConfStyle = {
			marginLeft: marginLeft
		}
		const forceChatEnabled = features["chat.toggle-force-true"];
		return(
			<SidebarSettingsList className={""}>
				<div className="sidebar-settings-chat" style={channelListConfStyle} hidden={!this.props.show}>
					<div className="sidebar-settings-chat-header">
						<h6>{I("Channels")}</h6>
						<div data-qa-id="close-chat-source-config" onClick={this.handleCloseAndSave}><i className="icon-times"></i></div>
					</div>
					<div className="sidebar-settings-chat-channel">
						<ul className="sidebar-settings-chat-channel-list">
							{ isQueuedManaged &&
								<ToggleSwitch id={"email-src-styler"} name={"email"} label={I("Email")} checked={enabledEmail} disabled={forceChatEnabled} handleToggle={this.handleEmailToggle}/>
							}
							{ hasChat &&
								<ToggleSwitch id={"chat-src-styler"} name={"chat"} label={I("Chat")} checked={enabledChat} disabled={forceChatEnabled} handleToggle={this.handleToggleChat}/>
							}
							{ hasFacebook &&
								<ToggleSwitch id={"facebook-src-styler"} name={"facebook_messenger"} label={I("Facebook Messenger")} disabled={forceChatEnabled} checked={enabledFacebook} handleToggle={this.handleToggleFacebook}/>
							}
							{ hasTwitter &&
								<ToggleSwitch id={"twitter-src-styler"} name={"twitter_direct_message"} label={I("Twitter Direct Message")} disabled={forceChatEnabled} checked={enabledTwitter} handleToggle={this.handleToggleTwitter}/>
							}
							{ hasLINEChat &&
								<ToggleSwitch id={"line-src-styler"} name={"line_chat_message"} label={I("LINE Message")} checked={enabledLINEChat} disabled={forceChatEnabled} handleToggle={this.handleToggleLINE}/>
							}
							{ hasWAChat &&
								<ToggleSwitch id={"wa-src-styler"} name={"wa_chat_message"} label={I("WhatsApp Message")} checked={enabledWAChat} disabled={forceChatEnabled} handleToggle={this.handleToggleWA}/>
							}
							{ hasTGChat &&
								<ToggleSwitch id={"tg-src-styler"} name={"tg_chat_message"} label={I("Telegram Message")} checked={enabledTGChat} disabled={forceChatEnabled} handleToggle={this.handleToggleTG}/>
							}
							{ hasVBChat &&
								<ToggleSwitch id={"vb-src-styler"} name={"vb_chat_message"} label={I("Viber Message")} checked={enabledVBChat} disabled={forceChatEnabled} handleToggle={this.handleToggleVB}/>
							}
							{ hasIGChat &&
								<ToggleSwitch id={"ig-src-styler"} name={"ig_chat_message"} label={I("Instagram Messenger")} checked={enabledIGChat} disabled={forceChatEnabled} handleToggle={this.handleToggleIG}/>
							}
							{ !chatChannels &&
								<span>{I("Chat channel not available")}</span>
							}
							{ hasSipVoice &&
								<ToggleSwitch id={"sipvoice-src-styler"} name={"sip_voice_service"} label={I("Voice")} checked={enabledSipVoice} disabled={forceChatEnabled} handleToggle={this.handleSipVoiceToggle}/>
							}
						</ul>
					</div>
				</div>
			</SidebarSettingsList>
		)
	}
}
