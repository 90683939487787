import React, { PureComponent, Fragment, useEffect, useState } from 'react';
import { BootstrapTable as OldBootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DragAndDropBox from '../../reactcomponents/DragAndDropBox';
import {
	ListHeaderWrapper,
	ListContentWrapper,
	TableHeader,
	Delete,
	Edit,
	EditorFooter,
	EditorHeader,
	SaveButton,
	CancelButton,
	AdminListAndEditLayout,
	withEditWrap,
	CustomButton,
	ActionsWrapper
  } from '../../reactcomponents/Admin';

import { TABLE_STANDARD_OPTIONS } from "../../reactcomponents/Table";
import {
	useCallbackMultiValues,
} from '../../hooks/callback'
 import { AlertWrapper } from "../../views/v5/admin";
 import { DismissableAlert } from '../../reactcomponents/Error';
 import { DeletePopup } from '../../reactcomponents/Dialog';
 import { WorkflowInnerContainerCtnr } from '../../views/v5/workflowCtnr';
 import PopupPage from "../../reactcomponents/PopupPage";
import {
	SerialMultiSelect
} from '../../reactcomponents/Dropdown';
import {
	ERRAND_PAGINATION_SIZE_OPT,
	AVAILABILITY_TYPES,
	BTN_TXT_SAVE,
	BTN_TXT_SAVING
} from '../../common/v5/constants';
import {
	getListSizeBasedOnHeight
} from '../../common/v5/helpers';
import {
	TABLE_BASE_CLASSNAME,
	TABLE_HEADER_STYLE
} from '../../reactcomponents/common';
import { PaginationSize } from '../../reactcomponents/SimpleTable';
import ReportTable, { ExpandableReportTable } from '../../reactcomponents/ReportTable';
import {
	TextField,
	ReadOnly,
	TableIconicButton,
	FormInputWithLabelRow,
	SelectInputRow,
	TextInputRow
} from '../../reactcomponents/Form';
import Button from '../../reactcomponents/Button';
import {
	SelectionBox
} from '../../reactcomponents/SelectBox';
import SortableList from "../../../app/components/SortableList";

const AvailabilityTypes = () => {
	let r = [];
	$.each(AVAILABILITY_TYPES.order, (i, v) => {
		r.push({id: v, name: AVAILABILITY_TYPES[v]})
	});
	return r;
};

const SelectedReadOnly = ({title, list, className, ...props}) => {
	let selectedList = [];
	$.each(list , (i,v) => {
		selectedList.push(<li key={i+v}>{v}</li>)
	});
	return (
		<div className={className} hidden={list.length > 0 ? false : true}>
			<span className="report-selected-title">{title} : </span>
			<ul>
				{selectedList}
			</ul>
		</div>
	);
};

const CreateReportForm = ({
	activeId,
	input,
	keys,
	validGrps,
	baseButtons,
	onSetCreateReport,
	sortedKeyNamePair,
	sortedGroupNamePair,
	onReorderKey,
	onReorderGroup,
	onRemoveSelKey,
	onRemoveSelGroup
}) => {

	const [toggleKeys, setToggleKeys] = useState(false);
	const [toggleGroups, setToggleGroups] = useState(false);

	const handleChangeName = (e) => {
		onSetCreateReport("name", e.target.value);
	}
	const onHandleTextInputBlur = () => {
	}
	const handleSelectAvailability = (v) => {
		onSetCreateReport("availability", v);
	}

	const handleSelectKeys = (v) => {
		onSetCreateReport("keys", v);
	}

	const handleToggleKeys = () => {
		setToggleKeys(!toggleKeys);
	}
	const handleSelectGroups = (v) => {
		onSetCreateReport("groups", v);
	}
	const handleToggleGroups = () => {
		setToggleGroups(!toggleGroups);
	}

	const handleRepositionKey = (example, pos, id) => {
		onReorderKey({pos, id})
	}
	const handleRepositionGroup = (example, pos, id) => {
		onReorderGroup({pos, id})
	}
	const handleRemoveSelKey = (index) => {
		onRemoveSelKey(index);
	}
	const handleRemoveSelGroup = (index) => {
		onRemoveSelGroup(index);
	}

	const idFields = {id: "id", name: "displayName", title: "description"};
	const placeholder = document.createElement("li");
		placeholder.className = "placeholder";

	return(
		<Fragment>
			<form id="createReportForm" className="admin-one-form edit-admin-form" hidden={false}>
				<FormInputWithLabelRow
					label={I('Name')}
					mandatory={true}
					inlineLabel={false}
				>
					<TextInputRow
						name="name"
						className="admin-text-input with-helper"
						value={input.name}
						onChange={handleChangeName}
						onBlur={onHandleTextInputBlur}
						warning={""}
						inlineLabel={false}
						mandatory={true}
					/>
				</FormInputWithLabelRow>
				<FormInputWithLabelRow
					label={I('Availability')}
					mandatory={true}
					inlineLabel={false}
				>
					<div className="row" style={{width: '100%'}}>
						<div className="col-11 col-md-11">
							<SelectInputRow
								id={"report-availability"}
								name={"availability"}
								className={"admin-select"}
								option={AvailabilityTypes()}
								mandatory={true}
								value={input.availability}
								textNoItemSelected={I("Please select")}
								onSelect={handleSelectAvailability}
								readonly={false}
							/>
						</div>
					</div>
				</FormInputWithLabelRow>
				{
					keys &&
					<FormInputWithLabelRow
					label={I('Key values:')}
					mandatory={true}
					inlineLabel={false}
				>
					<div className="row" style={{width: '100%', marginBottom: '50px'}}>
						<div className="col-11 col-md-11">
							<div>
								{
									keys && keys.length > 0 &&
										<SerialMultiSelect
											id={"create-keys"}
											title={I("Select keys")}
											className={"popup-multi-select"}
											overrideTitle={true}
											data={keys}
											idFields={idFields}
											selected={input.keys}
											show={toggleKeys}
											onSelect={handleSelectKeys}
											selectAll={true}
											selectNone={true}
											onToggle={handleToggleKeys}
										/>
								}
								<small className="report-field-tips">
									{I("Here you select which key values your report should contain.")}
								</small>
							</div>
							<div>
								<small hidden={!input.keys} className="report-field-tips text-info">
									{I("You can drag (drag and drop) items to re-order. The result is shown in the preview.")}
								</small>
								{
									input.keys &&
										<DragAndDropBox
											title={I("Preview")}
											referenceList={keys}
											placeholder={placeholder}
											displayList={input.keys}
											dragEnd={handleRepositionKey}
											onDelete={handleRemoveSelKey}
										/>
								}
							</div>
						</div>
					</div>
				</FormInputWithLabelRow>
				}
				<FormInputWithLabelRow
					label={I('Groups:')}
					mandatory={true}
					inlineLabel={false}
				>
					<div className="row" style={{width: '100%'}}>
						<div className="col-11 col-md-11">
							<div>
								{
									validGrps && validGrps.length > 0 &&
										<SerialMultiSelect
											id={"create-groups"}
											title={I("Select groups")}
											className={"popup-multi-select"}
											overrideTitle={true}
											data={validGrps}
											idFields={idFields}
											selected={input.groups}
											show={toggleGroups}
											onSelect={handleSelectGroups}
											selectAll={true}
											selectNone={true}
											onToggle={handleToggleGroups}
										/>
								}
								<small className="report-field-tips">{I("Here you choose how the key values you have selected should be grouped.")}</small>
							</div>
							<div>
								<small hidden={ !( input.keys && validGrps.length == 0 ) } className="report-field-tips text-info">{I("No common group available for selected Key Values")}</small>
								<small hidden={!input.groups} className="report-field-tips text-info">{I("You can drag (drag and drop) items to re-order. The result is shown in the preview.")}</small>
								{
									input.groups &&
										<DragAndDropBox
											title={I("Preview")}
											referenceList={validGrps}
											placeholder={placeholder}
											displayList={input.groups}
											dragEnd={handleRepositionGroup}
											onDelete={handleRemoveSelGroup}
										/>
								}
							</div>
						</div>
					</div>
				</FormInputWithLabelRow>
				<div style={{ marginTop: '50px' }}>
					{baseButtons}
				</div>
			</form>
		</Fragment>
	)
}

export const ReportPreviewModal = ({
	show,
	name,
	data,
	onClose,
}) => {
	return <PopupPage
		data-qa-id="report-preview-backdrop-popup"
		extraClass={"report-preview-backdrop report preview popup" + (show ? " open" : "")}
		innerClass="report-preview report-inner create-inner"
		show={show}
		onClose={onClose}
	>	<div style={{width: '100%', overflow: 'auto'}}>
			<div className="popup-title">
				<h2><i className={"icon-keypad"}></i>{I("Report preview") + " - " + name}</h2>
			</div>
			<div className="popup-content" style={{background: '#FFF'}}>
			{
				data &&
					<ReportTable data={data} customClass={"report-preview-table"} />
			}
			</div>
		</div>
	</PopupPage>
}

export const renderReportAction = (
	cell
	, { Id, Name }
	, { onDelete, onEdit }
) => {
	return (
		<ActionsWrapper>
			{onEdit && <Edit id={Id} onClick={() => onEdit(Id)} />}
			{onDelete && <Delete
				id={Id}
				onClick={(() => onDelete(Id, Name))}
			/>}
		</ActionsWrapper>
	)
}

const EditForm = withEditWrap(CreateReportForm);

export const EditFormButtons = ({
	onCancel,
	onDelete,
	onPreview,
	disableSave,
	saveTxt = BTN_TXT_SAVE,
	onSave,
	hideDelete,
	hidePreview = false
}) => {
	return (
		<EditorFooter
			left={
				<div className="admin-footer-btn" hidden={hideDelete}>
					<CustomButton className={"bg-transparent btn-transparent"} icon={"icon-trash"} onClick={onDelete} text={I("Remove")} />
				</div>
			}
		>
			<CancelButton onClick={onCancel} />
			{ !hidePreview && <CustomButton className={"btn-blue"} disabled={disableSave} onClick={onPreview} text={I("Preview")} /> }
			<SaveButton className={"btn-blue"}  disabled={disableSave} onClick={onSave} text={saveTxt} />
		</EditorFooter>
	)
}

const CreateReportBase = ({
	list,
	activeId,
	input,
	keys,
	validGrps,
	showInline,
	onLoad,
	onClickEdit,
	onClickDelete,
	onDismissAlert,
	onCloseForm,
	onCancel,
	onSave,
	showCreateReport,
	showPreview,
	dataPreview,
	onLoadToEdit,
	onSetCreateReport,
	selectedKeysName,
	selectedGroupsName,
	sortedKeyNamePair,
	sortedGroupNamePair,
	onReorderKey,
	onReorderGroup,
	onRemoveSelKey,
	onRemoveSelGroup,
	onClickPreview,
	onClosePreview,
	saveStatus,
	...props
}) => {

	const view = "CREATE_REPORT";
	const isNew = activeId === 0;

	const [showDeleteAlert, setShowDeleteAlert] = useState(false);
	const [toBeDeleted , setToBeDeleted] = useState(0);
	const [deleteMsg, setDeleteMsg] = useState("");
	const [disableSave, setDisableSave] = useState(false);
	const [buttonSaveTxt, setbuttonSaveTxt] = useState(BTN_TXT_SAVE);

	useEffect(() => {
		onLoad();
	}, []);

	useEffect(() => {
		if(input.name == "" || input.keys == "" || input.groups == ""){
			setDisableSave(true);
		} else {
			setDisableSave(false);
		}
	}, [activeId, input]);

	function handleOpenEdit(id) {
		onClickEdit(id, list);
	}

	function handleCreateNew() {
		showCreateReport(true);
		onClickEdit(0);
	}

	function handlePreview(id) {
		const { keys, groups } = input;
		if(keys === ""){
			alert(I("* Need at least 1 key selected."));
		}else if(groups === ""){
			alert(I("* Need at least 1 group selected."));
		}else {
			let params = {
				keys: keys,
				groups: groups ,
			}
			onClickPreview(params);
		}
	}

	const handleDelete = (id) => {
		setShowDeleteAlert(true);
		setToBeDeleted(id);
		if(input.name) {
			setDeleteMsg(input.name);
		}
	}

	const handleConfirmDelete = () => {
		setShowDeleteAlert(false);
		onClickDelete(toBeDeleted);
	}
	const handleCancelDelete = () => {
		setShowDeleteAlert(false);
	}

	useEffect(() => {
		if(saveStatus && saveStatus.status === 1) {
			setbuttonSaveTxt(BTN_TXT_SAVING);
		} else {
			setbuttonSaveTxt(BTN_TXT_SAVE);
		}
	}, [saveStatus]);

	const baseButtons = <EditFormButtons
		hideDelete={isNew}
		onCancel={useCallbackMultiValues(onCancel, activeId)}
		onDelete={useCallbackMultiValues(handleDelete, activeId)}
		disableSave={disableSave || saveStatus.status === 1}
		saveTxt={buttonSaveTxt}
		onSave={onSave}
		onPreview={useCallbackMultiValues(handlePreview, activeId)}
	/>

	const rowEvents = {
		onClick: (e, row) => {
			onClickEdit(row.Id);
		}
	};

	const rowStyle = (row) => {
		if (row.Id === activeId) {
			return { backgroundColor: '#f5f5f5' };
		}
	};
	

	const reportCols = [
		{
			dataField: 'Id',
			text: 'ID',
			sort: true,
			hidden: true
		},
		{
			dataField: 'Name',
			text: I('Name'),
			sort: true,
		},
		{
			isDummyField: true,
			dataField: 'Id',
			text: I('Action'),
			headerClasses: 'center',
			formatter: (cell, row) => {
				return renderReportAction(cell, row, actionDatas);
			},
		}
	];

	const actionDatas = {
		onEdit: handleOpenEdit
		, onDelete: handleDelete
	}

	const listSection = <Fragment>
		<ListHeaderWrapper>
			<TableHeader
				title={I("Create reports")}
				type='button'
				iconClass='icon-add'
				onClickAdd={e => handleCreateNew()}
				hide={false}
				hideAddIcon={false}
				showInline={showInline}
			/>
		</ListHeaderWrapper>
		<ListContentWrapper className={"v5-table-style"}>
			<BootstrapTable
				keyField="Id"
				data={list}
				noDataIndication={I("No data to display")}
				columns={reportCols}
				bordered={false}
				condensed={false}
				defaultSorted={[{
					dataField: 'name',
					order: 'asc'
				}]}
				pagination={paginationFactory(TABLE_STANDARD_OPTIONS)}
				rowEvents={rowEvents}
				rowStyle={rowStyle}
				hover
			/>
		</ListContentWrapper>
	</Fragment>;

	const editSection = <Fragment>
	<EditorHeader
		hidden={!showInline}
		isNew={isNew}
		onClickClose={onCloseForm}
		title={isNew ? I("Create report") : I("Edit report")}
	/>

	<EditForm
		activeId={activeId}
		input={input}
		keys={keys}
		validGrps={validGrps}
		view={view}
		isNew={isNew}
		hidden={!showInline}
		baseButtons={baseButtons}
		onSetCreateReport={onSetCreateReport}
		selectedKeysName={selectedKeysName}
		selectedGroupsName={selectedGroupsName}
		sortedKeyNamePair={sortedKeyNamePair}
		sortedGroupNamePair={sortedGroupNamePair}
		onReorderKey={onReorderKey}
		onReorderGroup={onReorderGroup}
		onRemoveSelKey={onRemoveSelKey}
		onRemoveSelGroup={onRemoveSelGroup}
	/>
	<DeletePopup
		title={I("Are you sure you want to delete ?")}
		msg={deleteMsg}
		icon={'icon-caution'}
		show={showDeleteAlert}
		onDelete={handleConfirmDelete}
		onClose={handleCancelDelete}
	/>
	</Fragment>;
	return <WorkflowInnerContainerCtnr className="app-inner-content" hidden={false} data-custom-scrollbar>
			<AdminListAndEditLayout
				className={"call-ivr-page"}
				listSection={listSection}
				editSection={editSection}
			/>
			<ReportPreviewModal show={showPreview} name={input.name} data={dataPreview} onClose={onClosePreview} />
		</WorkflowInnerContainerCtnr>
}

const CreateReport = ({...props}) => {
	return <CreateReportBase {...props} />
}

export default CreateReport;
