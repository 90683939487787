//Common functions for all workflow components
import {I} from '../common/v5/config.js';
import { ChatActions } from '../components/chatInterface';
import CoreSocket from './coreSocket';
import {
	evtCHAT_REGISTER,
	evtCHAT_FINISH_SESSION,
	evtCHAT_SET_AGENT,
	evtCHAT_CHAT_MESSAGE,
	evtCHAT_INVITED_CHAT_MESSAGE,
	evtCHAT_SUBSCRIBE_AGENT_PRESENCE,
	evtCHAT_AGENT_PRESENCE
} from  '../redux/constants/constants';

var self = new CoreSocket();
var log = self.log;
var sock = self.socket;
var _alertSound = null;
var currentURL = window.location.protocol + "//" +window.location.hostname;
var usesExternalQueue = initialChatData.externalChat.type != "normal";

if( initialChatData.playSound ) {
	soundManager.setup({
	url: currentURL + '/webframework-public/Resources/Flash/',
	preferFlash: false,
		onready: function(){
			_alertSound = soundManager.createSound({
				id: 'alert1',
				url: currentURL + '/ng/sounds/' +
					(soundManager.canPlayMIME('audio/mpeg') ? 'alert1.mp3' : 'alert.wav')
			});
		},
		ontimeout: function() {
			log('SoundManager2 could not start. Missing SWF? Flash blocked?');
		}
	});
}

self.bindEvents = function(socket) {
	socket.on('reconnecting', function() {
		ChatActions.onConnectStatus({
			connectStatus: "connecting",
		});
	});
	socket.on('status', function(cb) {
		// Don't put any unicode characters in response - it
		// won't work on some versions of IE.
		var response = {
			forcelongpolling: socket.forcedLongPolling,
		};
		cb(response);
	});
	socket.onJson(evtCHAT_REGISTER, function (msg, cb) {
		var chatToggle = $('.chatToggle').next();
		if(window.parent.$('#iframeParentDiv').length > 0) {
			chatToggle = window.parent.$('.chatToggle').next();
		}
		log('register:', msg);
		self.CHAT_STATE = self.CHAT_STATE_REGISTERED;
		msg.connectStatus = "connected";
		ChatActions.onConnectStatus(msg);
		self.acceptChat = msg.acceptChat;

		chatToggle.attr('title', '');
		chatToggle.css('background-color', '');
		cb('agent ' + initialChatData.agentID);
	});

	socket.on('accept chat', function(acceptChat) {
		self.acceptChat = acceptChat;
		ChatActions.onAcceptChat(acceptChat);
	});

	function onChatMessage(cm, ack) {
		var msg;

		if(!cm) return; // Don't process empty chat
		log('cm:', cm);
		msg = cm.session;
		msg.chat = cm.messages;
		msg.ack = ack;
		msg.hasUnacked = cm.hasUnacked;
		msg.isInvited = cm.isInvited;
		msg.autoDisplayIfActive = self.autoDisplayIfActive;
		msg.startedHuman = cm.startedHuman;
		if (!msg.dead) {
			// For invited agent
			msg.dead = cm.dead;
		}
		log('chat:', msg);
		ChatActions.newSessionReceived(msg);
		self._hasActiveChat = true;
		if(msg.hasUnacked && (usesExternalQueue || window.parent && !window.parent.hasStandAlone)){
			self.displayNotification(msg);
		}
	}

	socket.onJson(evtCHAT_INVITED_CHAT_MESSAGE, function(cm, ack) {
		cm.isInvited = true;
		onChatMessage(cm, ack);
	});

	socket.onJson(evtCHAT_CHAT_MESSAGE, function(cm,ack){
		onChatMessage(cm, ack);
	});

	socket.onJson('message acked', function(cm) {
		ChatActions.onMessageAcked(cm);
	});

	socket.onJson('preview', function(msg) {
		log('preview:', msg);
		ChatActions.onPreview(msg);
	});

	socket.onJson('tags', function(msg) {
		ChatActions.onTags(msg);
	});

	socket.onJson('paths', function(msg) {
		ChatActions.onClientPaths(msg);
	});

	socket.onJson(evtCHAT_FINISH_SESSION, function(msg){
		ChatActions.onFinishSession(msg);
		/*if (msg.isOwner) {
			/////////////////////////////
			// TODO: Port to go
			self.fireCallbackRequest('sendChatHistory', function(value) {
				log('Send chat history:', JSON.parse(value).status);
			}, {
				session: msg.sessionId
			});
		}*/
	});

	socket.onJson('chat session is not dead', function(msg){
		ChatActions.updateClientStatus({id:msg.sessionId,status:'Online'});
	});
	socket.onJson('maybe dead chat session', function(msg){
		ChatActions.updateClientStatus({id:msg.sessionId, status:'Away'});
	});
	socket.onJson('dead chat session', function(msg){
		ChatActions.onDeadSession(msg);
	});
	socket.onJson('forward', function(msg){
		if (msg.successful) {
			ChatActions.onFinishSession(msg);
		} else {
			if (msg.error) {
				alert("Forward error: " + msg.error);
			} else {
				alert(I('No agents available'));
			}
		}
	});

	socket.on('testCloseAllChat', function(){
		if (Test.autoCloseFinishedChat) {
			Test.finishAllChatSessions();
		}
	});
	socket.on('unsentmessages', function(){
		ChatActions.onUnsentMessages({callback:self.emitMessage});
	});

	socket.onJson(evtCHAT_AGENT_PRESENCE, function(msg){
		ChatActions.onAgentPresence(msg);
	});

	// 'disconnect' can happen either due to network
	// problem or agents closing their browser.  Closing
	// the chat window, due to this event does not seem to
	// be a good idea (please correct me if I'm wrong) -
	// nazri. See fs#5305 on the "danger" of
	// sock.disconnect()/sock.connect()
	//
	//socket.on('disconnect', function(){
	//	log('disconnected');
	//	log('accept chat: ' + self.isAcceptingChat());
	//	log('ongoing chat sessions: ' + self.chatSessions.count());
	//	if (self._standalone && window.CloseWindowAfterLastChatSession && self.chatSessions.count() == 0) {
	//		self.closeWindow();
	//	} else if (self.isAcceptingChat() || self.chatSessions.count() > 0) {
	//		// Disconnect happens just before navigation to another page happens.
	//		// The 5 second delay is there to prevent reconnect just before
	//		// navigation to the other page occurs.
	//		setTimeout(function() {
	//			log('reconnect');
	//			self.startUpdate();
	//		}, 5000 /* 5 seconds */);
	//	}
	//});
};
self.CHAT_STATE_START = 0;
self.CHAT_STATE_REGISTERED = 1;
self.CHAT_STATE = self.CHAT_STATE_START;

self.showClientUnreachableMessage = I("The customer seems to be unreachable. If the customer retries the chat he/she will be reconnected to you, as long this chat session remains open. In the mean time you will not be able to send further messages to the customer.");

self.shouldConnect = function() {
	return self.acceptChat;
};

self.onConnect = function() {
	ChatActions.onConnectStatus({
		connectStatus: "connecting"
	});
};

self.onConnected = function() {
	self.CHAT_STATE = self.CHAT_STATE_START;
};

self.checkReconnect = function() {
	var chatToggle = $('.chatToggle').next();
	if(window.parent.$('#iframeParentDiv').length > 0) {
		chatToggle = window.parent.$('.chatToggle').next();
	}
	if (self.CHAT_STATE == self.CHAT_STATE_REGISTERED) {
		return;
	}
	if (!self.shouldConnect()) {
		return;
	}
	chatToggle.css('background-color', 'red');
	chatToggle.attr('title', I("Problem establishing chat status. Please retry."));
	log('chat: attempting to reconnect');
	// TODO forcelongpolling if there are many reconnection attempts
	self.forceLongPolling();
};

self.sendMessage = function(senderId, chatSessionID,errandId, message) {
	//var newMessage = $_('NewMessage').value;
	var send;
	//if (translation && translation.enabled()) {
	//	send = self.sendTranslatedMessage;
	//} else {
	//	send = ajaxSendMessage;
	//}
	//send(senderId, chatSessionID,errandId, message);
	ChatActions.onQueueSend({
		errandId: errandId,
		sender:senderId,
		sessionId: chatSessionID,
		preview: message,
		callback:self.emitMessage
	});
};
self.isStandAloneWindow = function(){
	return window.parent.$('#chat_modal_container').length <= 0;
},
self.isIncludes = function( pageHash ){
	var location = window.parent.location;
	if( location != null){
		var hash = location.hash;
		if(  hash != "" && hash.indexOf(pageHash) >= 1){
			return true;
		}
	}
	return false;
},
self.autoDisplayIfActive  = function (dead,isDirty) {
	if (!dead && !self.isStandAloneWindow()) {
		if (isDirty) {
			var parent = window.parent;
			if (!usesExternalQueue) {
				if (parent && parent.hasStandAlone) {
					return;
				}
			}
			parent.$("#chatModal").modal('show');
			if( self.isIncludes('externalexpert') ){
				parent.$("#chatModal").modal('hide');
			}
		}
	}
};

self.emitMessage = function(o) {
	var um
		, message
		, messageElement
		, div
		;

	if (o.um.length == 0) {
		return;
	}
	um = o.um[0];
	message = um.text;
	self.socket.emit(evtCHAT_CHAT_MESSAGE,{
		id: um.id,
		sessionId: o.sessionId,
		message: message,
		mcount: o.mCount
	}, function(ack) {
		var onError = function(errorMsg){
			ChatActions.updateMessage({
				id: um.id,
				errandId: o.errandId,
				sessionId: o.sessionId,
				okToRemove: false,
				alreadySeen: false,
				errorNote: errorMsg,
				msg: {}
			});
		};
		var htmlMessage
			, messageId
			, okToRemove = false
			, errorNote = ''
			, alreadySeen = false
			;
		if (!ack) {
			log('chat.emit[chat message]: ack is null');
			onError("message not sent");
			return;
		}
		ack = JSON.parse(self.socket.decode(ack));
		if (!ack) {
			log('chat.emit[chat message]: decoded ack is null');
			onError("message not sent");
			return;
		}
		log("ack:",ack);
		if (ack.error) {
			switch (ack.error) {
				case 'ERR_NIL_CHAT':
				case 'ERR_TRY_AGAIN':
					setTimeout(function(){
						ChatActions.onUnsentMessages({callback:self.emitMessage});
					}, 4000);
					return;
				case 'ERR_INVALID_MESSAGE_ID':
					// This should not happen
					okToRemove = true;
					errorNote = I('Message could not be sent (invalid umid)');
					break;
				case 'ERR_ALREADY_SEEN':
					alreadySeen = true;
					okToRemove = true;
					break;
				case 'ERR_INVALID_CHAT_ID':
					okToRemove = true;
					errorNote = I('Message could not be sent (invalid chat id)');
					break;
				case 'ERR_INVALID_CHAT_STATE':
					okToRemove = true;
					errorNote = I('Message could not be sent (invalid chat session)');
					break;
				case 'ERR_WAITING_TO_TAG':
					okToRemove= true;
					errorNote = I('This chat session has ended.');
					break;
				case 'ERR_EXPIRED':
					okToRemove= true;
					errorNote = I('This chat session has ended.');
					break;
				default:
					throw new Error("unhandled ack.error '" + ack.error + "'");
			}
		} else {
			okToRemove = true;
		}
		ChatActions.updateMessage({
			id: um.id,
			errandId: o.errandId,
			sessionId: o.sessionId,
			okToRemove: okToRemove,
			alreadySeen: alreadySeen,
			errorNote: errorNote,
			msg: ack
		});

		// Keep sending until there are no more messages in the send queue
		ChatActions.onUnsentMessages({callback:self.emitMessage});
	});
};
self.addTags = function(sessionId,tags,selectedTags,onDone) {
	var errorNote = null;
	log("sock.emit--tag",sessionId,tags);
	self.socket.emit('tag',{
		'sessionId': sessionId,
		'tags': tags
	}, function(ack) {
		var canCloseChat = false;
		if (!ack) {
			log('chat.emit[tag]: ack is null');
			if(onDone) onDone({
				error: "NULL_ACK",
				canCloseChat: false
			});
			return;
		}
		ack = JSON.parse(self.socket.decode(ack));
		if (!ack) {
			log('chat.emit[tag]: decoded ack is null');
			if(onDone) onDone({
				error: "ERR_ACK_PARSE_FAIL",
				canCloseChat: false
			});
			return;
		}
		log("ack:",ack);
		if (ack.error) {
			switch (ack.error) {
				case 'ERR_TRY_AGAIN':
					log("chat.emit[tag]: ERR_TRY_AGAIN");
					setTimeout(function() {
						self.addTags(sessionId, tags, selectedTags, onDone);
						if(onDone) onDone({
							error: I('Internal error occurred, retrying ...'),
							canCloseChat: false
						});
					}, 4000);
					return;
				case 'ERR_INVALID_CHAT_ID':
					// This should not happen
					errorNote = 'ERR_INVALID_CHAT_ID';
					log("chat.emit[tag]: ERR_INVALID_CHAT_ID");
					break;
				case 'ERR_SAVE_ERROR':
					errorNote: I('Internal error occurred, please retry.');
					break;
				case 'ERR_NEED_TAG':
					errorNote = I('No tags received.');
				default:
					errorNote = "unhandled ack.error '" + ack.error + "'";
			}
		} else {
			log("chat.emit[tag]: Done!");
			ChatActions.onUpdateTags({
				sessionId: sessionId,
				tags: tags,
				selectedTags: selectedTags
			});
			canCloseChat = true;
		}
		if(onDone) onDone({
			error: errorNote,
			canCloseChat: canCloseChat
		});
	});
};
self.finishSession = function( session_id ) {
	self.socket.emit(evtCHAT_FINISH_SESSION,{
		sessionId: session_id
	});
};
self.subscribeAgentPresence = function(msg) {
	self.socket.emit(evtCHAT_SUBSCRIBE_AGENT_PRESENCE, msg);
};
self.addAgent = function(arg, cb) {
	self.socket.emit(evtCHAT_SET_AGENT, arg, function(ack) {
		ack = JSON.parse(self.socket.decode(ack));
		cb(ack);
	});
};
self.emitAction = function(action,data){
	self.socket.emit(action,data);
};
self.emitActionWithCallback = function(action,data,cb){
	self.socket.emit(action,data,function(ack) {
		ack = JSON.parse(self.socket.decode(ack));
		cb(ack);
	});
};
self.displayNotification= function(msg){
	var self = this;
		$.titleAlert('New Chat Message!', {
			requireBlur: false,
			stopOnFocus: true,
			stopOnMouseMove: true,
			duration: 0,
			interval: 500
		});
		if( initialChatData.playSound && _alertSound ) {
			_alertSound.play();
		}
		var messageString = I('New message');
		if (msg.client != "") {
			messageString = I('New message from {0}.').replace('{0}',msg.client);
		}

		var messageTitle = I('Cention Chat - New Message!');
		if( window.Notification && Notification.permission === 'granted' ) {
			this._notification = new Notification(messageTitle, {
				tag: 'CentionChatNotification',
				body: messageString
			});
			this._notification.addEventListener('click', function( event ) {
				self._notification.close();
				window.close();
			});
		} else if( window.webkitNotifications && window.webkitNotifications.checkPermission() == 0 ) {
			this._webkitNotification = webkitNotifications.createNotification(
				'', messageTitle, messageString
			);
			// Firefox 16.0.2 in Ubuntu 12.10 has some sort of support
			// for webkitNotifications however when trying to set 'replaceId'
			// it throws a permission denied error.
			try {
				this._webkitNotification.replaceId = 'CentionChatNotification';
			} catch( e ) { }
			this._webkitNotification.addEventListener('click', function( event ) {
				self._webkitNotification.cancel();
				window.focus();
			}, true);
			this._webkitNotification.show();
		} else {
			if(window.Notification){
				window.Notification.requestPermission();
			}
			if(window.webkitNotifications){
				window.webkitNotifications.requestPermission();
			}
		}
	};

self.sendChatHistory = function(sessionId, callback) {
	self.socket.emit('send chat history', { sessionId: sessionId }, function(msg) {
		var ack = JSON.parse(self.socket.decode(msg));
		callback(ack);
	});
};
export default self;
