import React, { useState, useEffect, useMemo, memo } from 'react';
import { PortalModalBox } from '../../reactcomponents/Modal';
import { composeWithDisplayName, withUnmountWhenHidden } 
	from '../../reactcomponents/hocs';
import { unreadNotification, dismissNotification } from '../../common/v5/socket';

import { 
    EditorFooter,
    CancelButton,
    SaveButton } from '../../reactcomponents/Admin';
import { AttachmentBox, SerialTagList } from './Attachments';

const AnnouncementForm = ({
	hidden
	, announcementData
	, announcementAttachment
	, onCloseAnnouncement
	, show
	, ...props
}) => {
	const handleDismissNotification = (id) => {
		dismissNotification(id);
		onCloseAnnouncement();
	}
	
	const handleUnreadNotification = (id) => {
		unreadNotification(id);
		onCloseAnnouncement();
	}

	return (
		<form id="announcementForm" className={"admin-one-form edit-admin-form "} 
			hidden={hidden}>
				<h2 className='name-heading'>{announcementData.subject}</h2>

				<div className='announcement-message'
					dangerouslySetInnerHTML={useMemo(
					() => ({ __html: announcementData.message }),
					[announcementData.message]
					)}
				/>
				{cflag.IsActive("2023-11-10.CEN-1788.announcement.upload.attachment") &&
					<div className="pull-left uploaded-announcement-attachment">
						<SerialTagList value={announcementAttachment} />
					</div>
				}
			<EditorFooter>
				<CancelButton 
					onClick={()=> handleDismissNotification(announcementData.id)}
					text={I('Clear')}/>
				<SaveButton className={"btn-blue"}  
					onClick={()=> handleUnreadNotification(announcementData.id)}
					text={I("Mark as unread")} />
			</EditorFooter>
		</form>
	)
}

const Announcement = composeWithDisplayName(
	"Announcement",
	withUnmountWhenHidden,
	memo
)(AnnouncementForm);

export const AnnoucementRenderer = ({
	announcementData
    , show
    , onCloseAnnouncement
	, announcementAttachment
	, ...props
}) => {
	return (
		<PortalModalBox className="annoucement-modal" show={show} onClose={onCloseAnnouncement} 
                            {...props} >
			{ <Announcement
				hidden={!show}
				announcementData={announcementData}
				announcementAttachment={announcementAttachment}
				onCloseAnnouncement={onCloseAnnouncement}
				show={show}
			/> }
		</PortalModalBox>
	)
}
