import React, { PureComponent, Fragment, useEffect, useState } from 'react';
import update from 'immutability-helper';
import {BootstrapTable as OldBootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
// TODO: please consider refactor this to avoid drag in old code react and old
// react library.
import SortableList from '../SortableList';
import {
	ERRAND_PAGINATION_SIZE_OPT,
	AVAILABILITY_TYPES,
	CONFIGURE_TIME,
	BTN_TXT_SAVE,
	BTN_TXT_SAVING
} from '../../common/v5/constants';
import {
	getListSizeBasedOnHeight,
	FormatOptions
} from '../../common/v5/helpers';
import {
	TABLE_BASE_CLASSNAME,
	TABLE_HEADER_STYLE
} from '../../reactcomponents/common';
import { PaginationSize } from '../../reactcomponents/SimpleTable';
import {
	SelectionBox
} from '../../reactcomponents/SelectBox';
import {
	TextField,
	ReadOnly,
	TableIconicButton,
	FormInputWithLabelRow,
	SelectInputRow,
	TextInputRow
} from '../../reactcomponents/Form';
import Button from '../../reactcomponents/Button';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
	ListHeaderWrapper,
	ListContentWrapper,
	TableHeader,
	EditorHeader,
	AdminListAndEditLayout,
	withEditWrap
  } from '../../reactcomponents/Admin';

import { TABLE_STANDARD_OPTIONS } from "../../reactcomponents/Table";
import {
	useCallbackMultiValues,
} from '../../hooks/callback'
 import { AlertWrapper } from "../../views/v5/admin";
 import { DismissableAlert } from '../../reactcomponents/Error';
 import { DeletePopup } from '../../reactcomponents/Dialog';
 import { WorkflowInnerContainerCtnr } from '../../views/v5/workflowCtnr';
 import PopupPage from "../../reactcomponents/PopupPage";
 import { EditFormButtons, renderReportAction } from './CreateReport';
 import DragAndDropBox from '../../reactcomponents/DragAndDropBox';

const getTimeFormatExample = (name) => {
	let example = name.substr(name.indexOf(":") + 1);
	example = example.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
	return example;
}

const getReadableTimeStampFormat = (list, ids) => {
	let obj = [];
	if(ids && ids != ""){
		let selected = ids.toString().split(",");
		$.each(selected, (i,v) => {
			let id = v;
			$.each(list, (n,c) => {
				if(id == c.Id){
					obj.push(getTimeFormatExample(c.Name));
				}
			});
		});
	}
	return obj;
};


export class ConfigureTimeFormOld extends PureComponent {
	constructor(props){
		super(props);
		this.handleName = this.handleName.bind(this);
		this.handleTimeFormat = this.handleTimeFormat.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleAdd = this.handleAdd.bind(this);
		this.handleReposition = this.handleReposition.bind(this);
		this.handleRemoveSelection = this.handleRemoveSelection.bind(this);
		this.handleSave = this.handleSave.bind(this);
		this.handleClear = this.handleClear.bind(this);
		this.state = {
			nameWarning: "",
			formatWarning: "",
			selectedTimeItem: 1,
			selectedTimeItemList: [],
		}
	}
	handleName(e) {
		this.props.onSetConfigTime("name", e.target.value);
	}
	handleAdd() {
		this.setState({"formatWarning": ""});
		this.props.onSetConfigTime("selectedTimespan", this.state.selectedTimeItem);
	}
	handleTimeFormat(v) {
		let currState = this.state.selectedTimeItemList;
		const newOptions = update(currState, {$merge: [v]});
		this.setState({
			selectedTimeItemList: newOptions,
			selectedTimeItem: v
		});
	}
	handleClose() {
		this.props.onClose();
	}
	handleSave() {
		const {name, selectedTimespan} = this.props.data;
		let warnTxt = I("* You can not leave this field empty.");
		let timeWarnTxt = I("* Please select time format and click add.")
		if(name === "") {
			this.setState({"nameWarning": warnTxt});
			return;
		} else {
			this.setState({"nameWarning": ""});
		}

		if(selectedTimespan === ""){
			this.setState({"formatWarning": timeWarnTxt});
			return;
		}else{
			this.setState({"formatWarning": ""});
		}
		this.props.onSetConfigTime("buttonBusy", true);
		this.props.onSave();
	}
	handleRemoveSelection(index){
		this.props.onRemoveSelection(index);
	}
	handleReposition(example, pos, id){
		this.props.onReorder({pos, id});
	}
	handleClear(){
		this.props.onClearSelect();
	}
	render() {
		const p = this.props, data = p.data;
		const { nameWarning } = this.state;
		let cbClass = "create report popup";
		if(this.props.show) {
			cbClass = "create report popup open";
		}
		let disabled = false;
		let updateMode = false, saveBtn = I("Create"), title = I("New Time Format");
		if(p.activeId > 0){
			updateMode = true;
			saveBtn = I("Update");
			title = I("Edit Time Format") +" - "+ data.name;
		}
		let buttons = [];
		if(data.buttonBusy) {
			let buttonText = <span>
					<i className="fa fa-spinner fa-spin"
					aria-hidden="true" /> {saveBtn}
				</span>
			buttons.push(<Button key="btn-update-report-loading" color="grey"
				text={buttonText} className="save-button" />)
		}else{
			buttons.push(<Button key="btn-update-report" color="blue"
				text={saveBtn} className="save-button"
				onClick={this.handleSave} />);
		}
		let placeholder = document.createElement("li");
		placeholder.className = "placeholder";
		let timePreview = getReadableTimeStampFormat(p.timeFormats, data.selectedTimespan);
		if(this.props.show) {
			return(
				<div className={cbClass}>
					<div className="report-inner popup-inner">
						<div className="create-report-box">
							<div className="popup-tabs">
								<span className={"toggle-tabs"} >
									{title}
								</span>
							</div>
							<form>
								<div className="create-report-form">
									<div className="label-row">
										<TextField className="create-report-name"
											onChange={this.handleName} value={data.name}
											disabled={disabled} label={I('NAME: ')}
											warning={nameWarning} />
									</div>
									<div className="label-row">
										<div className="label-block">
											<div className="label-format">
												<label htmlFor="format">
													<span className="mandatory">*</span>
													{I('Format: ')}
												</label>
											</div>
											<div className="format">
												<SelectionBox options={FormatOptions(p.timeFormats)}
													name="timespanFormat" selected={this.state.selectedTimeItem}
													onSelect={this.handleTimeFormat} />
												<Button key="btn-add-time" color="blue" text={I("Add")} className="add-button" onClick={this.handleAdd} />
												<p className="mandatory">{this.state.formatWarning}</p>
											</div>
										</div>
									</div>
									<div className="label-row">
										<div className="label-block">
											<div className="label-preview">
												<label htmlFor="preview">
													{I('Preview: ')}
												</label>
											</div>
											<div className="preview">
												<div className="time-preview">{timePreview.join(" ")}</div>
												{
													p.selectedTimeObj &&
														<SortableList
															data={p.selectedTimeObj}
															placeholder={placeholder}
															dragEnd={this.handleReposition}
															onDelete={this.handleRemoveSelection}/>
												}
												<small className="report-field-tips">{I("Please drag (drag and drop) items to re-order. The result is shown in the preview.")}</small>
												{
													(p.selectedTimeObj && p.selectedTimeObj.length > 0) &&
														<Button key="btn-add-time" color="blue" text={I("Clear")} className="clear-button" onClick={this.handleClear} />
												}
											</div>
										</div>
									</div>
								</div>
								<div className="label-row" style={{display: "inline-flex", float: "right"}}>
									{buttons}
								</div>
							</form>
						</div>
						<div data-qa-id="create-report-close" className="popup-close" onClick={this.handleClose}>
							<i className="icon-times"></i>
						</div>
					</div>
				</div>
				);
		} else {
			return null;
		}
	}
}

export const ConfigureTimeForm = ({
	input,
	timeFormats,
	baseButtons,
	selectedTimeObj,
	selectedTimeExample,
	onSetConfigTime,
	onReorder,
	onRemoveSelection,
	onClearSelect
}) => {
	const [formatWarning, setFormatWarning] = useState("");
	const [selectedTimeItem, setSelectedTimeItem] = useState(1);
	const [selectedTimeItemList, setSelectedTimeItemList] = useState([]);

	const handleChangeName = (e) => {
		onSetConfigTime("name", e.target.value);
	}
	const onHandleTextInputBlur = (e) => {

	}
	const handleAddTimeFormat = () => {
		setFormatWarning("");
		onSetConfigTime("selectedTimespan", selectedTimeItem);
	}
	const handleTimeFormat = (value, name) => {
		setSelectedTimeItem(value);

		let currState = selectedTimeItemList;
		const newOptions = update(currState, {$merge: [value]});
		setSelectedTimeItemList(newOptions);
	}

	const handleRepositionTime = (example, pos, id) => {
		onReorder({pos, id})
	}

	const handleDeleteItem = (pos) => {
		onRemoveSelection(pos);
	}

	const handleClear = () => {
		onClearSelect();
	}

	let placeholder = document.createElement("li");
		placeholder.className = "placeholder";
	const timePreview = getReadableTimeStampFormat(timeFormats, input.selectedTimespan);

	let sortedTimeFormatPair = [];
	if(selectedTimeObj && selectedTimeObj.length > 0){
		$.each(selectedTimeObj, (i,v) => {
			sortedTimeFormatPair.push({id: v.Id, displayName: v.Name});
		});
	}
	const addTimeBtnStyle = {
		border: '1px solid #EAEAEA',
		height: '24px',
		width: '24px',
		borderRadius: '4px',
		padding: '4px',
		cursor: 'pointer',
		marginLeft: '10px'
	}
	return (
		<Fragment>
			<form id="callExportEdit" className="admin-one-form edit-admin-form report-form">
				<FormInputWithLabelRow
					label={I('Name')}
					mandatory={true}
					inlineLabel={false}
				>
					<TextInputRow
						name="name"
						className="admin-text-input with-helper"
						value={input.name}
						onChange={handleChangeName}
						onBlur={onHandleTextInputBlur}
						warning={""}
						inlineLabel={false}
						mandatory={true}
					/>
				</FormInputWithLabelRow>
				<div className='admin-input-wrapper with-padding'>
					<FormInputWithLabelRow
						label={I("Format: ")}
						warning={formatWarning}
						className={""}
						inlineLabel={false}
						mandatory={true}
					>
						<div className='admin-select-full-row'>
							<SelectInputRow
								id="timeStampFormat"
								name="timeStampFormat"
								className="full-width"
								label={""}
								option={FormatOptions(timeFormats)}
								value={selectedTimeItem}
								onSelect={handleTimeFormat}
							/>
							<div style={addTimeBtnStyle} title={I("Add")} onClick={handleAddTimeFormat}>
								<i style={{color: '#969696'}} className="icon-add"></i>
							</div>
						</div>
					</FormInputWithLabelRow>
				</div>
				<div className='admin-input-wrapper with-padding'>
				<FormInputWithLabelRow
					label={I('Preview: ')}
					mandatory={false}
					inlineLabel={false}
				>
					<div className="time-preview" style={{padding: '10px'}}>{timePreview.join(" ")}</div>
					<div className=''>
						{ input.selectedTimespan ?
							<DragAndDropBox
								title={""}
								referenceList={sortedTimeFormatPair}
								placeholder={placeholder}
								displayList={input.selectedTimespan}
								dragEnd={handleRepositionTime}
								onDelete={handleDeleteItem}
							/> : ""
						}
					</div>
					<small className="report-field-tips">{I("Please drag (drag and drop) items to re-order. The result is shown in the preview.")}</small>
					{
						(selectedTimeObj && selectedTimeObj.length > 0) ?
							<div style={{padding: '10px 0px'}}>
								<Button key="btn-add-time" color="blue" text={I("Clear")} className="clear-button" onClick={handleClear} />
							</div>
							: ""
					}
				</FormInputWithLabelRow>
				</div>
				<div style={{ marginTop: '50px' }}>
					{baseButtons}
				</div>
			</form>
		</Fragment>
	)
}

const EditForm = withEditWrap(ConfigureTimeForm);

const ConfigureTimeBase = ({
	onLoad,
	activeId,
	list,
	input = {},
	data,
	show,
	timeFormats,
	selectedTimeObj,
	selectedTimeExample,
	onSetConfigTime,
	onReorder,
	onRemoveSelection,
	onClearSelect,
	onClickEdit,
	onClickDelete,
	onCancel,
	onSave,
	onClose,
	showInline,
	onCloseForm,
	onDismissAlert,
	saveStatus,
	...props
}) => {
	const [showDeleteAlert, setShowDeleteAlert] = useState(false);
	const [toBeDeleted , setToBeDeleted] = useState(0);
	const [deleteMsg, setDeleteMsg] = useState("");
	const [disableSave, setDisableSave] = useState(false);
	const [buttonSaveTxt, setbuttonSaveTxt] = useState(BTN_TXT_SAVE);
	const isNew = activeId == 0;
	const view = CONFIGURE_TIME;

	useEffect(() => {
		if(input.name == ""){
			setDisableSave(true);
		} else {
			setDisableSave(false);
		}
	}, [activeId, input]);

	function handleOpenEdit(id) {
		onClickEdit(id, list);
	}

	const handleDelete = (id) => {
		setShowDeleteAlert(true);
		setToBeDeleted(id);
		if(input.name) {
			setDeleteMsg(input.name);
		}
	}

	const handleConfirmDelete = () => {
		setShowDeleteAlert(false);
		onClickDelete(toBeDeleted);
	}
	const handleCancelDelete = () => {
		setShowDeleteAlert(false);
	}

	useEffect(() => {
		if(saveStatus && saveStatus.status === 1) {
			setbuttonSaveTxt(BTN_TXT_SAVING);
		} else {
			setbuttonSaveTxt(BTN_TXT_SAVE);
		}
	}, [saveStatus]);

	const baseButtons = <EditFormButtons
		hideDelete={isNew}
		hidePreview={true}
		onCancel={useCallbackMultiValues(onCancel, activeId)}
		onDelete={useCallbackMultiValues(handleDelete, activeId)}
		disableSave={disableSave || saveStatus.status === 1}
		saveTxt={buttonSaveTxt}
		onSave={onSave}
	/>

	const rowEvents = {
		onClick: (e, row) => {
			onClickEdit(row.Id);
		}
	};

	const handleCreateNew = () => {
		onClickEdit(0);
	}

	const reportCols = [
		{
			dataField: 'Id',
			text: 'ID',
			sort: true,
			hidden: true
		},
		{
			dataField: 'Name',
			text: I('Name'),
			sort: true,
		},
		{
			isDummyField: true,
			dataField: 'Id',
			text: I('Action'),
			headerClasses: 'center',
			formatter: (cell, row) => {
				return renderReportAction(cell, row, actionDatas);
			},
		}
	];

	const actionDatas = {
		onEdit: handleOpenEdit
		, onDelete: handleDelete
	}

	const rowStyle = (row) => {
		if (row.Id === activeId) {
			return { backgroundColor: '#f5f5f5' };
		}
	};

	const listSection = <Fragment>
		<ListHeaderWrapper>
			<TableHeader
				title={I("Time Formats")}
				type='button'
				iconClass='icon-add'
				onClickAdd={e => handleCreateNew()}
				hide={false}
				hideAddIcon={false}
				showInline={showInline}
			/>
		</ListHeaderWrapper>
		<ListContentWrapper className={"v5-table-style"}>
			{
				list ?
				<BootstrapTable
					keyField="Id"
					data={list}
					noDataIndication={I("No data to display")}
					columns={reportCols}
					bordered={false}
					condensed={false}
					defaultSorted={[{
						dataField: 'name',
						order: 'asc'
					}]}
					pagination={paginationFactory(TABLE_STANDARD_OPTIONS)}
					rowEvents={rowEvents}
					rowStyle={rowStyle}
					hover
				/> : ""
			}
		</ListContentWrapper>
	</Fragment>;

	const editSection = <Fragment>
	<EditorHeader
		hidden={!showInline}
		isNew={isNew}
		onClickClose={onCloseForm}
		title={isNew ?  I("New Time Format") : I("Edit Time Format")}
	/>
	<EditForm
		activeId={activeId}
		input={input}
		data={input}
		view={view}
		show={show}
		isNew={isNew}
		timeFormats={timeFormats}
		selectedTimeObj={selectedTimeObj}
		selectedTimeExample={selectedTimeExample}
		onReorder={onReorder}
		onRemoveSelection={onRemoveSelection}
		onSetConfigTime={onSetConfigTime}
		onClearSelect={onClearSelect}
		hidden={!showInline}
		baseButtons={baseButtons}
		onSave={onSave}
		{...props}
	/>
	<DeletePopup
		title={I("Are you sure you want to delete ?")}
		msg={deleteMsg}
		icon={'icon-caution'}
		show={showDeleteAlert}
		onDelete={handleConfirmDelete}
		onClose={handleCancelDelete}
	/>
	</Fragment>;
	return <WorkflowInnerContainerCtnr className="app-inner-content" hidden={false} data-custom-scrollbar>
			<AdminListAndEditLayout
				className={"report-page no-max-height"}
				listSection={listSection}
				editSection={editSection}
			/>
		</WorkflowInnerContainerCtnr>
}

const ConfigureTime = ({...props}) => {
	return <ConfigureTimeBase {...props} />
}


export default ConfigureTime;
