import React, { memo, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import each from 'lodash/each'
import { mapProps } from 'recompose'
import {
  appHeaderHeight,
  elSpacing
} from '../../styles/_variables'
import styled from 'styled-components'
import { ChartCtnr } from '../../containers/chart'
import ChartEditor from './ChartEditor'
import {
  composeWithDisplayName,
  mergeStyleIfExist
} from '../../reactcomponents/hocs'
import Grid from '../../reactcomponents/Grid'
import {
  CL_OVERVIEW,
  CL_REPORT,
  CS_EXIST,
  CS_PIN,
  CS_REPORT
} from '../../common/v5/constants'

const chartStyle = {
  height: '100%',
  overflow: 'auto',
  width: '100%'
}

let offsetC3Height = "20px";

const StyledTopDIV = styled.div`
  // background-color: #fff;
  // min-height: calc(100% - ${offsetC3Height});
  overflow: auto;
  padding: ${elSpacing};
`
const Chart = composeWithDisplayName(
  'Chart',
  memo,
  mapProps(({ data, index, shortid, style, ...props }) => ({
    id: data,
    idx: shortid,
    style: mergeStyleIfExist(style, chartStyle),
    ...props
  })),
  mapProps(({ edit, onLinkClick, ...props }) => {
    if (edit) {
      return { ...props }
    }
    return { onLinkClick, ...props }
  })
)(ChartCtnr)

const ChartGridBase = ({
  agentTimezoneOffset,
  chartMap,
  grid: { layouts },
  layout,
  onGridChange,
  onGridSave,
  pinReportsById,
  reportsById,
  saveDisabled,
  systemReportsByName,
  ...props
}) => {
  const handleAddChart = useCallback(
    (chartMap, layouts) => onGridChange(
      { layouts: { $set: layouts } },
      { $set: chartMap }
    ),
    [onGridChange]
  )
  const handleRemoveChart = useCallback(
    removee => onGridChange(
      ({ layouts }) => {
        const { index } = removee
        const _layouts = {}
        each(layouts, (v, k) => {
          _layouts[k] = { $splice: [[index, 1]] }
        })
        return { layouts: _layouts }
      },
      { $unset: [removee.shortid] },
      removee
    ),
    [onGridChange]
  )
  const onLayoutChange = useCallback(_layouts => {
    if (typeof _layouts === 'function') {
      return onGridChange(grid => ({
        layouts: { $set: _layouts(grid.layouts) }
      }))
    }
    return onGridChange({ layouts: { $set: _layouts } })
  }, [onGridChange])
  const editorProps = useMemo(
    () => ({
      agentTimezoneOffset,
      chartMap,
      pinReportsById,
      reportsById,
      systemReportsByName
    }),
    [
      agentTimezoneOffset,
      chartMap,
      pinReportsById,
      reportsById,
      systemReportsByName
    ]
  )
  const gridProps = useMemo(
    () => ({ layouts, onLayoutChange, onSave: onGridSave, saveDisabled }),
    [layouts, onLayoutChange, onGridSave, saveDisabled]
  )
  const canNotRemove = useMemo(() => {
    const result = {}
    each(chartMap, ({ id, type }) => {
      if (type === CS_EXIST &&
        id.layout === layout &&
        typeof id.idx === 'string') {
        result[id.idx] = true
      }
    })
    return result
  }, [chartMap, layout])
  return (
    <StyledTopDIV>
      <Grid
        canNotRemove={canNotRemove}
        childComponent={Chart}
        content={chartMap}
        editorComponent={ChartEditor}
        editorProps={editorProps}
        gridProps={gridProps}
        layout={layout}
        onAddItem={handleAddChart}
        onRemoveItem={handleRemoveChart}
        {...props}
      />
    </StyledTopDIV>
  )
}

const ChartGrid = composeWithDisplayName(
  'ChartGrid',
  memo
)(ChartGridBase)

const layoutPropTypes = PropTypes.oneOf([CL_OVERVIEW, CL_REPORT])

const chartIdPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      layout: layoutPropTypes,
      idx: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ])
    })
  ]),
  type: PropTypes.oneOf([CS_EXIST, CS_PIN, CS_REPORT]),
  param: PropTypes.object,
  chart: PropTypes.number
})

ChartGrid.propTypes = {
  chartMap: PropTypes.objectOf(chartIdPropTypes),
  grid: PropTypes.shape({
    charts: PropTypes.arrayOf(chartIdPropTypes),
    layouts: PropTypes.objectOf(PropTypes.array)
  }),
  layout: layoutPropTypes,
  onGridChange: PropTypes.func,
  onLinkClick: PropTypes.func
}

export default ChartGrid
